import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import store from "../../store";
import { getAllProducts, getBusiness, filterCategory } from "../../actions";
import { countPageView } from "../../api/products";
import { getVisibleProducts } from "../../services";

import GridCategories from "./GridCategories";
import Header from "./Header";

import TopSellers from "./TopSellers";

import FooterDark from "./FooterDark";
import { useHistory } from "react-router-dom";
import Currency from "../../components/common/currency";
import Ratings from "../Ratings";
import MetaTags from "react-meta-tags";
import ReactLoading from "react-loading";
import Banner from "../Banner";
import Payment from "../../components/common/payment";
import ReactPixel from "react-facebook-pixel";

function LandPage(props) {
    let history = useHistory();
    const [featured, setFeatured] = useState(false);

    function allProducts() {
        props.filterCategory(null);
        history.push("/category");
    }

    useEffect(() => {
        async function init() {
            //window.scrollTo(0, 0);
            document.body.style = "background: #424242;";
            let newClient = localStorage.getItem("newClient");
            //console.log("chamei o init");
            //console.log(props.selected.category);
            // Parâmetro que vem da tela de detalhamento do produto.
            let business = JSON.parse(localStorage.getItem("currentBusiness"));

            if (business && business.name) {
                if (newClient === "true") {
                    //alert("contei");
                    countPageView(business._id);
                    localStorage.setItem("newClient", "false");
                }
                if (
                    business.features.includes("metapixel") &&
                    business.activePlanName === "Diamante"
                ) {
                    const advancedMatching = {};
                    const options = {
                        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                        debug: false, // enable logs
                    };
                    let pixel = business.facebookPixel;
                    ReactPixel.init(pixel, advancedMatching, options);
                    ReactPixel.pageView(); // For tracking page view
                    console.log("carreguei o pixel do facebook");
                }
                // retirei pois não estava fazendo o load dos products após fechar a compra
                //if (props.products.length === 0) {
                store.dispatch(getAllProducts(business));
                store.dispatch(getBusiness(business));
                const favicon = document.getElementById("favicon");
                favicon.href = business.logo;
                document.title = business.name;
                //}
            } else {
                this.setState({ redirect: true });
            }
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.products.length > 0 && !featured) {
            //console.log(props.products);
            let featuredProducts = props.products.filter((p) => {
                return p.featured === true;
            });
            if (featuredProducts.length === 0) {
                featuredProducts = props.products;
            }
            let index = 0;
            setFeatured(featuredProducts[index]);
        }
    }, [featured, props]);
    function isLoading() {
        return localStorage.getItem("loaded") !== "true";
    }
    return (
        <div id="dark-wrap">
            {isLoading() && props.products.length === 0 ? (
                <div className="loading-area">
                    <ReactLoading type={"bubbles"} color={"white"} />
                </div>
            ) : (
                <>
                    <MetaTags>
                        <title>{props.business.name}</title>
                        <meta name="description" content={props.business.details} />
                        <meta property="og:title" content={props.business.name} />
                        <meta property="og:image" content={props.business.logo} />
                    </MetaTags>
                    <div className="header-dark">
                        <Header />
                    </div>
                    {props.business.freeShipping > 0 && (
                        <div className="blank">
                            <div
                                style={{
                                    marginTop: 25,
                                    paddingTop: 5,
                                    width: "100%",
                                    backgroundColor: "gray",
                                    height: 20,
                                    fontSize: 12,
                                    textAlign: "center",
                                    color: "white",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <div style={{ marginRight: 5 }}>
                                    FRETE GRÁTIS para compras acima de{" "}
                                </div>
                                <Currency value={props.business.freeShipping} />
                            </div>
                        </div>
                    )}
                    {props.business && (
                        <div className="promo-banner">
                            <Banner business={props.business} />
                        </div>
                    )}
                    <div className="featured-dark">
                        <div style={{ position: "relative" }}>
                            <img
                                src={featured.img}
                                style={{
                                    objectFit: "contain",
                                    width: "100%",
                                }}
                                alt="imagem principal"
                            />
                            <div onClick={() => allProducts()} class="image-dark-button">
                                VER PRODUTOS
                            </div>
                        </div>
                    </div>
                    <div className="grid-categories" style={{ marginTop: 40 }}>
                        <GridCategories />
                    </div>
                    <div className="top-sellers">
                        <TopSellers />
                    </div>
                    <div className="footer-dark">
                        <FooterDark />
                        <Payment business={props.business} />
                    </div>
                    {props.business &&
                        props.business.features &&
                        props.business.features.includes("rating") && (
                            <div className="rating-dark">
                                <Ratings business={props.business} />
                            </div>
                        )}
                </>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
    categories: state.category.current,
    cartItems: state.cartList.cart,
    selected: state.filters,
    products: getVisibleProducts(state.data, { category: null }, { searchTerm: null }),
});

export default connect(mapStateToProps, { filterCategory })(LandPage);
