// Get Products
export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const RECEIVE_CATEGORIES = "RECEIVE_CATEGORIES";
export const RECEIVE_PROMOS = "RECEIVE_PROMOS";
export const FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT";

//Get Business
export const RECEIVE_BUSINESS = "RECEIVE_BUSINESS";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const INCREMENT_QTY = "INCREMENT_QTY";
export const DECREMENT_QTY = "DECREMENT_QTY";
export const CLEAR_CART = "CLEAR_CART";
export const UPDATE_CART = "UPDATE_CART";

// CheckOut Process
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";

export const FILTER_CATEGORY = "FILTER_CATEGORY";
export const CLEAR = "CLEAR";

export const SET_LIMIT = "SET_LIMIT";

export const SET_SHIPPING = "SET_SHIPPING";
export const SET_COUPON = "SET_COUPON";
export const CLEAR_COUPON = "CLEAR_COUPON";

export const SET_INSTALLMENT = "SET_INSTALLMENT";
export const CLEAR_INSTALLMENT = "CLEAR_INSTALLMENT";

export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
