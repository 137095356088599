import * as types from "../constants/ActionTypes";

const filterState = {
  category: "",
};

const filtersReducer = (state = filterState, action) => {
  switch (action.type) {
    case types.FILTER_CATEGORY:
      return {
        ...state,
        category: action.category,
      };
    default:
      return state;
  }
};

export default filtersReducer;
