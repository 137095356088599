import React from "react";
import { connect } from "react-redux";

import "./styles_d.css";

function FooterDark(props) {
  return (
    <div>
      <div class="separator"></div>
      <div className="footer-dark-about-title">Sobre nós</div>

      <div className="footer-dark-about-text">{props.business.description}</div>
      <div class="separator"></div>

      <div className="footer-dark-about-title">Contato</div>
      <div className="footer-dark-about-text">
        <p>{props.business.address}</p>
        <p>{props.business.phone}</p>
        <p>{props.business.email}</p>
      </div>

      {props.business && (props.business.instagram || props.business.facebook) && (
        <div className="social-media-dark">
          <div className="social-media-container">
            <div className="icon">
              <a
                href={`https://api.whatsapp.com/send?phone=55${props.business.whatsappNumber}`}
                class="wrap"
              >
                <i class="fa fa-whatsapp"></i>
              </a>
            </div>
            {props.business.instagram && (
              <div className="icon">
                <a
                  href={"instagram://user?username=" + props.business.instagram}
                >
                  <i class="fa fa-instagram"></i>
                </a>
              </div>
            )}
            {props.business.facebook && (
              <div className="icon">
                <a href={"https://www.facebook.com/" + props.business.facebook}>
                  <i class="fa fa-facebook"></i>
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  business: state.business.current,
});

export default connect(mapStateToProps, {})(FooterDark);
