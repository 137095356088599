import React from "react";

export default function payment(props) {
    function isOnsiteCheckoutMP() {
        return (
            (props.business.activePlanName === "Pro" ||
                props.business.activePlanName === "Diamante") &&
            props.business.features &&
            props.business.features.includes("onsite-checkout-mp")
        );
    }

    return (
        isOnsiteCheckoutMP() && (
            <div class="payment-section">
                <div class="payment-label">Integrado com o Mercado Pago.</div>
                <div className="payment-wrapper">
                    <li style={{ listStyle: "none" }}>
                        <ul className="payment-visa"></ul>
                        <ul className="payment-mastercard"></ul>
                        <ul className="payment-hipercard"></ul>
                        <ul className="payment-amex"></ul>
                        <ul className="payment-diners"></ul>
                        <ul className="payment-elo"></ul>
                        <ul className="payment-melicard"></ul>
                        <ul className="payment-bolbradesco"></ul>
                    </li>
                </div>
            </div>
        )
    );
}
