import shop from "../api/shop";
import * as types from "../constants/ActionTypes";
import { generateTransformationURL } from "../components/common/cloudinary";
import { round } from "../components/common/currency-method";

export const fetchProductsBegin = () => ({
    type: types.FETCH_PRODUCTS_BEGIN,
});

export const receiveProducts = (products) => ({
    type: types.RECEIVE_PRODUCTS,
    products,
});

export const receiveCategories = (categories) => ({
    type: types.RECEIVE_CATEGORIES,
    categories,
});

export const receivePromos = (promos) => ({
    type: types.RECEIVE_PROMOS,
    promos,
});

export const receiveBusiness = (business) => ({
    type: types.RECEIVE_BUSINESS,
    business,
});

export const clearState = () => ({
    type: types.CLEAR,
});

export const clearCart = () => ({
    type: types.CLEAR_CART,
});

export const getBusiness = (business) => async (dispatch) => {
    if (business) {
        if (!business.logo) {
            business.logo = "../assets/blank_logo.png";
        }
        //console.log("peguei o business " + business.name);
        dispatch(receiveBusiness(business));
    }
    return business;
};
function isPaidPlan(business) {
    return (
        business.activePlanName === "Premium" ||
        business.activePlanName === "Pro" ||
        business.activePlanName === "Diamante"
    );
}

export const getAllProducts = (business) => async (dispatch) => {
    let c = await shop.getCategories(business._id);
    dispatch(receiveCategories(c));
    //console.log(transformation);
    //console.log("pegando produtos");
    let products = [];

    dispatch(fetchProductsBegin());
    products = await shop.getProducts(business.uid);

    //let categories = [{ _id: 0, name: "Todos" }];
    //console.log(products);
    let p = products.filter((product) => {
        if (
            (business.activePlanName === "Pro" ||
                business.activePlanName === "Diamante") &&
            business.delivery.includes("shipping")
        ) {
            return (
                (product.hasDimensions && product.status === true) ||
                (product.variations.length > 0 && product.status === true)
            );
        } else {
            return product.status === true;
        }
    });

    //moving the out-of stock items to end of array
    if (
        isPaidPlan(business) &&
        business.features &&
        business.features.includes("stock")
    ) {
        let hasStock = p
            .filter((product) => {
                if (product.variations.length === 0) {
                    if (product.stock?.length > 0) {
                        return product.quantityStock > 0;
                    }
                    return product.quantity > 0;
                } else {
                    return (
                        product.variations.reduce((total, variation) => {
                            return total + variation.quantity;
                        }, 0) > 0
                    );
                }
            })
            .map((product) => {
                return { ...product, hasStock: true };
            });
        let outOfStock = p
            .filter((product) => {
                if (product.variations.length === 0) {
                    if (product.stock?.length > 0) {
                        return product.quantityStock === 0;
                    }
                    return product.quantity === 0;
                } else {
                    return (
                        product.variations.reduce((total, variation) => {
                            return total + variation.quantity;
                        }, 0) === 0
                    );
                }
            })
            .map((product) => {
                return { ...product, hasStock: false };
            });
        //console.log(outOfStock);
        p = [...hasStock, ...outOfStock];
    }
    //const bandwithKiller = [];

    let promoProducts = [];
    let promoFind = [];
    //if (business.activePlanName === "Diamante" && business.features.includes("promo")) {
    if (business.activePlanName === "Diamante") {
        let promos = await shop.getPromos(business.uid);
        if (promos && promos.length > 0) {
            dispatch(receivePromos(promos));
            for (let i = 0; i < promos.length; i++) {
                let promoAmount = promos[i].amount;
                let promoCode = promos[i].name
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replace(" ", "");
                let mappedProducts = promos[i].products.map((p) => {
                    return {
                        _id: p,
                        amount: promoAmount,
                        promoCode: promoCode,
                    };
                });
                promoProducts = [...promoProducts, ...mappedProducts];
                promoFind = [...promoFind, ...promos[i].products];
            }
        }
    }

    let transformation = p.map((value, index) => {
        let url = value.imageUri;
        let cropImages = null;
        const imageTransformation = business.imageTransformation || "original";

        if (value.imageUri) {
            url = generateTransformationURL(
                value.imageUri,
                imageTransformation,
                business.imageQuality
            );

            cropImages = value.images.map((image) => {
                return generateTransformationURL(
                    image,
                    imageTransformation,
                    business.imageQuality
                );
            });
        }

        /*

        if (value.category && !categories.includes(value.category.name)) {
            categories.push(value.category.name);
        }
        */
        //console.log(promoFind);
        let productCategories = [];
        if (value.category) {
            productCategories.push(value.category.name?.replace('/', ' '));
        }

        let isPromotional = promoFind.includes(value._id);
        let promoAmount = 0;
        let promoCode = "";

        let price = value.price;
        let promoPrice = value.promoPrice;

        if (isPromotional) {
            let targetProduct = promoProducts.filter((p) => {
                return p._id === value._id;
            })[0];
            promoAmount = targetProduct.amount;
            promoCode = targetProduct.promoCode;
            productCategories.push(promoCode);
            if (promoAmount > 0) {
                price = promoPrice || price;
                promoPrice = (promoPrice || price) * (1 - promoAmount / 100);
                promoPrice = round(promoPrice);
            }
        }

        let product = {
            id: value._id,
            name: value.name,
            img: url,
            featured: value.featured,
            price: price,
            promoPrice: promoPrice,
            description: value.description,
            category: productCategories,
            images: cropImages || value.images,
            model: value.models[0],
            createdAt: value.createdAt,
            quantity: value.quantity,
            video: value.video,
            videoThumbnail: value.videoThumbnail,
            variations: value.variations.map((v) => {
                let v1 = JSON.parse(v.variationsValues);
                let price = v.price;
                let promoPrice = v.promoPrice;
                if (isPromotional) {
                    price = v.promoPrice || v.price;
                    promoPrice = (v.promoPrice || v.price) * (1 - promoAmount / 100);
                    promoPrice = round(promoPrice);
                }

                return {
                    ...v,
                    id: v._id,
                    img: v.imageUri,
                    price: price,
                    promoPrice: promoPrice,
                    variationValue: v1[0].variationValue,
                };
            }),
            hasStock: value.hasStock,
            promo: isPromotional,
            promoAmount: promoAmount,
            promoCode: promoCode,
            colors: value.colors,
            sizes: value.sizes,
            stock: value.stock,
            quantityStock: value.quantityStock,
            primaryColor: value.primaryColor,
        };

        return product;
    });

    dispatch(receiveProducts(transformation));
    localStorage.setItem("loaded", "true");
    return transformation;
};

//it seems that I should probably use this as the basis for "Cart"
export const addToCart = (product, qty) => (dispatch) => {
    //console.log("Produto adicionado ao carrinho");
    dispatch(addToCartUnsafe(product, qty));
};
export const addToCartAndRemoveWishlist = (product, qty) => (dispatch) => {
    //console.log("Produto adicionado ao carrinho");
    dispatch(addToCartUnsafe(product, qty));
};
export const addToCartUnsafe = (product, qty) => ({
    type: types.ADD_TO_CART,
    product,
    qty,
});
export const removeFromCart = (product) => (dispatch) => {
    //console.log("Produto removido do carrinho");
    dispatch({
        type: types.REMOVE_FROM_CART,
        product,
    });
};
export const incrementQty = (product, qty) => (dispatch) => {
    //console.log("Produto adicionado ao carrinho");
    dispatch(addToCartUnsafe(product, qty));
};
export const filterCategory = (category) => ({
    type: types.FILTER_CATEGORY,
    category,
});

export const setLimit = (limit) => ({
    type: types.SET_LIMIT,
    limit,
});

export const setSearchTerm = (searchTerm) => ({
    type: types.SET_SEARCH_TERM,
    searchTerm,
});

export const setShipping = (shipping) => ({
    type: types.SET_SHIPPING,
    shipping,
});

export const setCoupon = (coupon) => ({
    type: types.SET_COUPON,
    coupon,
});
export const clearCoupon = () => ({
    type: types.CLEAR_COUPON,
});

export const setInstallment = (installment) => ({
    type: types.SET_INSTALLMENT,
    installment,
});
export const clearInstallment = () => ({
    type: types.CLEAR_INSTALLMENT,
});

export const decrementQty = (product, qty) => (dispatch) => {
    //console.log("Produto removido do carrinho.");

    dispatch({
        type: types.DECREMENT_QTY,
        product,
        qty,
    });
};

export const updateCart = (product) => ({
    type: types.UPDATE_CART,
    product,
});
