import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getVisibleProducts } from "../../services";
import Currency from "../../components/common/currency";
import { Link } from "react-router-dom";
import "./styles_f.css";

function ListView(props) {
    let limit = 21;
    const [loadedProducts, setLoadedProducts] = useState([]);
    const [quantity, setQuantity] = useState(limit);

    useEffect(() => {
        setLoadedProducts(props.products.slice(0, quantity));
    }, [props.products, quantity]);

    function loadMore() {
        setQuantity(quantity + limit);
    }

    useEffect(() => {
        setQuantity(21);
    }, [props.selected.category]);
    return (
        <>
            {loadedProducts.map((product, index) => (
                <div className="list-view">
                    <div className="image">
                        <Link
                            to={{
                                pathname: `${process.env.PUBLIC_URL}/product/${product.id}`,
                                state: { view: "list" },
                            }}
                        >
                            <div
                                style={{
                                    backgroundImage: `url(${product.img})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    width: 90,
                                    height: 120,
                                }}
                            />
                        </Link>
                    </div>
                    <div className="list-details">
                        <div className="item-title">{product.name}</div>
                        <div className="item-description">{product.description}</div>
                        <div>
                            {product.promoPrice ? (
                                <>
                                    <div style={{ textDecorationLine: "line-through" }}>
                                        <Currency
                                            value={
                                                product.variations.length > 0
                                                    ? product.variations[0].price
                                                    : product.price
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{
                                            fontSize: 22,
                                            color: "rgba(var(--primary-color), 1)",
                                            marginBottom: 3,
                                        }}
                                    >
                                        <Currency
                                            value={
                                                product.variations.length > 0
                                                    ? product.variations[0].promoPrice
                                                    : product.promoPrice
                                            }
                                        />
                                    </div>
                                </>
                            ) : (
                                <div
                                    style={{
                                        fontSize: 22,
                                        color: "rgba(var(--primary-color), 1)",
                                        marginBottom: 3,
                                    }}
                                >
                                    <Currency
                                        value={
                                            product.variations.length > 0
                                                ? product.variations[0].price
                                                : product.price
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ))}
            {quantity < props.products.length && (
                <div className="load-more-button" onClick={() => loadMore()}>
                    MAIS PRODUTOS
                </div>
            )}
        </>
    );
}
const mapStateToProps = (state) => ({
    business: state.business.current,
    products: getVisibleProducts(state.data, state.filters, state.searchTerm),
    selected: state.filters,
});

export default connect(mapStateToProps, {})(ListView);
