import React, { useEffect, useState } from "react";
import "./styles_promo.css";
import TextField from "@material-ui/core/TextField";
import api from "../api/service";
import InputAdornment from "@material-ui/core/InputAdornment";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Countdown from "react-countdown";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import Currency from "../components/common/currency";
import qs from "qs";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginRight: 10,
    },
    card: {
        minWidth: 275,
        paddingLeft: 50,
        paddingRight: 50,

        marginTop: 20,
        marginBottom: 20,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    mt: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    instructionsLabel: {
        fontSize: 13,
    },
    paper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "& > *": {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            margin: theme.spacing(2),
            width: "100%",
            height: theme.spacing(30),
        },
    },
}));

function PixPaymentPage(props) {
    const classes = useStyles();
    const [businessId, setBusinessId] = useState();
    const [productId, setProductId] = useState();
    const [payerName, setPayerName] = useState();
    const [payerEmail, setPayerEmail] = useState();
    const [planDesc, setPlanDesc] = useState();
    const [payerId, setPayerId] = useState();
    const [planPrice, setPlanPrice] = useState();
    const [expiresIn, setExpiresIn] = useState();
    const [loaded, setLoaded] = useState(false);
    const [qrcode, setQrcode] = useState();
    const [qrcode64, setQrcode64] = useState();
    const [nodata, setNodata] = useState(false);
    const [credits, setCredits] = useState();

    const [message, setMessage] = useState("");
    const [expirationDatePix, setExpirationDatePix] = useState();
    const [progressCountdown, setProgressCountdown] = useState(0);

    let MAX = 60000 * 10;
    let MIN = 0;

    const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);

    const renderer = ({ formatted, total, completed }) => {
        setProgressCountdown(MAX - total);
        // Render a countdown
        return (
            <span>
                {formatted.minutes}:{formatted.seconds}
            </span>
        );
    };

    useEffect(() => {
        let params = props.location.search;
        let businessId = qs.parse(params, { ignoreQueryPrefix: true }).businessId;
        setBusinessId(businessId);
        let productId = qs.parse(params, { ignoreQueryPrefix: true }).productId;
        setProductId(productId);
    }, [props.location.search]);

    useEffect(() => {
        async function init() {
            if (productId && businessId) {
                const response = await api.post("orderPlan/pix", {
                    businessId,
                    productId,
                });
                const data = response.data;
                if (data && !data.error) {
                    setQrcode(data.qrcode);
                    setQrcode64(data.qrcode_base64);
                    setExpirationDatePix(data.expirationDatePix);
                    setPayerEmail(data.payerEmail);
                    setPayerName(data.payerName);
                    setPlanDesc(data.planDesc);
                    setExpiresIn(data.expiresIn);
                    setPayerId(data.payerId);
                    setPlanPrice(data.planPrice);
                    setCredits(data.credits);
                    setLoaded(true);
                } else {
                    setNodata(true);
                }
            }
        }
        init();
    }, [businessId, productId]);

    const handleCloseMessage = () => {
        setMessage("");
    };

    return (
        <div id="promo-wrap-payment">
            {loaded ? (
                <>
                    <div className="promo-header">
                        <img
                            className="logo"
                            src="https://res.cloudinary.com/rockedevelopr/image/upload/v1627344463/carty/logo_lvqqnx.png"
                            alt="logo"
                        />
                        <div style={{ marginTop: 3 }}> PAGAMENTO POR PIX</div>
                    </div>

                    <Snackbar
                        open={message !== ""}
                        //onClose={handleClose}
                        message={message}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        autoHideDuration={3000}
                        onClose={handleCloseMessage}
                    />
                    <div
                        className="promo-content"
                        style={{
                            display: "flex",
                            flex: 1,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div
                            style={{
                                border: "solid 1px black",
                                width: "90%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    marginTop: 20,
                                }}
                            >
                                <div>
                                    <Typography className={classes.instructions}>
                                        Email:
                                    </Typography>

                                    <Typography className={classes.instructions}>
                                        Nome:
                                    </Typography>

                                    <Typography className={classes.instructions}>
                                        CPF / CNPJ:
                                    </Typography>

                                    <Typography className={classes.instructions}>
                                        Plano:
                                    </Typography>

                                    <Typography className={classes.instructions}>
                                        Vencimento:
                                    </Typography>

                                    <Typography className={classes.instructions}>
                                        Investimento:
                                    </Typography>
                                </div>
                                <div>
                                    <Typography className={classes.instructions}>
                                        {payerEmail}
                                    </Typography>

                                    <Typography className={classes.instructions}>
                                        {payerName}
                                    </Typography>

                                    <Typography className={classes.instructions}>
                                        {payerId}
                                    </Typography>

                                    <Typography className={classes.instructions}>
                                        {planDesc}
                                    </Typography>

                                    <Typography className={classes.instructions}>
                                        {moment(expiresIn).format("DD/MM/YYYY")}
                                    </Typography>

                                    <Typography className={classes.instructions}>
                                        <Currency value={planPrice} />
                                    </Typography>
                                </div>
                            </div>
                            {credits > 0 && (
                                <div style={{ backgroundColor: "#EEEEEE", padding: 10 }}>
                                    Serão adicionados {credits} dia(s) ao plano contratado
                                    referente a conversão do seu crédito atual para o novo
                                    plano.
                                </div>
                            )}
                            <div style={{ textAlign: "center", padding: 20 }}>
                                <img
                                    src={`data:image/png;base64,${qrcode64}`}
                                    style={{ width: "90%" }}
                                    alt="qrcode"
                                />
                                <div style={{ fontSize: 14, textAlign: "center" }}>
                                    Copie o código abaixo e utilize o Pix Copia e Cola ou
                                    use o
                                </div>
                                <div style={{ fontSize: 14, textAlign: "center" }}>
                                    QRCode acima no aplicativo que você vai fazer o
                                    pagamento.
                                </div>
                                <CopyToClipboard
                                    text={qrcode}
                                    onCopy={() =>
                                        setMessage("Código copiado com sucesso!")
                                    }
                                >
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        disabled
                                        style={{ marginBottom: 20, marginTop: 20 }}
                                        value={qrcode}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FileCopyOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </CopyToClipboard>
                                <div style={{ fontSize: 12, textAlign: "center" }}>
                                    Você tem até 10 minutos para fazer o pagamento.
                                </div>
                                <div
                                    style={{
                                        fontSize: 12,
                                        textAlign: "center",
                                        marginTop: 20,
                                    }}
                                >
                                    O tempo para você pagar acaba em:
                                </div>
                                <div style={{ fontSize: 20, textAlign: "center" }}>
                                    <Countdown
                                        zeroPadTime={2}
                                        date={expirationDatePix}
                                        renderer={renderer}
                                    />
                                </div>
                                <LinearProgress
                                    variant="determinate"
                                    value={normalise(progressCountdown)}
                                />
                                <img
                                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1645215548/carty/selo-seguranca_utma7f.png"
                                    class="secureImage"
                                    style={{ marginTop: 20, width: 300 }}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : nodata ? (
                <div className="promo-header">
                    <img src="/assets/nodata.jpg" alt="nodata" width={300}></img>
                    <div style={{ fontSize: 22, color: "gray" }}>
                        Atualize seu Cadastro
                    </div>
                    <div style={{ fontSize: 14, marginTop: 10, color: "gray" }}>
                        No app Carty: Vá em Minhas Configurações,
                    </div>
                    <div style={{ fontSize: 14, marginTop: 3, color: "gray" }}>
                        clique em CPF/CNPJ e atualize seu cadastro.
                    </div>
                </div>
            ) : (
                <div className="promo-header">
                    <div style={{ textAlign: "center", marginTop: 10 }}>
                        Processando...
                    </div>
                </div>
            )}
        </div>
    );
}
export default PixPaymentPage;
