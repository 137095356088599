import React, { useEffect, useState } from "react";

import Currency from "../../components/common/currency";
import { connect } from "react-redux";
import { getVisibleProducts } from "../../services";
import { Link } from "react-router-dom";
import { setLimit } from "../../actions";
import SearchBar from "../SearchBar";

function GridView(props) {
    const [loadedProducts, setLoadedProducts] = useState([]);
    //const [quantity, setQuantity] = useState(props.limit);

    useEffect(() => {
        setLoadedProducts(props.products.slice(0, props.limit));
    }, [props.products, props.limit]);

    function loadMore() {
        props.setLimit(props.limit + 20);
    }

    return (
        <>
            <div className="product-anchor-area" id="product-anchor" />
            {props.business.features &&
            props.business.features.includes("searchbar") &&
            (props.business.activePlanName === "Premium" ||
                props.business.activePlanName === "Pro" ||
                props.business.activePlanName === "Diamante") ? (
                <SearchBar />
            ) : undefined}
            <div className="product-grid-minimalist">
                {loadedProducts
                    .filter((product, index) => {
                        return product.id !== props.featuredId;
                    })
                    .map((product, index) => (
                        <div>
                            <div className="product-grid-container-minimalist">
                                <Link
                                    to={{
                                        pathname: `${process.env.PUBLIC_URL}/product/${product.id}`,
                                        state: { view: "grid" },
                                    }}
                                >
                                    <img
                                        src={product.img}
                                        alt={product.name}
                                        style={{
                                            opacity:
                                                !product.hasStock &&
                                                props.business.features &&
                                                props.business.features.includes("stock")
                                                    ? 0.3
                                                    : 1,
                                        }}
                                    />
                                </Link>
                                {product.promoAmount === 0 ? (
                                    <></>
                                ) : (
                                    <div className="product-grid-dark-sale">
                                        {product.promoAmount > 0 && product.promoAmount}
                                        {product.promoAmount > 0 && "% OFF"}
                                    </div>
                                )}
                            </div>
                            <div className="product-grid-minimalist-title">
                                {product.name}
                            </div>
                            {product.promoPrice ||
                            (product.variations.length > 0 &&
                                product.variations[0].promoPrice) ? (
                                <div className="product-grid-minimalist-container-price">
                                    <div className="product-grid-minimalist-price-line">
                                        <Currency
                                            value={
                                                product.variations.length > 0
                                                    ? product.variations[0].price
                                                    : product.price
                                            }
                                        />
                                    </div>
                                    <div className="product-grid-minimalist-promoprice">
                                        <Currency
                                            value={
                                                product.variations.length > 0
                                                    ? product.variations[0].promoPrice
                                                    : product.promoPrice
                                            }
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="product-grid-minimalist-price">
                                    <Currency
                                        value={
                                            product.variations.length > 0
                                                ? product.variations[0].price
                                                : product.price
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    ))}
            </div>
            {props.limit < props.products.length && (
                <div className="load-more-button" onClick={() => loadMore()}>
                    MAIS PRODUTOS
                </div>
            )}
        </>
    );
}
const mapStateToProps = (state) => ({
    business: state.business.current,
    products: getVisibleProducts(state.data, state.filters, state.searchTerm, true),
    selected: state.filters,
    limit: state.limit.current,
});

export default connect(mapStateToProps, { setLimit })(GridView);
