export const generateTransformationURL = (url, mode, imageTransformation) => {
    let result = "";
    /*
  let transformation =
    "w_" +
    width +
    (height ? ",h_" + height : "") +
    (mode === 1 ? ",c_fill" : ",c_scale") +
    "/";
  */

    let transformation = "t_normal/";
    if (mode === "original") {
        if (imageTransformation === "high") {
            transformation = "t_original/";
        } else {
            transformation = "t_normal/";
        }
    } else if (mode === "4:3") {
        if (imageTransformation === "high") {
            transformation = "t_portrait/";
        } else {
            transformation = "t_quality/";
        }
    } else if (mode === "1:1") {
        if (imageTransformation === "high") {
            transformation = "t_square/";
        } else {
            transformation = "t_square-eco/";
        }
    }
    if (url !== null) {
        const prefix = url.substring(0, url.indexOf("upload/") + 7);
        const sufix = url.substring(url.indexOf("upload/") + 7, url.length);
        result = prefix + transformation + sufix;
        return result;
    }
    return url;
};

export const generateIco = (url) => {
    //console.log(url);
    if (url) {
        let result = "";
        let transformation = "w_90,c_scale/";

        const prefix = url.substring(0, url.indexOf("upload/") + 7);
        const sufix = url.substring(url.indexOf("upload/") + 7, url.length);
        result = prefix + transformation + sufix;
        return result;
    }
    return url;
};
