import React, { useState, useEffect } from "react";
import service from "../api/service";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { makeStyles } from "@material-ui/core/styles";
import { setShipping } from "../actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getCartTotal } from "../services";

function Shipping(props) {
    const [destinationZipCode, setDestinationZipCode] = useState();
    const [localShipping, setLocalShipping] = useState();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState();
    const [isEligibleFreeShipping] = useState(
        props.business.freeShipping && props.business.freeShipping > 0
    );
    function isFreeShipping() {
        return isEligibleFreeShipping && props.subtotal > props.business.freeShipping;
    }

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        //console.log("aqui");
        //console.log(props.shipping);
        if (isEmpty(props.shipping)) {
            //console.log("entrei");
            setLocalShipping(null);
        }
    }, [props.shipping]);

    useEffect(() => {
        if (!isFreeShipping()) {
            //console.log("props.searchBy", props.searchBy);
            if (props.searchBy && props.searchBy.length === 8) {
                calcShipping(props.searchBy);
                setDestinationZipCode(props.searchBy);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchBy]);

    function handleClick(m) {
        setMessage(m);
        setOpen(true);
    }
    function handleClose() {
        setOpen(false);
    }

    const useStyles = makeStyles((theme) => ({
        root: { padding: 5 },
        label: {
            fontSize: 14,
            marginLeft: 10,
        },
        loading: {
            width: "100%",
            "& > * + *": {
                marginTop: theme.spacing(2),
            },
        },
        wrapper: {
            position: "relative",
        },
        buttonProgress: {
            color: "gray ",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        buttonProgressCheckout: {
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
    }));

    const classes = useStyles();

    async function calcShipping(searchBy) {
        setLoading(true);
        props.setShipping({});
        let ids = props.cartItems.map((item) => {
            let a = [];
            let i = 0;
            while (i < item.qty) {
                a.push(item.id);
                i++;
            }
            return a;
        });
        //console.log(ids.join());
        ids = ids.join();
        if ((destinationZipCode && destinationZipCode !== "") || searchBy) {
            let zip = searchBy || destinationZipCode;
            zip = zip.replace("-", "");

            try {
                let url = `/shipping/me2/${props.business._id}/${ids}/${zip}`;

                console.log(url);
                const response = await service.get(url);
                console.log(response);
                if (response.status !== 400) {
                    const unavailable = response.data.filter((item) => {
                        return item.price === 0;
                    });
                    const available = response.data.filter((item) => {
                        return item.price !== 0;
                    });
                    let sorted = [...available, ...unavailable];

                    setLocalShipping(sorted);
                } else {
                    const message = response.data.message;
                    console.log(message);
                    if (message === "Shipping not available.") {
                        handleClick("Entrega indisponível para outras cidades.");
                    } else {
                        handleClick("Não foi possível calcular o frete");
                    }
                }
                setLoading(false);
            } catch (e) {
                setLoading(false);
                const message = e.data.message;
                if (message === "Shipping not available.") {
                    handleClick("Entrega indisponível para outros estados.");
                } else {
                    handleClick("Não foi possível calcular o frete");
                }
                props.setShipping({
                    error: true,
                    message: "Entrega indisponível para outras cidades.",
                });
            }
        } else {
            handleClick("Digite o CEP para consulta.");
        }
        setLoading(false);
    }

    function handleME(service) {
        console.log("handleME", service);
        let result = {
            ...service,
            method: service.name,
            total: parseFloat(service.price.replace(",", ".")),
            days: service.days,
            destinationZipCode: destinationZipCode,
        };
        //console.log(result);
        props.setShipping(result);
    }

    return localShipping ? (
        <>
            <div class="cart-shipping">
                {isFreeShipping() ? (
                    <div class="free-shipping">
                        <span role="img" aria-label="party">
                            🎉
                        </span>
                        Parabéns! Você ganhou FRETE GRÁTIS!
                        <span role="img" aria-label="party">
                            🎉
                        </span>
                    </div>
                ) : (
                    props.searchBar && (
                        <>
                            <InputMask
                                mask="99999-999"
                                value={destinationZipCode}
                                onChange={(e) => setDestinationZipCode(e.target.value)}
                            >
                                {() => (
                                    <TextField
                                        id="outlined-basic"
                                        label="Consulte o Frete:"
                                        placeholder="Insira aqui o seu CEP"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        shrink={false}
                                        style={{ flex: 0.8 }}
                                    />
                                )}
                            </InputMask>

                            <div onClick={() => calcShipping()} class="apply-button">
                                <div>CALCULAR</div>
                                {loading && (
                                    <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                    />
                                )}
                            </div>
                        </>
                    )
                )}
            </div>
            {localShipping ? (
                <div class="cart-shipping-results">
                    <RadioGroup aria-label="shipping-method" name="shipping-method">
                        {localShipping &&
                            localShipping.map((item) => (
                                <FormControlLabel
                                    value={item.name}
                                    control={
                                        <Radio
                                            key={item.name}
                                            icon={
                                                <RadioButtonUncheckedIcon
                                                    style={{ fontSize: 17 }}
                                                />
                                            }
                                            checkedIcon={
                                                <RadioButtonCheckedIcon
                                                    style={{ fontSize: 17 }}
                                                />
                                            }
                                            disabled={!item.active}
                                            classes={{
                                                root: classes.root,
                                            }}
                                            checked={
                                                props.shipping &&
                                                props.shipping.method === item.name
                                            }
                                            onClick={() => handleME(item)}
                                        />
                                    }
                                    label={
                                        item.name +
                                        " : " +
                                        item.desc +
                                        (item.days ? " (" + item.days + ") " : "")
                                    }
                                    classes={{
                                        label: classes.label,
                                    }}
                                />
                            ))}
                    </RadioGroup>
                </div>
            ) : (
                loading &&
                props.searchBy && (
                    <div style={{ position: "relative" }}>
                        <CircularProgress
                            size={30}
                            className={classes.buttonProgressCheckout}
                        />
                    </div>
                )
            )}
        </>
    ) : loading && props.searchBy ? (
        <div style={{ position: "relative" }}>
            <CircularProgress size={30} className={classes.buttonProgressCheckout} />
        </div>
    ) : (
        <>
            <Snackbar
                open={open}
                //onClose={handleClose}
                message={message}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                autoHideDuration={5000}
                onClose={handleClose}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    business: state.business.current,
    shipping: state.shipping.current,
    total: getCartTotal(state.cartList.cart, state.shipping.current),
    subtotal: getCartTotal(state.cartList.cart),
});

export default connect(mapStateToProps, { setShipping })(Shipping);
