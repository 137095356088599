import React, { useState, useEffect } from "react";

import TextField from "@material-ui/core/TextField";

import Rating from "@material-ui/lab/Rating";
import "./styles_review.css";
import service from "../api/service";

import { loadBusiness, loadBusinessCustomDomain } from "../api/products";

function ReviewPage(props) {
    const [title, setTitle] = useState();
    const [errorTitleMessage, setErrorTitleMessage] = useState("");
    const [review, setReview] = useState();
    const [errorReviewMessage, setErrorReviewMessage] = useState("");
    const [stars, setStars] = useState();
    const [business, setBusiness] = useState();
    const [sent, setSent] = useState(false);

    useEffect(() => {
        async function init() {
            // url direta
            let host = window.location.host;
            //console.log(host);
            if (host.indexOf(".carty") === -1 && host.indexOf("localhost") === -1) {
                let b = await loadBusinessCustomDomain(host);
                setBusiness(b);
            } else {
                let parts = host.split(".");
                let uid = parts[0];
                let b = await loadBusiness(uid);
                setBusiness(b);
            }
        }
        init();
    }, []);

    async function saveReview() {
        if (stars === null || stars === 0 || stars === undefined) {
            alert("Classifique entre 1 a 5 estrelas");
            return;
        }
        if (title === null || title === "" || title === undefined) {
            setErrorTitleMessage("Digite o título da sua avaliação.");
            return;
        }
        if (review === null || review === "" || review === undefined) {
            setErrorReviewMessage("Descreva brevemente a sua experiência.");
            return;
        }

        let rating = {
            title,
            stars,
            review,
            orderId: props.match.params.orderId,
        };
        //console.log(rating);
        await service.post("/rating/order", rating);
        setSent(true);
    }

    return (
        <div id="review-wrap">
            <div class="review-header">
                {business && <img src={business.logo} alt="img1" class="review-logo" />}
                <div class="store-name">{business && business.name}</div>
            </div>
            {sent ? (
                <div class="review-data">
                    <div class="review-success">Muito obrigado pela avaliação!</div>
                </div>
            ) : (
                <div class="review-data">
                    <div class="review-stars">
                        <div class="label">
                            De uma forma geral, como você avalia a nossa loja virtual?
                        </div>
                        <Rating
                            value={stars}
                            size="large"
                            onChange={(e, value) => setStars(value)}
                        />
                    </div>
                    <div class="review-title">
                        <div class="label">Digite um título para a avaliação:</div>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{ marginBottom: 20 }}
                            required
                            error={errorTitleMessage !== ""}
                            helperText={errorTitleMessage}
                            value={title}
                            onChange={(e) => {
                                setErrorTitleMessage("");
                                setTitle(e.target.value);
                            }}
                        />
                    </div>
                    <div class="review-content">
                        <div class="label">Descreva a sua experiência:</div>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            rows={4}
                            size="small"
                            multiline
                            color="secondary"
                            fullWidth
                            style={{ marginBottom: 20 }}
                            error={errorReviewMessage !== ""}
                            helperText={errorReviewMessage}
                            value={review}
                            onChange={(e) => {
                                setErrorReviewMessage("");
                                setReview(e.target.value);
                            }}
                        />
                    </div>
                    <div class="review-button" onClick={() => saveReview()}>
                        ENVIAR
                    </div>
                </div>
            )}
        </div>
    );
}

export default ReviewPage;
