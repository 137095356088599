import React from "react";
import { Route, Switch } from "react-router-dom";
import QuotaExceeded from "./main/QuotaExceededPage";
export const QuotaExceededLayout = () => {
    return (
        <Switch>
            <Route
                path={`${process.env.PUBLIC_URL}/`}
                component={QuotaExceeded}
                exact={true}
            />
        </Switch>
    );
};
