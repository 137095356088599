import React, { useState, useEffect } from "react";
import SearchInput from "react-search-input";
import { setSearchTerm } from "../actions";
import { connect } from "react-redux";

function SearchBar(props) {
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        //console.log(searchTerm);
        props.setSearchTerm(searchTerm);
    }, [props, searchTerm]);

    return (
        <div className="search-bar">
            <SearchInput
                className="search-input"
                onChange={setSearchTerm}
                placeholder={"Buscar"}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
});

export default connect(mapStateToProps, { setSearchTerm })(SearchBar);
