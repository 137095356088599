import React from "react";
import Badge from "@material-ui/core/Badge";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { filterCategory } from "../../actions";
import { useHistory } from "react-router-dom";

function Header(props) {
    let history = useHistory();

    function goBack() {
        if (props.isPromo) {
            props.filterCategory(null);
        }
        //props.filterCategory(null);
        history.goBack();
    }
    return (
        <div className="container-header-dark">
            {props.back ? (
                <div className="back-dark" onClick={() => goBack()}>
                    <i class="fa fa-chevron-left"></i>
                </div>
            ) : (
                <div class="zap">
                    <a
                        href={`https://api.whatsapp.com/send?phone=55${props.business.whatsappNumber}`}
                        class="link"
                    >
                        <i class="fa fa-whatsapp"></i>
                    </a>
                </div>
            )}
            <Link
                style={{ textDecoration: "none" }}
                to={{
                    pathname: `${process.env.PUBLIC_URL}/`,
                }}
                className="secondary"
            >
                <div className="title-dark">{props.business.name}</div>
            </Link>
            <div className="cart-dark">
                <div className="badge-dark">
                    <Badge
                        component={Link}
                        to={`${process.env.PUBLIC_URL}/cart`}
                        color="secondary"
                        badgeContent={props.cartItems.reduce((a, { qty }) => a + qty, 0)}
                        overlap="circle"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                    >
                        <div className="icon">
                            <i class="fa fa-shopping-bag"></i>
                        </div>
                    </Badge>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
    cartItems: state.cartList.cart,
});

export default connect(mapStateToProps, { filterCategory })(Header);
