import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";

//import InputMask from "react-input-mask";

//import service from "../../api/service";

import Button from "@material-ui/core/Button";

import "./styles_checkout.css";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Snackbar from "@material-ui/core/Snackbar";

import InputAdornment from "@material-ui/core/InputAdornment";

import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

function DialogPaymentLink(props) {
    const [message, setMessage] = useState("");

    const [openPix, setOpenPix] = useState(props.openPix);
    const openModal = props.openModal;

    const handleClosePix = (event, reason) => {
        if (reason && reason === "backdropClick") return;
    };

    const handleCloseMessage = () => {
        setMessage("");
    };

    function handleCancelPix() {
        setOpenPix(false);
    }

    return (
        <div>
            {!openModal ? (
                <div
                    style={{
                        backgroundColor: "#F8F8F8",
                        padding: 50,
                        marginBottom: 30,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <img
                            alt="link de pagamento"
                            style={{ width: 35, height: 35 }}
                            src="https://res.cloudinary.com/rockedevelopr/image/upload/v1643817265/carty/logo-mercado-pago-icone-1024_acxupw.png"
                        />
                        <div style={{ marginLeft: 10, fontSize: 20 }}>
                            LINK DE PAGAMENTO
                        </div>
                    </div>

                    <div style={{ marginTop: 10, marginLeft: 10, fontSize: 16 }}>
                        Clique no botão abaixo para gerar o link de pagamento.
                    </div>
                    <div style={{ marginLeft: 10, fontSize: 16 }}>
                        Você também receberá esse link por e-mail.
                    </div>

                    <div onClick={() => setOpenPix(true)} className="slip-button">
                        GERAR LINK DE PAGAMENTO
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            marginTop: 20,
                            fontWeight: "bold",
                        }}
                    >
                        AGUARDANDO PAGAMENTO
                    </div>
                    <div onClick={() => setOpenPix(true)} className="slip-button">
                        VER LINK DE PAGAMENTO
                    </div>
                </div>
            )}
            <Snackbar
                open={message !== ""}
                //onClose={handleClose}
                message={message}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                autoHideDuration={3000}
                onClose={handleCloseMessage}
            />

            <Dialog
                fullWidth={true}
                open={openPix}
                onClose={handleClosePix}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {"Pedido aguardando pagamento"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div style={{ margin: 5 }}>
                            <div style={{ fontSize: 16, textAlign: "center" }}>
                                Copie o link abaixo e cole no seu navegador para realizar
                                o pagamento ou clique no botão pagar agora ( você será
                                redirecionado para o Mercado Pago para realização do
                                pagamento )
                            </div>
                            <div>
                                <CopyToClipboard
                                    text={props.initPoint}
                                    onCopy={() => setMessage("Link copiado com sucesso!")}
                                >
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        disabled
                                        style={{ marginBottom: 20, marginTop: 20 }}
                                        value={props.initPoint}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FileCopyOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </CopyToClipboard>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCancelPix()} color="primary">
                        CANCELAR
                    </Button>
                    <CopyToClipboard text={props.initPoint}>
                        <Button
                            onClick={() => setMessage("Código copiado com sucesso!")}
                            color="primary"
                        >
                            COPIAR LINK
                        </Button>
                    </CopyToClipboard>
                    <Button
                        onClick={() => window.open(props.initPoint, "_blank")}
                        color="primary"
                    >
                        PAGAR AGORA
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
});

export default connect(mapStateToProps, {})(DialogPaymentLink);
