import React from "react";

function TermsPage(props) {
    return (
        <div class="terms">
            <h3>TERMOS E CONDIÇÕES DE USO: </h3>
            <p>Atualizado em 08/03/2022</p>
            <p>
                Estes Termos e Usos (doravante denominados “Termos e Condições de Uso”)
                regulamenta o uso do serviço que o Carty Brasil (www.carty.com.br )
                oferece aos seus USUÁRIOS.
            </p>
            <p>
                1) Qualquer pessoa, física ou jurídica, doravante nominada USUÁRIO, que
                pretenda utilizar os serviços da Carty.com.br, deverá aceitar as Cláusulas
                de Uso e todas as demais políticas e princípios que as regem.
            </p>
            <p>
                2) A ACEITAÇÃO DESTES TERMOS E CONDIÇÕES GERAIS É INDISPENSÁVEL À
                UTILIZAÇÃO DOS SITES E SERVIÇOS PRESTADOS PELA Carty.com.br. O USUÁRIO
                deverá ler, certificar-se de haver entendido e aceitar todas as
                disposições estabelecidas nos Termos e Condições e na Política de
                Privacidade, para que então seja efetuado com sucesso seu cadastro como
                USUÁRIO da Carty.com.br.
            </p>
            <h4>II – DO USUÁRIO: </h4>
            <p>
                3) Os serviços do carty.com.br somente estão disponíveis para as pessoas
                que tenham plena capacidade de fato para contratar. Dessa forma, não podem
                efetuar cadastro pessoas menores de 18 anos ou acometidas por outras
                incapacidades inscritas nos artigos 3º e 4º do Código Civil Brasileiro,
                salvo se devidamente representadas ou assistidas.
            </p>
            <p>
                4) As pessoas jurídicas efetuarão seus cadastros por meio de representante
                legal.
            </p>
            <p>
                5) O USUÁRIO se compromete a fornecer seus dados pessoais de forma
                verdadeira e precisa, devendo atualizá-los sempre que ocorrer alguma
                alteração.
            </p>
            <p>
                6) O Carty.com.br não se responsabiliza pela veracidade das informações
                prestadas por seus USUÁRIOS, sendo deles toda a responsabilidade por seu
                conteúdo.
            </p>
            <p>
                7) O Carty.com.br pode checar a veracidade dos dados cadastrais de um
                USUÁRIO a qualquer tempo. Caso constate haver entre eles dados incorretos
                ou inverídicos, ou ainda caso o USUÁRIO se furte ou se negue a enviar os
                documentos requeridos, a Carty.com.br poderá bloquear o perfil do USUÁRIO,
                até que a irregularidade seja sanada.
            </p>
            <p>
                8) O USUÁRIO acessará sua conta através de login e senha, comprometendo-se
                a não informar a terceiros esses dados, responsabilizando-se integralmente
                pelo uso que deles seja feito.
            </p>
            <p>
                9) O USUÁRIO compromete-se a notificar a Carty.com.br imediatamente,
                através de comunicação em que fique certificado o recebimento pela
                Carty.com.br, a respeito de qualquer uso não autorizado de sua conta, bem
                como o acesso não autorizado por terceiros a mesma. O USUÁRIO será o único
                responsável pelas operações efetuadas em sua conta, uma vez que o acesso à
                mesma só será possível mediante a senha pessoal do USUÁRIO.
            </p>
            <p>
                10) O login que o USUÁRIO utiliza na Carty.com.br não poderá guardar
                semelhança com o nome Carty.com.br, tampouco poderá ser utilizado qualquer
                login considerado ofensivo, bem como os que contenham dados pessoais do
                USUÁRIO ou alguma URL ou endereço eletrônico.
            </p>
            <p>
                11) O USUAŔIO é o responsável por todas as despesas referentes ao
                aprovisionamento e entrega dos seus produtos e ao fornecimentos dos mesmos
                de maneira profissional, em consonância com os padrões do setor.
            </p>
            <p>
                12) O USUÁRIO é o único responsável por todas as declarações e promessas
                que fizer e por qualquer assistência, garantia e suporte relativo aos
                produtos ofertados, devendo informar dados de contato fidedignos no seu
                website para fins de dúvidas, reclamações ou solicitações.
            </p>
            <p>
                13) O USUÁRIO não poderá oferecer ou vender qualquer produto ou material
                que seja considerado perigoso, falsificado, roubado, fraudulento, ofensivo
                ou abusivo, que tenha venda, distribuição ou uso proibido, ou que de outra
                forma não cumpra as leis aplicáveis, incluindo direito do consumidor,
                propriedade intelectual ou privacidade, segurança de produtos,
                regulamentações e sanções comerciais, suporte, manutenção e exportação.
            </p>
            <p>
                14) O USUÁRIO não poderá se utilizar das imagens armazenadas nos
                servidores do Carty.com.br para contrução de catálogos ou sites em outras
                plataformas.
            </p>

            <h4>III – DO SERVIÇO PRESTADO:</h4>
            <p>
                15) A Carty.com.br é um aplicativo que possui como objetivo criar websites
                e sua estrutura para seus USUÁRIOS.
            </p>
            <p>
                16) Não será permitido o anúncio de serviços ou imagens ilícitas,
                imorais,discriminatórias ou que violem direitos autorais de terceiros
                como, por exemplo, relacionadas a pornografia infantil e racismo, entre
                outras. Bem como será proibida a exposição à venda de produtos destinados
                a práticas criminosas, substancias entorpecentes ou farmacológicas
                ilícitas ou de venda restrita pela Anvisa, podendo a Carty.com.br
                retirá-los do ar imediatamente, mediante comunicação posterior.
            </p>
            <p>
                17) Caso algum terceiro notifique a Carty.com.br alegando o cometimento de
                violação de propriedade intelectual/industrial por algum de seus USUÁRIOS,
                através de anúncios em seus respectivos websites, A Carty.com.br
                reserva-se no direito de, averiguada a denúncia, retirar o website do ar
                imediatamente, mediante comunicação posterior ao USUÁRIO.
            </p>
            <p>
                18) A qualquer momento a Carty.com.br poderá, a seu exclusivo critério,
                suspender ou interromper por prazo indeterminado os serviços prestados ao
                USUÁRIO, em casos de suspeita de cometimento de fraudes ao consumidor,
                fundamentada por denúncias de qualquer tipo, notificações judiciais e/ou
                extrajudiciais, citações em processos de responsabilidade a qualquer
                título.
            </p>
            <p>
                19) Caso sejam detectados acessos robóticos e/ou ilegais e/ou que
                comprometam a infraestrutura da plataforma na loja virtual do USUÁRIO, a
                Carty.com.br poderá bloquear automaticamente a loja por tempo
                indeterminado por medidas de segurança.
            </p>
            <p>
                20) A Carty.com.br não se responsabiliza por qualquer dano, prejuízo ou
                perda sofridos pelo USUÁRIO em razão de falhas na internet, no sistema ou
                no servidor utilizados pelo USUÁRIO, decorrentes de condutas de terceiros,
                caso fortuito ou força maior. A Carty.com.br também não será responsável
                por qualquer vírus que ataque o equipamento do USUÁRIO em decorrência do
                acesso, utilização ou navegação na internet ou devido a transferências de
                dados, arquivos, imagens, textos ou áudio.
            </p>
            <p>
                21) A Carty.com.br se responsabiliza apenas pelos serviços oferecidos no
                seu aplicativo, não oferecendo suporte a quaisquer produtos adquiridos em
                outras empresas, não assumindo prejuízo algum devido à impossibilidade de
                instalação ou danos a loja, devido à utilização incorreta de códigos
                oferecido por terceiros.
            </p>
            <p>
                22) A Carty.com.br não possui qualquer relação com a atividade
                desenvolvida pelos USUÁRIOS no website, ficando sua responsabilidade
                restrita ao desenvolvimento funcional do aplicativo.
            </p>
            <p>
                23) A Carty.com.br se reserva ao direito de excluir as lojas virtuais
                criadas e inativas quando o período de inatividade for superior a 2 meses.
                Os usuários serão notificados antecipadamente via email da exclusão por
                inatividade. Essa cláusula é válida apenas para usuários que não possuem
                subscrição ativa de qualquer Plano de Uso comercializado pela plataforma.
            </p>
            <p>
                24) A Carty.com.br comercializa através da sua plataforma diferentes
                Planos de Uso que oferecem acesso a funcionalidades específicas e
                restritas aos assinantes dos Planos.
            </p>
            <p>
                25) Os Planos de Uso serão comercializados no formato de subscrições
                mensais, com renovação automática, e serão contratados exclusivamente pelo
                aplicativo Carty existente nas lojas de aplicativos.
            </p>
            <p>
                26) A Carty.com.br se reserva no direito de reajustar unilateralmente, a
                qualquer tempo, os valores dos planos ofertados através da plataforma sem
                consulta ou anuência prévia do usuário.
            </p>
            <p>
                27) O usuário poderá cancelar a contratação do plano de uso nos termos que
                forem definidos no momento de sua contratação. Nessa condição o acesso ao
                Plano de uso será interrompido após concluído o período vigente da
                subscrição contratada.
            </p>
            <p>
                28) O cancelamento da subscrição dos Planos de Uso deverá ser realizado
                através das lojas de aplicativos: App Store para usuários de dispositivos
                iPhone e Play Store para usuários de dispositivos Android.
            </p>
            <p>
                29) O Carty.com.br dispoe de Infraestrutura de TI armazenada em núvem e
                compartilhada entre os usuários do serviço. A Carty.com.br utiliza de
                algorítmos automatizados para a detecção de tentativas de ataques a nossa
                infraestrutura para prover a disponibilidade dos serviços aos usuários da
                plataforma.
            </p>

            <h4>IV – DO PAGAMENTO NO SITE:</h4>
            <p>
                30) O Carty.com.br oferece para os assinantes do Plano Pro, integração com
                a plataforma MERCADO PAGO para realização de checkout transparente
                conhecida como Pagamento na Loja nos websites criados no Carty.
            </p>
            <p>
                31) A integração com o MERCADO PAGO restringe-se a funcionalidade de
                pagamento utilizando a função crédito.
            </p>
            <p>
                32) Através da funcionalidade de Pagamento na Loja, o USUÁRIO poderá
                receber na sua conta do MERCADO PAGO os valores correspondentes das
                compras realizadas na sua loja virtual.
            </p>
            <p>
                33) Para a utilização do serviço, o USUÁRIO precisa criar uma conta no
                MERCADO PAGO. O Carty.com.br não garante a aprovação do USUÁRIO para na
                criação dessa conta, sendo de responsabilidade exclusiva do MERCADO PAGO a
                aprovação da conta do USUÁRIO.
            </p>
            <p>
                34) O USUAŔIO precisa aceitar os Termos de Uso do MERCADO PAGO para a
                utilização da funcionalidade de Pagamento na Loja bem como tomar ciência
                das taxas praticadas pelo MERCADO PAGO.
            </p>
            <p>
                35) O Carty.com.br não cobra nenhuma taxa adicional para o uso da
                funcionalidade de Pagamento na Loja.
            </p>
            <p>
                36) A transação é realizada diretamente entre o cliente e a plataforma do
                MERCADO PAGO através de API específica fornecida pela empresa. O
                Carty.com.br não se responsabiliza por eventuais indisponibilidades no
                serviço fornecido pelo MERCADO PAGO.
            </p>
            <p>
                37) As transações da funcionalidade Pagamento na Loja são realizadas em
                ambiente seguro SSL, seguindo todas as recomendações da empresa MERCADO
                PAGO.
            </p>
            <p>
                38) O Carty.com.br não se responsabiliza por qualquer dano, prejuízo ou
                perda sofridos pelo USUÁRIO e/ou CLIENTE no uso da funcionalidade
                Pagamento no Site uma vez que as transações são realizadas diretamente
                entre o CLIENTE e a plataforma do MERCADO PAGO.
            </p>

            <h4>V – DAS DISPOSIÇÕES FINAIS:</h4>
            <p>
                39) Estes Termos e Condições Gerais não geram nenhum contrato de
                sociedade, de mandato, franquia ou relação de trabalho entre a
                Carty.com.br e o USUÁRIO. O USUÁRIO manifesta ciência de que a
                Carty.com.br não é parte de nenhuma transação realizada entre USUÁRIOS,
                nem possui controle algum sobre a qualidade, segurança ou legalidade dos
                produtos ou serviços anunciados pelos USUÁRIOS, sobre a veracidade ou
                exatidão dos anúncios elaborados pelos USUÁRIOS, e sobre a capacidade dos
                USUÁRIOS para negociar.
            </p>
            <p>
                40) O conteúdo deste instrumento poderá ser modificado livremente pela
                Carty.com.br por meio de comunicação eletrônica aos USUÁRIOS, passando os
                novos termos a valer desde a data em que forem veiculados no site da
                Carty.com.br, reservando-se ao USUÁRIO o direito de permanecer cadastrado
                ou simplesmente solicitar sua exclusão.
            </p>
            <p>
                41) Todo o conteúdo, como, por exemplo, textos, programas de computador,
                sons, fotografias e outros materiais disponíveis na Carty.com.br, marcas,
                patentes, modelos, desenhos industriais, a aparência, a organização e a
                estrutura da Carty.com.br são protegidas pela legislação de propriedade
                intelectual aplicável e não infringem qualquer lei ou norma a que estejam
                subordinadas, contratos, documentos, acordos dos quais faz parte, bem como
                não infringe direitos de terceiros. A violação de tais direitos ensejará a
                respectiva reparação integral dos danos aos prejudicados, seja a
                Carty.com.br, seus USUÁRIOS e/ou terceiros.
            </p>
            <p>
                42) É vedado ao USUÁRIO modificar, copiar, distribuir, transmitir, exibir,
                realizar, reproduzir, publicar, disponibilizar, licenciar ou criar obras
                derivadas a partir das informações coletadas no site Carty.com.br, bem
                como transferir ou vender tais informações, software, produtos ou
                serviços, sob pena de violação do presente termo e infração legal.
            </p>
            <p>
                43) Fica eleito, para dirimir eventuais dúvidas e resolver os conflitos
                oriundos deste contrato, o foro da Cidade de Brasília, Distrito Federal,
                com renúncia a qualquer outro, por mais privilegiado que seja, salvo os
                USUÁRIOS que se enquadrem no conceito legal de consumidor e optem pela
                prerrogativa legal.
            </p>
            <p>
                44) Os casos omissos neste instrumento serão resolvidos de conformidade
                com as disposições legais aplicáveis à espécie.
            </p>
        </div>
    );
}
export default TermsPage;
