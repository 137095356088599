/* eslint-disable react/jsx-no-target-blank */
import React from "react";

import { connect } from "react-redux";

function Slip(props) {
    return (
        <div
            style={{
                backgroundColor: "#F8F8F8",
                padding: 50,
                marginBottom: 30,
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}
            >
                <img
                    alt="barcode"
                    style={{ width: 35, height: 35 }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1627344462/carty/boleto_d4tn2n.png"
                />
                <div style={{ marginLeft: 10, fontSize: 20 }}>BOLETO BANCÁRIO</div>
            </div>

            <div>Clique no botão abaixo para gerar o boleto.</div>
            <div>Prazo para compensação: 3 dias</div>

            <a href={props.slip_url} className="slip-button" target="_blank">
                GERAR BOLETO
            </a>
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
});

export default connect(mapStateToProps, {})(Slip);
