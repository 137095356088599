import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppsIcon from "@material-ui/icons/Apps";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Currency from "../../components/common/currency";
import { connect } from "react-redux";
import store from "../../store";
import { getAllProducts, getBusiness, filterCategory } from "../../actions";
import { countPageView } from "../../api/products";

import GridView from "./GridView";
import ListView from "./ListView";
import "./styles_f.css";

import Ratings from "../Ratings";

import MetaTags from "react-meta-tags";
import SearchBar from "../SearchBar";
import ReactLoading from "react-loading";
import { getVisibleProducts } from "../../services";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

function LandPage(props) {
    let width = window.innerWidth;
    const classes = useStyles();
    const [tabIndex, setTabIndex] = useState(0);
    const [view, setView] = useState();
    const [categories, setCategories] = useState();

    const handleChange = (event, newValue) => {
        //console.log(newValue);
        setTabIndex(newValue);
    };

    function fade(element) {
        var op = 1; // initial opacity
        var timer = setInterval(function () {
            if (op <= 0.1) {
                clearInterval(timer);
                element.style.display = "none";
            }
            element.style.opacity = op;
            element.style.filter = "alpha(opacity=" + op * 100 + ")";
            op -= op * 0.1;
        }, 10);
    }

    function unfade(element) {
        var op = 0.1; // initial opacity
        element.style.display = "block";
        var timer = setInterval(function () {
            if (op >= 1) {
                clearInterval(timer);
            }
            element.style.opacity = op;
            element.style.filter = "alpha(opacity=" + op * 100 + ")";
            op += op * 0.1;
        }, 10);
    }

    useEffect(() => {
        function fadeWhatsapp() {
            window.addEventListener("scroll", function () {
                let scrollHeight = Math.max(
                    document.body.scrollHeight,
                    document.documentElement.scrollHeight,
                    document.body.offsetHeight,
                    document.documentElement.offsetHeight,
                    document.body.clientHeight,
                    document.documentElement.clientHeight
                );

                if (scrollHeight === window.scrollY + window.innerHeight) {
                    if (
                        document.getElementById("whatsapp") &&
                        document.getElementById("whatsapp").style.display !== "none"
                    ) {
                        fade(document.getElementById("whatsapp"));
                    }
                } else {
                    if (
                        document.getElementById("whatsapp") &&
                        document.getElementById("whatsapp").style.display === "none"
                    ) {
                        unfade(document.getElementById("whatsapp"));
                    }
                }
            });
        }
        async function init() {
            fadeWhatsapp();
            //console.log("chamei o init");
            //console.log(props.selected.category);
            // Parâmetro que vem da tela de detalhamento do produto.
            document.body.style = "background: #fff;";
            if (props.location.state && props.location.state.view) {
                const { view } = props.location.state;
                setView(view);
            } else {
                setView("grid");
            }
            let business = JSON.parse(localStorage.getItem("currentBusiness"));
            let newClient = localStorage.getItem("newClient");

            if (business && business.name) {
                if (newClient === "true") {
                    //alert("contei");
                    countPageView(business._id);
                    localStorage.setItem("newClient", "false");
                }
                store.dispatch(getAllProducts(business));
                store.dispatch(getBusiness(business));
                const favicon = document.getElementById("favicon");
                favicon.href = business.logo;
                document.title = business.name;
            }
        }
        init();
    }, [props.location.state]);

    const isGroupCategory = useCallback(() => {
        return (
            (props.business.activePlanName === "Premium" ||
                props.business.activePlanName === "Pro" ||
                props.business.activePlanName === "Diamante") &&
            props.categories &&
            props.categories.length > 0 &&
            props.categories[0].categories
        );
    }, [props.business.activePlanName, props.categories]);

    useEffect(() => {
        if (isGroupCategory()) {
            let cat = [];
            props.categories.forEach((c) => {
                c.categories.forEach((c2) => {
                    cat.push(c2.name);
                });
            });
            setCategories(["Todos", ...cat]);
        } else {
            setCategories([
                "Todos",
                ...props.categories
                    .sort((a, b) => {
                        if (a.order) return -1;
                        else if (b.order) return 1;
                        return 0;
                    })
                    .map((c) => {
                        return c.name;
                    }),
            ]);
        }
    }, [isGroupCategory, props.categories]);

    useEffect(() => {
        if (props.selected.category && categories) {
            setTabIndex(categories.indexOf(props.selected.category));
        }
    }, [categories, props.selected.category]);

    function changeView(view) {
        setView(view);
    }
    function isLoading() {
        return localStorage.getItem("loaded") !== "true";
    }
    return (
        <div id="cool-wrap">
            {isLoading() && props.products.length === 0 ? (
                <div className="loading-area">
                    <ReactLoading type={"bubbles"} color={"black"} />
                </div>
            ) : (
                <>
                    <MetaTags>
                        <title>{props.business.name}</title>
                        <meta name="description" content={props.business.details} />
                        <meta property="og:title" content={props.business.name} />
                        <meta property="og:image" content={props.business.logo} />
                    </MetaTags>

                    <div className="header">
                        <img
                            src="../assets/blank_image_top.png"
                            alt="carty icon"
                            width="50"
                            height="50"
                        />

                        <Badge
                            color="secondary"
                            badgeContent={props.cartItems.reduce(
                                (a, { qty }) => a + qty,
                                0
                            )}
                            showZero
                            overlap="circle"
                        >
                            <div className="cart">
                                <Button
                                    component={Link}
                                    to={`${process.env.PUBLIC_URL}/cart`}
                                    size="small"
                                    color="inherit"
                                    style={{ minWidth: 0 }}
                                >
                                    <ShoppingCartIcon
                                        style={{ fontSize: 24, color: "#707070" }}
                                    />
                                </Button>
                            </div>
                        </Badge>
                    </div>
                    <div className="header-title-cool">
                        <img className="logo" src={props.business.logo} alt="logo" />
                        <p>{props.business.description}</p>
                    </div>
                    {props.business.freeShipping > 0 && (
                        <div className="free-shipping">
                            <div className="food">
                                <div class="space">
                                    FRETE GRÁTIS para compras acima de
                                </div>
                                <Currency value={props.business.freeShipping} />
                            </div>
                        </div>
                    )}

                    <div className="content-filter">
                        <Paper className={classes.root}>
                            <Tabs
                                value={tabIndex}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant={width > 480 ? "standard" : "scrollable"}
                                scrollButtons={width > 480 ? "off" : "auto"}
                                centered={width > 480 ? true : false}
                            >
                                {categories &&
                                    categories.map((category) => {
                                        return (
                                            <Tab
                                                label={category}
                                                onClick={() =>
                                                    props.filterCategory(category)
                                                }
                                            />
                                        );
                                    })}
                            </Tabs>
                        </Paper>
                    </div>

                    <div className="content-view">
                        <AppsIcon
                            style={{
                                fontSize: 35,
                                marginRight: 15,
                                color: "#707070",
                            }}
                            onClick={() => changeView("grid")}
                        />
                        <FormatListBulletedIcon
                            style={{ fontSize: 35, color: "#707070" }}
                            onClick={() => changeView("list")}
                        />
                    </div>

                    {view === "grid" ? (
                        <div className="banner">
                            <GridView business={props.business.uid} />
                        </div>
                    ) : view === "list" ? (
                        <div className="banner">
                            {props.business.features &&
                                props.business.features.includes("searchbar") &&
                                (props.business.activePlanName === "Premium" ||
                                    props.business.activePlanName === "Pro" ||
                                    props.business.activePlanName === "Diamante") && (
                                    <SearchBar />
                                )}

                            <ListView business={props.business.uid} />
                        </div>
                    ) : undefined}

                    {props.business &&
                        props.business.features &&
                        props.business.features.includes("rating") && (
                            <div className="rating">
                                <Ratings business={props.business} />
                            </div>
                        )}

                    <div className="footer">
                        <img className="logo" src={props.business.logo} alt="logo" />
                        <li>
                            <ul>
                                <span>{props.business.phone}</span>
                            </ul>
                            <ul>
                                <span>{props.business.address}</span>
                            </ul>
                            <ul>
                                <span>{props.business.email}</span>
                            </ul>
                        </li>
                    </div>
                    <div id="section-footer" class="line" />
                    {props.business &&
                        (props.business.instagram || props.business.facebook) && (
                            <div className="social-media">
                                <div className="social-media-container-cool">
                                    Siga nossas redes sociais:
                                    {props.business.instagram && (
                                        <div className="icon">
                                            <a
                                                href={
                                                    "instagram://user?username=" +
                                                    props.business.instagram
                                                }
                                            >
                                                <i class="fa fa-instagram"></i>
                                            </a>
                                        </div>
                                    )}
                                    {props.business.facebook && (
                                        <div className="icon">
                                            <a
                                                href={
                                                    "https://www.facebook.com/" +
                                                    props.business.facebook
                                                }
                                            >
                                                <i class="fa fa-facebook"></i>
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                    <div>
                        <link
                            rel="stylesheet"
                            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
                        ></link>
                        <a
                            href={
                                "https://api.whatsapp.com/send?phone=55" +
                                props.business.whatsappNumber
                            }
                            id="whatsapp"
                            class="float"
                        >
                            <i class="fa fa-whatsapp my-float"></i>
                        </a>
                    </div>
                </>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
    categories: state.category.current,
    cartItems: state.cartList.cart,
    selected: state.filters,
    products: getVisibleProducts(state.data, state.filters, state.searchTerm),
});

export default connect(mapStateToProps, { filterCategory })(LandPage);
