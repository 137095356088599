import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";

//import InputMask from "react-input-mask";

import LinearProgress from "@material-ui/core/LinearProgress";

//import service from "../../api/service";

import Button from "@material-ui/core/Button";

import "./styles_checkout.css";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Snackbar from "@material-ui/core/Snackbar";

import InputAdornment from "@material-ui/core/InputAdornment";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Countdown from "react-countdown";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

function DialogPix(props) {
    const [message, setMessage] = useState("");

    const [openPix, setOpenPix] = useState(props.openPix);
    const [progressCountdown, setProgressCountdown] = useState(0);

    let MAX = 60000 * 10;
    let MIN = 0;

    const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);

    const renderer = ({ formatted, total, completed }) => {
        if (completed) {
            setOpenPix(false);
        } else {
            setProgressCountdown(MAX - total);
            // Render a countdown
            return (
                <span>
                    {formatted.minutes}:{formatted.seconds}
                </span>
            );
        }
    };

    const handleClosePix = (event, reason) => {
        if (reason && reason === "backdropClick") return;
    };

    const handleCloseMessage = () => {
        setMessage("");
    };

    function handleCancelPix() {
        setOpenPix(false);
    }

    return (
        <div>
            <div
                style={{
                    backgroundColor: "#F8F8F8",
                    padding: 50,
                    marginBottom: 30,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <img
                        alt="pix"
                        style={{ width: 35, height: 35 }}
                        src="https://res.cloudinary.com/rockedevelopr/image/upload/v1664394679/carty/logo-pix-png-icone-520x520_fty3ld.png"
                    />
                    <div style={{ marginLeft: 10, fontSize: 20 }}>PIX COPIA E COLA</div>
                </div>

                <div style={{ marginTop: 10, marginLeft: 10, fontSize: 16 }}>
                    Clique no botão abaixo para gerar o código pix para pagamento.
                </div>
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                    Você também receberá esse código por e-mail.
                </div>

                <div onClick={() => setOpenPix(true)} className="slip-button">
                    GERAR CÓDIGO
                </div>
            </div>
            <Snackbar
                open={message !== ""}
                //onClose={handleClose}
                message={message}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                autoHideDuration={3000}
                onClose={handleCloseMessage}
            />
            <Dialog
                fullWidth={true}
                open={openPix}
                onClose={handleClosePix}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {"Pedido aguardando pagamento"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div style={{ margin: 5 }}>
                            <div style={{ fontSize: 12, textAlign: "center" }}>
                                Copie o código abaixo e utilize o Pix Copia e Cola no
                                aplicativo que você vai fazer o pagamento:
                            </div>
                            <div>
                                <CopyToClipboard
                                    text={props.qrcode}
                                    onCopy={() =>
                                        setMessage("Código copiado com sucesso!")
                                    }
                                >
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        disabled
                                        style={{ marginBottom: 20, marginTop: 20 }}
                                        value={props.qrcode}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FileCopyOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </CopyToClipboard>
                                <div style={{ fontSize: 12, textAlign: "center" }}>
                                    Você tem até 10 minutos para fazer o pagamento.
                                </div>
                                <div
                                    style={{
                                        fontSize: 12,
                                        textAlign: "center",
                                        marginTop: 20,
                                    }}
                                >
                                    O tempo para você pagar acaba em:
                                </div>

                                <div style={{ fontSize: 20, textAlign: "center" }}>
                                    <Countdown
                                        zeroPadTime={2}
                                        mounted={props.expirationDatePix}
                                        date={props.expirationDatePix}
                                        renderer={renderer}
                                    />
                                </div>

                                <LinearProgress
                                    variant="determinate"
                                    value={normalise(progressCountdown)}
                                />
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCancelPix()} color="primary">
                        CANCELAR
                    </Button>
                    <CopyToClipboard text={props.qrcode}>
                        <Button
                            onClick={() => setMessage("Código copiado com sucesso!")}
                            color="primary"
                        >
                            COPIAR CÓDIGO
                        </Button>
                    </CopyToClipboard>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
});

export default connect(mapStateToProps, {})(DialogPix);
