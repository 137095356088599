import moment from "moment";
import { getPaymentMethods } from "@mercadopago/sdk-react";

const errors = {
    name: "Digite o seu nome",
    cellphone: "Digite um número de celular válido.",
    email: "Digite um email válido.",
    zipcode: "Digite o CEP da entrega.",
    addressNumber: "Digite o número.",
    delivery: "Selecione o tipo de entrega.",
    shippingMethod: "Selecione o método de envio.",
    payment: "Selecione o tipo de pagamento.",
    cpf: "Digite um CPF válido.",
    cardNumber: "Digite um número de cartão válido.",
    cardExpiration: "Digite o mês e ano de validade do cartão ( MM/YY )",
    cardholderName: "Digite o nome do titular conforme apresentado no cartão.",
    securityCode: "Digite o código de segurança.",
    installments: "Selecione a quantidade de parcelas.",
};

export function getErrorDescription(field) {
    return errors[field];
}

async function validateCardNumber(cardNumber) {
    let cardNumberNoSpace = cardNumber.replace(/[^0-9]+/g, "");
    if (cardNumberNoSpace !== "") {
        let bin = cardNumberNoSpace.substring(0, 6);
        const paymentMethods = await getPaymentMethods({ bin: bin });

        return (
            cardNumber !== "" &&
            cardNumberNoSpace.length === 16 &&
            paymentMethods.results &&
            paymentMethods.results.length > 0
        );
    } else {
        return false;
    }
}
function validateCardExpiration(cardExpiration) {
    let cardExpirationMonth = cardExpiration.split("/")[0];
    let cardExpirationYear = cardExpiration.split("/")[1];
    var regex = new RegExp("^((0[1-9])|(1[0-2]))/(2[2-9])|(3[0-9])$");
    let valid = cardExpiration !== "" && regex.test(cardExpiration);
    valid = valid && moment().year() <= 2000 + Number(cardExpirationYear);
    if (moment().year() === 2000 + Number(cardExpirationYear)) {
        valid = valid && moment().month() + 1 <= Number(cardExpirationMonth);
    }
    return valid;
}
function validateCardHolderName(cardholderName) {
    return cardholderName !== "";
}
function validateSecurityCode(securityCode) {
    return securityCode !== "" && securityCode >= 3;
}
function validateInstallments(installments) {
    return installments > 0;
}

function validateCPF(identificationNumber) {
    let cpf = identificationNumber;
    cpf = cpf.replace(/[^\d]+/g, "");
    if (cpf === "") return false;
    // Elimina CPFs invalidos conhecidos
    if (
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999"
    )
        return false;
    // Valida 1o digito
    let add = 0;
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) return false;
    return true;
}

function validatePayment(payment) {
    return payment !== "";
}

function validateAddressDelivery(delivery, address) {
    if (delivery === "delivery") {
        return address !== null && address !== "";
    }
    return true;
}

function validateName(name) {
    return name !== "";
}

function validateCellphone(cellphone) {
    var regex = new RegExp("^\\(((1[1-9])|([2-9][0-9]))\\) (9[0-9]{4}-[0-9]{4})$");
    return regex.test(cellphone);
}

function validateEmail(email) {
    if (email) {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            email
        );
    } else {
        return false;
    }
}

function validateDelivery(delivery) {
    return delivery !== "";
}

function validateShippingAddress(delivery, zipCode) {
    let valid = true;
    if (delivery === "delivery") {
        valid = valid && zipCode !== "";
    }
    return valid;
}

function validateAddressNumber(delivery, addressNumber) {
    let valid = true;
    if (delivery === "delivery") {
        valid = valid && addressNumber !== "";
    }
    return valid;
}

function validateShipping(
    delivery,
    zipCode,
    isFreeShipping,
    shippingFee,
    shippingMethod,
    shippingZipCode,
    shippingDays
) {
    /*
    const validateShipping =
        shippingFee &&
        shippingFee > 0 &&
        shippingMethod &&
        shippingMethod !== "" &&
        shippingZipCode &&
        shippingZipCode !== "" &&
        shippingDays &&
        shippingDays !== "" &&
        deliveryTax &&
        deliveryTax > 0;
        */

    if (isFreeShipping || delivery === "takeout" || !zipCode) {
        return true;
    }
    return shippingMethod && shippingMethod !== "";
}

export async function validateOrder(data) {
    let errorFields = [];
    const { isFreeShipping, isOnsiteCheckoutMP } = data.business;

    const { name, cellphone, email, address, zipCode, number, identificationNumber } =
        data.client;

    const {
        delivery,
        payment,
        shippingFee,
        shippingMethod,
        shippingZipCode,
        shippingDays,
    } = data.order;

    if (!validateName(name)) {
        errorFields.push("name");
    }
    if (!validateCellphone(cellphone)) {
        errorFields.push("cellphone");
    }
    if (!validateEmail(email)) {
        errorFields.push("email");
    }
    if (!validateDelivery(delivery)) {
        errorFields.push("delivery");
    }
    if (!validateAddressDelivery(address)) {
        errorFields.push("address");
    }
    if (!validateShippingAddress(delivery, zipCode)) {
        errorFields.push("zipcode");
    }
    if (!validateAddressNumber(delivery, number)) {
        errorFields.push("addressNumber");
    }

    if (
        !validateShipping(
            delivery,
            zipCode,
            isFreeShipping,
            shippingFee,
            shippingMethod,
            shippingZipCode,
            shippingDays
        )
    ) {
        console.log("não validou shipping");
        errorFields.push("shippingMethod");
    } else {
        console.log("validou shipping");
    }

    if (!validatePayment(payment)) {
        errorFields.push("payment");
    }
    if (!validateCPF(identificationNumber)) {
        errorFields.push("cpf");
    }
    if (payment === "credit-card" && !data.card && isOnsiteCheckoutMP) {
        errorFields.push("cardNumber");
        errorFields.push("cardExpiration");
        errorFields.push("cardholderName");
        errorFields.push("securityCode");
        errorFields.push("installments");
    }
    if (data.card && isOnsiteCheckoutMP) {
        const cardNumber = data.card.cardNumber;
        const cardholderName = data.card.cardholderName;
        const securityCode = data.card.securityCode;
        const installments = data.card.installments;
        const cardExpiration = data.card.cardExpiration;

        if (!(await validateCardNumber(cardNumber))) {
            //console.log("cartao invalido");
            errorFields.push("cardNumber");
        }
        if (!validateCardExpiration(cardExpiration)) {
            errorFields.push("cardExpiration");
        }
        if (!validateCardHolderName(cardholderName)) {
            errorFields.push("cardholderName");
        }
        if (!validateSecurityCode(securityCode)) {
            errorFields.push("securityCode");
        }
        if (!validateInstallments(installments)) {
            errorFields.push("installments");
        }
    }

    console.log(errorFields);

    return errorFields;
}
