import { RECEIVE_BUSINESS } from "../constants/ActionTypes";

const initialState = {
  current: {},
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_BUSINESS:
      return { ...state, current: action.business };
    default:
      return state;
  }
};
export default businessReducer;
