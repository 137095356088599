import React, { useEffect, useState } from "react";

function ProductVariations(props) {
    const [itens, setItens] = useState();
    const [model, setModel] = useState();
    const [selected, setSelected] = useState();

    useEffect(() => {
        props.callback(itens, selected);
    }, [selected, itens]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //calculate itens quantity = quantity - cartItens
        setItens(
            props.product.variations.map((variation) => {
                if (props.cartItems.length > 0) {
                    let cartItens = props.cartItems.filter((item) => {
                        return item._id === variation._id;
                    });
                    let avaialableItems = variation.quantity;
                    if (cartItens && cartItens.length > 0) {
                        avaialableItems = variation.quantity - cartItens[0].qty;
                    }
                    return {
                        ...variation,
                        avaialableItems: avaialableItems,
                    };
                } else {
                    return {
                        ...variation,
                        avaialableItems: variation.quantity,
                    };
                }
            })
        );
        setModel(props.product.model);
        setSelected(props.selected);
    }, [props.product]); // eslint-disable-line react-hooks/exhaustive-deps

    function render(item) {
        if (model && model.type === "list-quantity") {
            return (
                <div class="item">
                    {props.hasStock ? (
                        <div class="description">
                            {item.variationValue} ({item.avaialableItems})
                        </div>
                    ) : (
                        <div class="description">{item.variationValue}</div>
                    )}
                    <div></div>
                    <div class="quantity">
                        {item.variationQuantity > 0 && (
                            <div onClick={() => handleMinus(item)} class="button">
                                -
                            </div>
                        )}
                        {item.variationQuantity > 0 && (
                            <div class="value">{item.variationQuantity}</div>
                        )}
                        <div onClick={() => handleAdd(item)} class="button">
                            +
                        </div>
                    </div>
                </div>
            );
        } else if (model && model.type === "list-box") {
            return (
                <div
                    class={
                        selected === item.variationValue
                            ? "description-on"
                            : "description-off"
                    }
                    onClick={() => {
                        handleAdd(item, 1);
                        setSelected(item.variationValue);
                    }}
                >
                    {item.variationValue}
                </div>
            );
        } else if (model && model.type === "list-radio") {
            return (
                <label class="label">
                    {item.variationValue}
                    <input
                        type="radio"
                        name="radio"
                        checked={selected === item.variationValue}
                        onClick={() => {
                            handleAdd(item, 1);
                            setSelected(item.variationValue);
                        }}
                    />
                    <span class="checkmark"></span>
                </label>
            );
        }
    }

    function handleAdd(item, quantity) {
        //console.log(item);
        //console.log(item.variationQuantity);
        //console.log(quantity);
        setItens(
            itens.map((value) => {
                if (value.variationValue === item.variationValue) {
                    if (props.hasStock && item.avaialableItems === 0) {
                        return value;
                    }
                    return {
                        ...value,
                        variationQuantity:
                            quantity ||
                            (!item.variationQuantity ? 0 : item.variationQuantity) + 1,
                        avaialableItems: value.avaialableItems - 1,
                    };
                }
                if (quantity) {
                    return { ...value, variationQuantity: 0 };
                } else {
                    return value;
                }
            })
        );
    }
    function handleMinus(item) {
        setItens(
            itens.map((value) => {
                if (value._id === item._id) {
                    return {
                        ...value,
                        variationQuantity: value.variationQuantity - 1,
                        avaialableItems: value.avaialableItems + 1,
                    };
                }
                return value;
            })
        );
    }

    return (
        <div class="product-variations">
            <div class="title">{model && model.title}</div>
            <div class={model ? model.type : ""}>
                {itens &&
                    itens
                        .filter((item) => {
                            return item.status;
                        })
                        .map((item) => {
                            return render(item);
                        })}
            </div>
        </div>
    );
}
export default ProductVariations;
