import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    DECREMENT_QTY,
    CLEAR_CART,
    UPDATE_CART,
} from "../constants/ActionTypes";

export default function cartReducer(
    state = {
        cart: [],
    },
    action
) {
    switch (action.type) {
        case ADD_TO_CART:
            const productId = action.product.id;
            const productOption = action.product.option;
            console.log(state.cart);
            if (
                state.cart.findIndex(
                    (product) =>
                        product.id === productId && product.option === productOption
                ) !== -1
            ) {
                console.log("já tem no carrinho");
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === productId && product.option === productOption) {
                        cartAcc.push({
                            ...product,
                            qty: product.qty + action.qty,
                            sum: (
                                (product.promoPrice
                                    ? product.promoPrice
                                    : product.price) *
                                (product.qty + 1)
                            ).toFixed(2),
                        }); // Increment qty
                    } else {
                        cartAcc.push(product);
                    }

                    return cartAcc;
                }, []);

                return { ...state, cart };
            }

            return {
                ...state,
                cart: [
                    ...state.cart,
                    {
                        ...action.product,
                        qty: action.qty,
                        sum: (action.product.price * action.qty).toFixed(2),
                    },
                ],
            };

        case DECREMENT_QTY:
            if (
                state.cart.findIndex(
                    (product) =>
                        product.id === action.product.id &&
                        product.option === action.product.option
                ) !== -1
            ) {
                //console.log("tirando do carrinho");
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === action.product.id && product.qty > 1) {
                        //console.log(product);
                        //console.log(action.qty);
                        //console.log('price: '+product.price+'Qty: '+product.qty)
                        cartAcc.push({
                            ...product,
                            qty: product.qty - (action.qty || 1),
                            sum: (
                                product.price *
                                (product.qty - (action.qty || 1))
                            ).toFixed(2),
                        }); // Decrement qty
                    } else {
                        cartAcc.push(product);
                    }

                    return cartAcc;
                }, []);

                return { ...state, cart };
            }

            return {
                ...state,
                cart: [
                    ...state.cart,
                    {
                        ...action.product,
                        qty: action.qty,
                        sum: action.product.price * action.qty,
                    },
                ],
            };

        case REMOVE_FROM_CART:
            return {
                cart: state.cart.filter(
                    (item) =>
                        !(
                            item.id === action.product.id &&
                            item.option === action.product.option
                        )
                ),
            };

        case UPDATE_CART:
            return {
                cart: state.cart.map((product) => {
                    if (product.id === action.product.id) {
                        return {
                            ...product,
                            quantity: action.product.quantity,
                        };
                    }
                    return product;
                }),
            };

        case CLEAR_CART: {
            return {
                cart: [],
            };
        }

        default:
    }
    return state;
}
