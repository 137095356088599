import React from "react";
import "./styles_support.css";

function SuportCredentials(props) {
    return (
        <div id="support-wrap">
            <div className="support-content">
                <h2>Como realizar a integração com o Mercado Pago </h2>
                <h3>Pré-requisitos</h3>
                <ul>
                    <li>Possuir uma conta no Mercado Pago.</li>
                    <li>Ser assinante do Plano Pro ou Diamante</li>
                    <li>
                        Ter acesso a um computador para ativar as credenciais do Mercado
                        Pago.
                    </li>
                    <li>
                        Caso queira oferecer pagamentos via Pix, é necessário ter as
                        chaves cadastradas. Caso ainda não tenha,{" "}
                        <a href="https://www.youtube.com/watch?v=60tApKYVnkA">
                            clique aqui
                        </a>{" "}
                        para mais informações sobre como cadastrá-las.
                    </li>
                </ul>
                <h4>Passo a passo</h4>
                <p>
                    Para realizar a integração do Carty com o Mercado Pago, é necessário
                    ativar as credenciais de produção na sua conta do Mercado Pago.
                </p>
                <p>
                    Para isso, utilizando um computador, acesse esse link abaixo, ele irá
                    te redirecionar para a tela de Login do Mercado Pago.
                </p>
                <p>
                    Link:{" "}
                    <a
                        href="https://www.mercadopago.com.br/developers/panel/app"
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target={"_blank"}
                    >
                        Clique aqui para ir para o Mercado Pago
                    </a>
                </p>
                <p>
                    Logo após carregar a página, certifique que irá abrir uma página como
                    na imagem abaixo:
                </p>
                <img
                    style={{ width: "100%" }}
                    src={`https://res.cloudinary.com/rockedevelopr/image/upload/v1694698970/carty/mp/1_yruzut.png`}
                    alt="tela de login do mp"
                />

                <ul>
                    <li>
                        Faça o login na sua conta do Mercado Pago.
                    </li>
                </ul>
                <br></br>
                <h3>Criando uma Aplicação</h3>
                <p> 
                    Após o login você será redirecionado para tela "Suas Integrações". 
                    O Mercado Pago cria um conjunto de credenciais para cada Integração. 
                    Se você já tiver criado alguma integração anteriormente, ela será listada aqui.
                </p>
                <img
                    style={{ width: "100%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1694698972/carty/mp/3_nxdv1w.png"
                    alt="criar aplicacao"
                />
                <ul>
                    <li>
                        Clique no botão <b>Criar aplicação</b>.
                    </li>
                </ul>

                <img
                    style={{ width: "100%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1694699752/carty/mp/2_cmcevk.png"
                    alt="criar aplicacao 2"
                />

                <ul>
                    <li>No Campo "Nome da Aplicação", digite o nome da aplicação a qual você está realizando a integração. Ex. <b>Carty App</b></li>
                    <li>Na opção "Qual tipo de solução de pagamento você vai integrar?", marque <b>Pagamentos Online</b> </li>
                    <li>Na opção "Você está utilizando uma plataforma de e-commerce?", marque <b>Sim</b> </li>
                    <li>Na opção "Selecione a plataforma que você vai utilizar para integrar", selecione <b>Outrasplataformas</b> </li>
                </ul>
                <img
                    style={{ width: "100%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1694700287/carty/mp/4_k8lzqr.png"
                    alt="criar aplicacao 3"
                />

                <ul>
                    <li>Na opção "Qual produto você está integrando?", selecione <b>CheckoutTransparente</b> </li>
                    <li>
                        Marque a opção aceitando os termos e condições e clique em <b>Criar aplicação</b>.
                    </li>
                </ul>

                <p>Pronto, a aplicação foi criada. Agora precisamos ativar as credenciais de produção.</p>
                <br></br>

                <h3>Ativando as credenciais de produção.</h3>
                <p>Após a criação da aplicação você visualizará uma tela semelhante a tela abaixo:</p>

                <img
                    style={{ width: "100%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1694700524/carty/mp/5_cfui1p.png"
                    alt="credenciais"
                />
                <ul>
                    <li>Localize a seção "Procurando pelas credenciais de produção?"</li>
                    <li>Clique no link <b>credenciais de produção</b> sublinhado na imagem acima em vermelho.</li>
                </ul>

                <img
                    style={{ width: "100%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1694701273/carty/mp/mp7_zspwzo.png"
                    alt="credenciais"
                />
                

                <p>
                    Na tela Credenciais de Produção, preencha informações sobre o seu negócio para ativar as Credenciais de Produção:
                    <ul>
                    
                        <li>Na primeira opção, selecione o Setor de atividade do seu negócio. Você pode selecionar a opção <b>Outros</b> caso necessário. </li>
                
                        <li>
                            Clique no botão <b>Ativar credenciais de produção.</b>
                        </li>
                    </ul>
                </p>

                <h3>Copiando as credenciais</h3>
                <p>
                    As credenciais de produção são 2 conjuntos de caracteres comumente chamados de "Tokens".
                    São 2 Tokens que precisam ser copiados aqui e colados lá no Carty:
                    <ul>
                        <li>A chave pública ou "Public Key"</li>
                        <li>E o Token de Acesso ou "Access Token"</li>
                    </ul>
                    Ambos possuem o prefixo <b>APP_USR</b>
                </p>
                <img
                    style={{ width: "100%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1694701701/carty/mp/8_v136lr.png"
                    alt="credenciais"
                />
                  <ul>
                        <li>Copie a chave pública ou "Public Key" selecionando o texto e clicando em Control + C. Você também pode clicar no ícone de copiar, localizado no canto direito, como mostrado na tela acima.</li>
                        <li>Copie o Token de Acesso ou "Access Token" selecionando o texto e clicando em Control + C. Você também pode clicar no ícone de copiar, localizado no canto direito, como mostrado na tela acima.</li>
                    </ul>
                <p>
                    Dica 1: Para ver o Access Token é necessário clicar no ícone de olho
                    para revelar a chave.
                </p>
                <p>
                    Dica 2: envie as credenciais para seu email, assim ficará mais fácil
                    ter acesso a elas no Carty.
                </p>
               <br></br>
                <h3>Cadastrando as credenciais no Carty</h3>
                <p>
                    Com as novas credenciais em mãos, abra o Carty no seu celular.
                </p>
                <img
                    style={{ width: "40%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1694703518/carty/mp/1_jtnzyt.png"
                    alt=""
                />
               <ul>
                    <li>
                        Clique em <b>Pagamento na Loja</b>
                    </li>
                </ul>
                <img
                    style={{ width: "40%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1694703517/carty/mp/2_afgmh4.png"
                    alt=""
                />
                <ul>
                    <li>
                        Clique em <b>Credenciais</b>
                    </li>
                    <li>
                        Você vai precisar colocar seu email e senha de
                        acesso ao Carty para acessar essa tela.
                    </li>
                </ul>
              
                <img
                    style={{ width: "40%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1694703516/carty/mp/4_tuecjv.png"
                    alt=""
                />
      
                <ul>
                    <li>
                        No primeiro campo cole a Chave Pública <b>"Public Key"</b> obtida na página do Mercado Pago.
                    </li>
                    <li>
                        No segundo campo cole o Token de Acesso <b>"Access Token"</b> obtido na página do Mercado Pago.
                    </li>
                    <li>Clique em Salvar</li>
                </ul>
           
               
                <p>Você será redirecionado para a tela anterior.</p>
                <p>
                    Agora, será necessário aceitar os termos e condiçoes do Mercado Pago.
                </p>
                <p>Clique em Termos de Uso</p>
                <img
                    style={{ width: "40%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1694703516/carty/mp/6_moeyvf.png"
                    alt=""
                />
                <p>Clique em Eu Concordo.</p>
                <p>Clique em Voltar</p>
              
                <p>Tome ciência das taxas aplicadas pelo Mercado Pago.</p>
                <p>Clique em Taxas</p>
                <img
                    style={{ width: "40%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1694703515/carty/mp/5_h9xozq.png"
                    alt=""
                />
                <p>Clique em Estou Ciente</p>
                <img
                    style={{ width: "40%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1694703515/carty/mp/5_h9xozq.png"
                    alt=""
                />
                <p>
                    Agora basta ativar a integração clicando na chave Desativado/Ativado
                    que sua loja estará integrada com o Mercado Pago.
                </p>
                <img
                    style={{ width: "40%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1694703515/carty/mp/7_krk2hf.png"
                    alt=""
                />
                <p>
                    Qualquer dúvida favor contactar o suporte técnico através do app
                    Carty.
                </p>
                <p>Vá em Perfil e clique em Suporte Técnico.</p>
                
                <h2>
                    Configuração com o Mercado Pago feita. Como saber se está tudo certo?
                </h2>
                <p>
                    Essa é uma dúvida muito comum entre os clientes do Carty por isso
                    criamos esse guia para tentar ajudá-los nesse processo.
                </p>
                <p>Antes de começar é importante verificar:</p>
                <ul>
                    <li>
                        Se as chaves cadastradas ( Chave Pública e Token de acesso )
                        possuem o prefixo ‘APP_USR’. Esse é o prefixo das chaves de
                        produção disponibilizadas pelo Mercado Pago. Caso suas chaves não
                        tenham esse prefixo retorne ao manual de Integração e revise o
                        passo-a-passo para obtenção das chaves.
                    </li>
                    <li>
                        É necessário cadastrar uma chave PIX na sua conta do Mercado Pago
                        para ativar a integração por PIX. Para isso abra o Mercado Pago e
                        vá em Área PIX. Lá é possível cadastrar sua chave.
                    </li>
                    <li>
                        O Mercado Pago não autoriza transações realizadas com o CPF e/ou
                        email do proprietário da conta do Mercado Pago. Utilize outros
                        dados no momento de fazer as simulações de compra na sua loja.
                    </li>
                    <li>
                        Verifique se o cartão de crédito utilizado nos testes possui
                        limite disponível. A integração não permite o uso de cartões do
                        tipo débito.
                    </li>
                    <li>
                        Verifique se o Pagamento na Loja está ativado. Vá em Pagamento na
                        Loja, clique em Mercado Pago e veja se o botão está ativado.
                    </li>
                </ul>
                <p>
                    A melhor forma de saber se a integração foi bem sucedida é fazendo uma
                    simulação de compra na sua loja.
                </p>
                <p>
                    Para isso crie um produto fictício de valor baixo ( isso fica a seu
                    critério ), ele vai ser utilizado nos testes.
                </p>
                <p>
                    Eu gosto de começar testando a forma de pagamento mais simples e a
                    queridinha dos brasileiros, o PIX.
                </p>
                <h3>Pix</h3>
                <ul>
                    <li>Selecione o produto, adicione no Carrinho e Feche a compra.</li>
                    <li>
                        Preencha os dados normalmente lembrando que o email precisa ser
                        diferente do email do titular da conta do Mercado Pago.
                    </li>
                    <li>Selecione a forma de pagamento PIX.</li>
                    <li>
                        Preencha o CPF do comprador. Não pode ser o CPF do titular da
                        compra do Mercado Pago.
                    </li>
                    <li>Clique em Finalizar.</li>
                    <li>
                        O pedido será gerado e então vai aparecer uma seção PIX COPIA E
                        COLA. Clique em Gerar Código.
                    </li>
                    <li>Clique em Copiar Código.</li>
                    <li>
                        Abra o aplicativo do seu Banco preferido, selecione a opção Pix
                        Copia e Cola e cole o Código no campo especificado no app do seu
                        Banco. Faça o pagamento.
                    </li>
                </ul>
                <p>
                    Agora é só verificar se o dinheiro caiu na sua conta do Mercado Pago.
                </p>
                <ul>
                    <li>Abra sua conta do Mercado Pago</li>
                    <li>
                        Verifique em ‘Sua Atividade’ se o dinheiro foi creditado na sua
                        conta.
                    </li>
                </ul>
                <p>
                    Com isso você já tem certeza que a configuração foi bem sucedida e que
                    a integração está funcionando corretamente.
                </p>
                <p>Vamos testar as outras formas de pagamento?</p>

                <h3>Boleto Bancário</h3>
                <p>
                    Para testar o Boleto Bancário o processo é basicamente o mesmo só tem
                    um detalhe: o pedido é gerado antes da compensação do boleto. Isso
                    acontece pois a data de vencimento padrão para pagamentos com boleto é
                    de 3 dias.
                </p>
                <ul>
                    <li>Selecione o produto, adicione no Carrinho e Feche a compra.</li>
                    <li>
                        Preencha os dados normalmente lembrando que o email precisa ser
                        diferente do email do titular da conta do Mercado Pago.
                    </li>
                    <li>Selecione a forma de pagamento Boleto Bancário.</li>
                    <li>
                        Preencha o CPF do comprador. Não pode ser o CPF do titular da
                        compra do Mercado Pago.
                    </li>
                    <li>Clique em Finalizar.</li>
                    <li>
                        O pedido será gerado e aparecerá um link para o cliente
                        imprimir/gerar o boleto.
                    </li>
                </ul>
                <p>
                    Você pode verificar se existe um lançamento na sua conta do Mercado
                    Pago dessa compra:
                </p>
                <ul>
                    <li>Abra sua conta do Mercado Pago</li>
                    <li>
                        Verifique em ‘Sua Atividade’ se existe um registro referente a
                        venda e se ela se encontra na situação aguardando compensação.
                    </li>
                </ul>
                <p>Observações:</p>
                <ul>
                    <li>O pagamento do boleto é opcional para fins de testes.</li>
                    <li>
                        Caso você opte por pagar o boleto você pode acompanhar a
                        compensação através do Carty. Vá em meus pedidos e verifique o
                        estado da compensação.
                    </li>
                    <li>
                        Você pode regerar o boleto a qualquer momento e enviar-lo para seu
                        cliente. Vá em Meus Pedidos, selecione o pedido e clique em Gerar
                        Boleto.
                    </li>
                    <li>Envie o produto após a compensação do Boleto Bancário.</li>
                </ul>

                <h3>Cartão de Crédito</h3>
                <ul>
                    <li>Selecione o produto, adicione no Carrinho e Feche a compra.</li>
                    <li>
                        Preencha os dados normalmente lembrando que o email precisa ser
                        diferente do email do titular da conta do Mercado Pago.
                    </li>
                    <li>Selecione a forma de pagamento Cartão de Crédito.</li>
                    <li>Preencha os dados do Cartão de Crédito.</li>
                    <li>
                        Preencha o CPF do comprador. Não pode ser o CPF do titular da
                        compra do Mercado Pago.
                    </li>
                    <li>Selecione a quantidade de parcelas</li>
                    <li>Clique em Finalizar.</li>
                    <li>
                        Aparecerá uma janela de confirmação que precisa ser confirmada.
                    </li>
                    <li>
                        O pedido será gerado e seu dinheiro cairá na sua conta do Mercado
                        Pago.
                    </li>
                </ul>
                <p>Observações:</p>
                <ul>
                    <li>
                        A aprovação do Cartão de Crédito depende exclusivamente das regras
                        e políticas do Mercado Pago. Quando uma compra não é aprovada o
                        pedido não será gerado no Carty e o cliente receberá uma
                        notificação. Uma compra pode ser recusada por inúmeros fatores.
                        Para ter maiores detalhes você pode acessar sua conta do Mercado
                        Pago e verificar nas Minhas Atividades o motivo da recusa. Existe
                        ainda, na Atividade do Mercado Pago um botão ‘Preciso de Ajuda’
                        onde você pode entrar em contato com um atendente do Mercado Pago
                        e verificar o motivo da recusa.
                    </li>
                    <li>Os juros do parcelamento são por conta do comprador.</li>
                    <li>
                        Você receberá seu dinheiro em no máximo 30 dias independente da
                        quantidade de parcelas que o comprador escolher.
                    </li>
                    <li>
                        Você pode configurar o parcelamento sem juros na plataforma do
                        Mercado Pago e assumir os juros do comprador. Para isso abra o
                        Mercado Pago, Vá em Seu Negócio, clique em Custos e ative a Venda
                        com parcelamento sem juros para pagamentos online.
                    </li>
                </ul>
                <p>
                    O último mas não menos importante, o pagamento com Link de Pagamento:
                </p>
                <h3>Link de Pagamento</h3>
                <p>
                    O link de pagamento pode ser o mais versátil das formas de pagamento
                    apresentadas até aqui.{" "}
                </p>
                <p>
                    Ele permite o cliente optar por qualquer uma das formas de pagamento e
                    ainda adiciona:
                </p>
                <ul>
                    <li>Pagamento com conta do Mercado Pago.</li>
                    <li>Pagamento com app do Mercado Pago.</li>
                    <li>Cartão de débito virtual Caixa</li>
                    <li>PayPal</li>
                    <li>Pagamento na lotérica ( a depender do valor da venda )</li>
                </ul>

                <p>
                    Para fazer o teste é só seguir os mesmos passos anteriores e
                    selecionar Link de Pagamento na forma de pagamento.
                </p>
                <p>
                    O sistema irá gerar o pedido e apresentar uma janela para o cliente
                    gerar o link de pagamento. Clique no botão Gerar Link de Pagamento.
                    Você pode copiar o link e colar no seu navegador preferido ou clicar
                    no botão Pagar Agora que o redirecionará para o site do Mercado Pago
                    para finalização do pedido.
                </p>
                <h3>É isso!</h3>
                <p>
                    Com isso encerramos os testes de validação da configuração do Mercado
                    Pago. Se tudo deu certo, parabéns, sua loja está pronta para vender na
                    Internet. Não se esqueça que estamos sempre disponíveis através do
                    nosso suporte técnico.
                </p>
                <h3>Boas Vendas!</h3>
            </div>
        </div>
    );
}
export default SuportCredentials;
