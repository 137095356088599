import React from "react";

function Subscriptions(props) {
    return (
        <div class="privacy">
            <h2>Siga as intruções para cancelar sua assinatura</h2>
            <h3>Para dispositivos Android:</h3>
            <p>1) Feche o Carty e abra o aplicativo da PlayStore.</p>
            <p>2) Clique no seu perfil. Ele está localizado no canto superior direito.</p>
            <p>3) Clique em Pagamentos e Assinaturas.</p>
            <p>4) Localize e clique no Carty.</p>
            <p>5) Clique em Cancelar Assinatura</p>
            <br />

            <h3>Para dispositivos IOS(IPhone):</h3>
            <p>1) Feche o Carty e abra o aplicativo AppStore.</p>
            <p>2) Clique no seu perfil. Ele está localizado no canto superior direito.</p>
            <p>3) Clique em Assinaturas.</p>
            <p>4) Localize e clique no Carty.</p>
            <p>5) Clique em Cancelar Assinatura</p>
            <br />
        </div>
    );
}
export default Subscriptions;
