export const getVisibleProducts = (data, { category }, { searchTerm }, minimalist) => {
    let filtered = [...data.products];
    //console.log(filtered);
    let featured = filtered.filter((p) => {
        return p.featured === true;
    });

    let normal = filtered.filter((p) => {
        return p.featured !== true;
    });
    filtered = normal;

    filtered = filtered.map((parent) => {
        if (parent.variations && parent.variations.length > 0) {
            let firstActiveVariation = parent.variations.filter((item) => {
                return item.status;
            })[0];
            if (firstActiveVariation) {
                return {
                    ...parent,
                    price: firstActiveVariation.price,
                    promoPrice: firstActiveVariation.promoPrice,
                    description: firstActiveVariation.description,
                    name: firstActiveVariation.name,
                };
            } else {
                return parent;
            }
        } else {
            return parent;
        }
    });

    if (featured && featured.length > 0) filtered.unshift(featured[0]);

    if (category && category !== "Todos") {
        filtered = filtered.filter((product) => {
            return (
                product.category &&
                product.category.length > 0 &&
                product.category.includes(category)
            );
        });
    }
    if (searchTerm && searchTerm !== "") {
        let found = filtered.filter((product) => {
            return (
                (minimalist && product.featured === true) ||
                product.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            );
        });
        if (found && found.length === 0 && minimalist) {
            filtered = [filtered[0]];
        } else {
            filtered = found;
        }
    }
    return filtered;
};

export const getCartTotal = (cartItems, shipping, coupon, installment) => {
    var total = 0;

    //subtotal
    for (var i = 0; i < cartItems.length; i++) {
        total +=
            parseFloat(cartItems[i].qty) *
            parseFloat(cartItems[i].promoPrice || cartItems[i].price || 0);
    }

    if (coupon && coupon.amount && coupon.amount > 0) {
        let couponAmount = total * (coupon.amount / 100);
        total = total - couponAmount;
    }

    if (shipping && shipping.total > 0) {
        total = parseFloat(shipping.total + total).toFixed(2);
    }
    total = parseFloat(total).toFixed(2);
    /*
    if (installment && installment.installments > 1) {
        let rate = total * (installment.installment_rate / 100);
        total = total + rate;
    }
*/
    return total;
};
export const getCouponAmount = (cartItems, coupon) => {
    var total = 0;

    //subtotal
    for (var i = 0; i < cartItems.length; i++) {
        total +=
            parseFloat(cartItems[i].qty) *
            parseFloat(cartItems[i].promoPrice || cartItems[i].price);
    }

    if (coupon && coupon.amount && coupon.amount > 0) {
        let couponAmount = total * (coupon.amount / 100);
        return couponAmount;
    }

    return null;
};
