import React, { useEffect } from "react";
import { connect } from "react-redux";
import store from "../store";
import { getBusiness } from "../actions";

function QuotaExceededPage(props) {
    useEffect(() => {
        async function init() {
            let business = JSON.parse(localStorage.getItem("currentBusiness"));
            store.dispatch(getBusiness(business));
            const favicon = document.getElementById("favicon");
            favicon.href = business.logo;
            document.title = business.name;
        }

        init();
    }, []);
    return (
        <div
            style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "100vh",
            }}
        >
            <img
                style={{ width: 50, height: 50, marginTop: 50 }}
                src={props.business.logo}
                alt="logo"
            />
            <h3>{props.business.name}</h3>
            <h4>
                Essa loja foi temporariamente desativada por violar os termos de uso da
                plataforma.
            </h4>
            <h5>
                Para entrar em contato com o proprietário da loja clique nos botões
                abaixo:
            </h5>

            <div className="social-media-dark">
                <div>
                    <a
                        href={`https://api.whatsapp.com/send?phone=55${props.business.whatsappNumber}`}
                        style={{ textDecoration: "none" }}
                    >
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "row",
                                borderColor: "black",
                                border: "solid 1px black",
                                borderRadius: 5,
                                color: "black",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: 10,
                            }}
                        >
                            <div className="icon">
                                <i class="fa fa-whatsapp"></i>
                            </div>
                            <div style={{ padding: 5 }}>WhatsApp</div>
                        </div>
                    </a>
                    {props.business && props.business.instagram && (
                        <a
                            href={"instagram://user?username=" + props.business.instagram}
                            style={{ textDecoration: "none" }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    borderColor: "black",
                                    border: "solid 1px black",
                                    borderRadius: 5,
                                    color: "black",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: 10,
                                }}
                            >
                                <div className="icon">
                                    <i class="fa fa-instagram"></i>
                                </div>
                                <div style={{ padding: 5 }}>Instagram</div>
                            </div>
                        </a>
                    )}
                    {props.business && props.business.facebook && (
                        <a
                            href={"https://www.facebook.com/" + props.business.facebook}
                            style={{ textDecoration: "none" }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "row",
                                    borderColor: "black",
                                    border: "solid 1px black",
                                    borderRadius: 5,
                                    color: "black",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: 10,
                                }}
                            >
                                <div className="icon">
                                    <i class="fa fa-facebook"></i>
                                </div>
                                <div style={{ padding: 5 }}>Facebook</div>
                            </div>
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
});

export default connect(mapStateToProps, {})(QuotaExceededPage);
