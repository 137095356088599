import React, { useEffect, useState } from "react";

function ColorsAndSizes(props) {
    const [colors, setColors] = useState();
    const [sizes, setSizes] = useState();
    const [selectedColor, setSelectedColor] = useState();
    const [selectedSize, setSelectedSize] = useState();
    //const [quantity, setQuantity] = useState();

    useEffect(() => {
        setColors(props.product.colors || []);
        setSizes(props.product.sizes || []);
        setSelectedColor(props.product.colors?.[0]);
        setSelectedSize(props.product.sizes?.[0]);
    }, [props.product]);

    useEffect(() => {
        console.log(selectedColor, selectedSize);
        props.callback(selectedColor, selectedSize);
        /*
        setQuantity(
            props.product.stock?.filter((s) => {
                if (selectedColor && selectedSize)
                    return s.color === selectedColor && s.size === selectedSize;
                if (selectedColor) {
                    return s.color === selectedColor;
                }
                if (selectedSize) {
                    return s.size === selectedSize;
                }
                return true;
            })[0]?.quantity
        );
        */
    }, [selectedColor, selectedSize]); // eslint-disable-line react-hooks/exhaustive-deps

    function renderColor(item) {
        let quantity = 1;
        if (colors?.length === 0 && sizes?.length === 0 && props.hasStock) {
            quantity = props.product.stock?.filter((s) => s.color === item)[0]?.quantity;
        }
        console.log("renderizando a cor ", quantity);
        if (quantity === 0)
            return (
                <div
                    class={"box-disabled"}
                    onClick={() => {
                        setSelectedColor(item);
                    }}
                >
                    {item}
                </div>
            );
        else
            return (
                <div
                    class={selectedColor === item ? "box-selected" : "box"}
                    onClick={() => {
                        setSelectedColor(item);
                    }}
                >
                    {item}
                </div>
            );
    }

    function renderSize(item) {
        let quantity = 1;
        if (colors?.length === 0 && props.hasStock) {
            quantity = props.product.stock?.filter((s) => s.size === item)[0]?.quantity;
        } else if (props.hasStock) {
            quantity = props.product.stock?.filter(
                (s) => s.color === selectedColor && s.size === item
            )[0]?.quantity;
        }

        if (quantity === 0)
            return (
                <div
                    class={"box-disabled"}
                    onClick={() => {
                        setSelectedSize(item);
                    }}
                >
                    {item}
                </div>
            );
        else
            return (
                <div
                    class={selectedSize === item ? "box-selected" : "box"}
                    onClick={() => {
                        setSelectedSize(item);
                    }}
                >
                    {item}
                </div>
            );
    }

    return (
        <div class="product-variations">
            {colors?.length > 0 && <div class="title-color-size">Cor</div>}
            <div class={"list-box"}>
                {colors &&
                    colors.map((item) => {
                        return renderColor(item);
                    })}
            </div>
            {sizes?.length > 0 && (
                <>
                    {sizes && sizes.length > 0 && (
                        <div class="title-color-size">Tamanho</div>
                    )}
                    <div class={"list-box"}>
                        {sizes &&
                            sizes.map((item) => {
                                return renderSize(item);
                            })}
                    </div>
                </>
            )}
        </div>
    );
}
export default ColorsAndSizes;
