import React from "react";

export const WhatsApp = (props) => {
  function mtel(v) {
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/g, "$1 $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
    return v.substr(0, 15);
  }
  return (
    <>
      <a
        href={`https://api.whatsapp.com/send?phone=55${props.whatsappNumber}`}
        class="wrap"
      >
        <div className="blank-minimalist-container">
          <i className="fa fa-whatsapp"></i>
          <div class="item">
            <div>
              <p>
                <span>Whatsapp</span>:{" "}
                {props.whatsappNumber ? mtel(props.whatsappNumber) : undefined}
              </p>
              <p>Precisando de ajuda?</p>
              <p>É só falar com a gente</p>
            </div>
          </div>
        </div>
      </a>
    </>
  );
};
