import React from "react";
import "./styles_promo.css";
import Countdown from "react-countdown";

function PromoPage(props) {
    //var end = new Date();
    //end.setHours(23, 59, 59, 999);

    var end = new Date("November 24, 2022 00:00:00");

    return (
        <div id="promo-wrap">
            <div className="promo-header">
                <div className="title-black">BLACK FRIDAY</div>{" "}
                <div className="subtitle-black">DIA 23/11/22</div>
                <div className="countdown">
                    <Countdown date={end.toUTCString()} />
                </div>
            </div>

            <div className="promo-content">
                <div className="off-black">40% OFF</div>
                <div className="promo-black">
                    <div>Plano Premium por 12 meses - De R$ 240 por R$ 144</div>
                </div>
                <div>
                    <div className="buy">ASSINAR PREMIUM</div>
                </div>

                <div className="off-black">50% OFF</div>
                <div className="promo-black">
                    <div>Plano PRO por 12 meses - De R$ 480 por R$ 240</div>
                </div>
                <div>
                    <div className="buy">ASSINAR PRO</div>
                </div>

                <div className="off-black">60% OFF</div>
                <div className="promo-black">
                    <div>Plano Diamante por 12 meses - De R$ 720 por R$ 288</div>
                </div>
                <div>
                    <div className="buy">ASSINAR DIAMANTE</div>
                </div>

                <div style={{ padding: 10 }}>
                    <link
                        rel="stylesheet"
                        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
                    ></link>
                    <a
                        href={"https://api.whatsapp.com/send?phone=5561993901315"}
                        id="whatsapp"
                        class="float"
                    >
                        <i class="fa fa-whatsapp my-float"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}
export default PromoPage;
