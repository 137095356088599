import { create } from "apisauce";

function getServer() {
    if (localStorage.getItem("currentBusiness") !== null) {
        let business = JSON.parse(localStorage.getItem("currentBusiness"));
        if (
            business.activePlanName === "Diamante" ||
            business.activePlanName === "Pro" ||
            business.activePlanName === "Premium"
        ) {
            return "https://carty-private.herokuapp.com";
        } else {
            return "https://yeswedeliver-backend.herokuapp.com";
        }
    } else {
        return "https://yeswedeliver-backend.herokuapp.com";
    }
}
const api = create({
    baseURL: getServer(),
});

api.addAsyncRequestTransform((request) => async () => {
    const token = await localStorage.getItem("@ywd:token");
    if (token) {
        request.headers["Authorization"] = `Bearer ${token}`;
    }
});
api.addResponseTransform((response) => {
    if (!response.ok) {
        throw response;
    }
});

export default api;
