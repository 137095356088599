import React from "react";

import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";

import store from "./store";

//import { clearState } from "../src/actions";
import { CoolLayout } from "./CoolLayout";
import { FoodLayout } from "./FoodLayout";
import { MinimalistLayout } from "./MinimalistLayout";
import { DarkLayout } from "./DarkLayout";
import { FendiLayout } from "./FendiLayout";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import HotSite from "./main/RedirectPage";
import Terms from "./main/TermsPage";
import SupportCredentials from "./main/SupportCredentials";
import SupportME from "./main/SupportME";
import Privacy from "./main/PrivacyPage";
import DataProtection from "./main/DataProtection";

import Retails from "./main/Retails";
import PromoPage from "./main/PromoPage";
import PixPaymentPage from "./main/PixPaymentPage";
import PaymentPage from "./main/PaymentPage";

import AnualPayment from "./main/AnualPayment";
import Subscriptions from "./main/Subscriptions";

import { loadBusiness, loadBusinessCustomDomain } from "./api/products";
import ScrollMemory from "./components/scroll/ScrollMemory";
import moment from "moment";
import { QuotaExceededLayout } from "./QuotaExceededLayout";
import loadMP from "./main/loadMP";
import smoothscroll from "smoothscroll-polyfill";
import SupportInstagramShopping from "./main/SupportInstagramShopping";

const theme = createMuiTheme({
    palette: {
        primary: { main: "rgba(var(--primary-color),1)" },
        secondary: { main: "rgba(var(--primary-color),1)" },
    },
});

class Root extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isBloqued: false,
            isQuotaExceeded: false,
            isMinimalistLayout: false,
            isFoodLayout: false,
            isCoolLayout: false,
            isDarkLayout: false,
            isFendiLayout: false,
            redirect: false,
            loaded: false,
        };
    }

    async componentWillMount() {
        let isMinimalistLayout = false;
        let isFoodLayout = false;
        let isCoolLayout = false;
        let isDarkLayout = false;
        let isFendiLayout = false;
        let isQuotaExceeded = false;
        let isBloqued = false;
        let redirect = false;

        async function init() {
            smoothscroll.polyfill();
            let host = window.location.host;
            let search = window.location.search;
            let subdomain = "";
            localStorage.setItem("loaded", "false");
            if (host.indexOf(".carty") === -1 && host.indexOf("localhost") === -1) {
                let business = await loadBusinessCustomDomain(host);
                if (business) {
                    if (
                        business.activePlanName &&
                        (business.activePlanName === "Pro" ||
                            business.activePlanName === "Diamante") &&
                        business.features.includes("onsite-checkout-mp")
                    ) {
                        loadMP(() => {
                            //console.log("carregou mp no index");
                        });
                    }

                    localStorage.setItem("currentBusiness", JSON.stringify(business));
                    isFoodLayout =
                        business.layout === "food" ||
                        business.layout === undefined ||
                        business.layout === null;
                    isMinimalistLayout = business.layout === "minimalist";
                    isDarkLayout = business.layout === "dark";
                    isFendiLayout = business.layout === "fendi";
                    isCoolLayout = business.layout === "cool";
                    localStorage.setItem("newClient", "true");
                }
            } else {
                let parts = host.split(".");
                // If we get more than 4 parts, then we have a subdomain
                if (parts.length >= 1 && parts[0] !== "www") {
                    //call from internet with subdomain(burgerise.carty.com.br)

                    if (search.indexOf("token=") !== -1) {
                        let token = search.substring(
                            search.lastIndexOf("token=") + 6,
                            search.length
                        );
                        let decoded = Buffer.from(token, "base64");
                        //alert(decoded);
                        if (decoded.toString().indexOf("oboxynaotembraco") !== -1) {
                            let parts = decoded.toString().split("|");
                            let tokenDate = moment(parts[1]);
                            let diffInSeconds = (new Date() - tokenDate.toDate()) / 1000;
                            if (diffInSeconds < 15) {
                                localStorage.setItem("newClient", "false");
                            } else {
                                localStorage.setItem("newClient", "true");
                            }
                        } else {
                            localStorage.setItem("newClient", "true");
                        }
                    } else {
                        localStorage.setItem("newClient", "true");
                    }
                    subdomain = parts[0];
                    //console.log(subdomain);
                    let business = await loadBusiness(subdomain);
                    // console.log(business.layout === "minimalist");
                    if (business) {
                        if (
                            business.activePlanName &&
                            (business.activePlanName === "Pro" ||
                                business.activePlanName === "Diamante") &&
                            business.features.includes("onsite-checkout-mp")
                        ) {
                            loadMP(() => {
                                // console.log("carregou mp no index");
                            });
                        }

                        localStorage.setItem("currentBusiness", JSON.stringify(business));
                        if (business.blocked) {
                            isBloqued = true;
                        }

                        /*
                        if (business.accessQuota) {
                            let traffic = await loadTraffic(business._id);
                            let views = traffic.data.reduce((a, b) => a + b, 0);
                            isQuotaExceeded =
                                business.activePlanName !== "Premium" &&
                                views > business.accessQuota;
                        }
                        */

                        if (!isBloqued) {
                            isFoodLayout =
                                business.layout === "food" ||
                                business.layout === undefined ||
                                business.layout === null;
                            isMinimalistLayout = business.layout === "minimalist";
                            isDarkLayout = business.layout === "dark";
                            isFendiLayout = business.layout === "fendi";
                            isCoolLayout = business.layout === "cool";
                        }

                        const bgColor = null; //"255,255,255";
                        let primary = business.primaryColor; //null; //"12, 89, 122";
                        let secondary = null; //"255,255,255";

                        if (primary) {
                            const rgbArray = primary.split(","); // Split the string into an array using comma as the delimiter

                            // Convert the components to numbers
                            const red = parseInt(rgbArray[0]);
                            const green = parseInt(rgbArray[1]);
                            const blue = parseInt(rgbArray[2]);

                            const threshold = 0.7;

                            let y = (red + green + blue) / 255;
                            console.log(y);
                            const result = (y - threshold) * -10000000;
                            console.log(result);
                            if (result >= 0) {
                                document.documentElement.style.setProperty(
                                    "--dynamic-text-color",
                                    "255,255,255"
                                );
                            } else {
                                document.documentElement.style.setProperty(
                                    "--dynamic-text-color",
                                    "0,0,0"
                                );
                            }

                            document.documentElement.style.setProperty(
                                "--primary-color",
                                primary
                            );
                        } else {
                            //set defaults
                            if (isFoodLayout) {
                                primary = "0,0,0";
                                secondary = "0,0,0";
                            } else if (isMinimalistLayout) {
                                primary = "0,0,0";
                                secondary = "255,255,255";
                            } else if (isDarkLayout) {
                                primary = "53,239,224";
                                secondary = "0,0,0";
                            }
                            document.documentElement.style.setProperty(
                                "--primary-color",
                                primary
                            );
                            document.documentElement.style.setProperty(
                                "--dynamic-text-color",
                                secondary
                            );
                        }
                        if (bgColor) {
                            document.documentElement.style.setProperty(
                                "--bg-color",
                                bgColor
                            );
                        }
                        if (secondary) {
                            document.documentElement.style.setProperty(
                                "--dynamic-text-color",
                                secondary
                            );
                        }
                    }
                }
            }
        }

        await init();
        this.setState({ redirect: redirect });
        this.setState({ isMinimalistLayout: isMinimalistLayout });
        this.setState({ isFoodLayout: isFoodLayout });
        this.setState({ isCoolLayout: isCoolLayout });
        this.setState({ isDarkLayout: isDarkLayout });
        this.setState({ isFendiLayout: isFendiLayout });
        this.setState({ isQuotaExceeded: isQuotaExceeded });
        this.setState({ isBloqued: isBloqued });
        this.setState({ loaded: true });
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Provider store={store}>
                    <BrowserRouter basename={"/"}>
                        <ScrollMemory />
                        {this.state.isFoodLayout && <Route component={FoodLayout} />}
                        {this.state.isCoolLayout && <Route component={CoolLayout} />}
                        {this.state.isMinimalistLayout && (
                            <Route component={MinimalistLayout} />
                        )}
                        {this.state.isDarkLayout && <Route component={DarkLayout} />}
                        {this.state.isFendiLayout && <Route component={FendiLayout} />}
                        {this.state.isBloqued && (
                            <Route component={QuotaExceededLayout} />
                        )}
                        {this.state.loaded &&
                            !this.state.isMinimalistLayout &&
                            !this.state.isFoodLayout &&
                            !this.state.isCoolLayout &&
                            !this.state.isDarkLayout &&
                            !this.state.isFendiLayout &&
                            !this.state.isBloqued && (
                                <>
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/`}
                                        component={HotSite}
                                        exact={true}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/retails`}
                                        component={Retails}
                                        exact={true}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/terms`}
                                        component={Terms}
                                        exact={true}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/support/mp`}
                                        component={SupportCredentials}
                                        exact={true}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/support/instagram-shopping`}
                                        component={SupportInstagramShopping}
                                        exact={true}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/support/me`}
                                        component={SupportME}
                                        exact={true}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/privacy`}
                                        component={Privacy}
                                        exact={true}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/yourdata`}
                                        component={DataProtection}
                                        exact={true}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/promo`}
                                        component={PromoPage}
                                        exact={true}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/payment`}
                                        component={PaymentPage}
                                        exact={true}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pix`}
                                        component={PixPaymentPage}
                                        exact={true}
                                    />

                                    <Route
                                        path={`${process.env.PUBLIC_URL}/anualpayment`}
                                        component={AnualPayment}
                                        exact={true}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/subscriptions`}
                                        component={Subscriptions}
                                        exact={true}
                                    />
                                </>
                            )}
                    </BrowserRouter>
                </Provider>
            </MuiThemeProvider>
        );
    }
}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<Root />, rootElement);
} else {
    render(<Root />, rootElement);
}
