import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Currency from "../../components/common/currency";
import { addToCart } from "../../actions";
import { loadBusiness, loadBusinessCustomDomain } from "../../api/products";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import "./styles_f.css";
import store from "../../store";
import { getAllProducts, getBusiness } from "../../actions";
import "pure-react-carousel/dist/react-carousel.es.css";
import ProductVariations from "../ProductVariations";
import { setShipping } from "../../actions";
import ImageGallery from "react-image-gallery";
import ColorsAndSizes from "../ColorsAndSizes";

function ProductPage(props) {
    // eslint-disable-next-line no-undef
    let cli = cloudinary;
    const [open, setOpen] = useState(false);
    const [product, setProduct] = useState();
    const [parent, setParent] = useState();
    const [recomendations, setRecomendations] = useState(false);
    const [fromView, setFromView] = useState();
    const [selectedVariations, setSelectedVariations] = useState();
    const [message, setMessage] = useState();
    const [assets, setAssets] = useState([]);
    const [selectedColor, setSelectedColor] = useState();
    const [selectedSize, setSelectedSize] = useState();

    let history = useHistory();

    function handleClick(message) {
        setMessage(message);
        setOpen(true);
    }

    function showStockQuantity() {
        let show = false;
        if (
            props.business.activePlanName &&
            props.business.activePlanName !== "Gratuito" &&
            props.business.features &&
            props.business.features.includes("stock")
        ) {
            show = true;
            if (parent.variations && parent.variations.length > 0) {
                show = parent.model && parent.model.type !== "list-quantity";
            }
        }

        return show;
    }

    useEffect(() => {
        async function init() {
            window.scrollTo(0, 0);

            let pathname = props.location.pathname;
            //console.log(pathname);
            let productId = pathname.substring(
                pathname.lastIndexOf("/") + 1,
                pathname.length
            );

            if (props.products && props.products.length > 0) {
                let parent = props.products.filter(
                    (product) => product.id === productId
                )[0];
                if (parent.variations && parent.variations.length > 0) {
                    if (parent.model && parent.model.type === "list-quantity") {
                        let firstActiveVariation = parent.variations.filter((item) => {
                            return item.status;
                        })[0];

                        setProduct({
                            ...parent,
                            price: firstActiveVariation.price,
                            promoPrice: firstActiveVariation.promoPrice,
                            description: firstActiveVariation.description,
                            name: firstActiveVariation.name,
                        });
                    } else {
                        let firstActiveVariation = parent.variations.filter((item) => {
                            return item.status;
                        })[0];
                        firstActiveVariation.variationQuantity = 1;
                        setProduct(firstActiveVariation);
                    }
                } else {
                    setProduct(parent);
                }
                setParent(parent);
            } else {
                // url direta
                let host = window.location.host;
                //console.log("no product do food");
                //console.log(host);
                if (host.indexOf(".carty") === -1 && host.indexOf("localhost") === -1) {
                    let business = await loadBusinessCustomDomain(host);
                    //console.log(business);
                    store.dispatch(getBusiness(business));
                    store.dispatch(getAllProducts(business));
                } else {
                    let parts = host.split(".");
                    let uid = parts[0];
                    let business = await loadBusiness(uid);
                    store.dispatch(getBusiness(business));
                    store.dispatch(getAllProducts(business));
                }
            }
            // retiro o produto atual e pego 3 aleatórios para a recomendação
            setRecomendations(
                shuffleArray(
                    props.products.filter((product) => product.id !== productId)
                ).slice(0, 3)
            );
            if (props.location && props.location.state && props.location.state.view) {
                setFromView(props.location.state.view);
            } else {
                setFromView("grid");
            }
        }
        init();
    }, [props.history, props.location, props.products]);

    useEffect(() => {
        function getPublicId(uri) {
            let parts = uri.split("/");
            let folder = parts[parts.length - 2];
            let fileName = parts[parts.length - 1];
            let uid = fileName.split(".")[0];
            return `${folder}/${uid}`;
        }
        function renderGallery() {
            if (product) {
                let assets = product.images.map((image) => {
                    return {
                        publicId: getPublicId(image),
                        transformation: { crop: "fill" },
                        thumbnailTransformation: { crop: "fill" },
                    };
                });
                if (product.video) {
                    let video = {
                        publicId: getPublicId(product.video),
                        transformation: { crop: "fill" },
                        thumbnailTransformation: { crop: "fill" },
                        mediaType: "video",
                    };
                    assets.push(video);
                }

                //console.log(assets);
                setAssets(assets);
            } else {
                //console.log("produto ainda náo carregado");
            }
        }
        if (
            (props.business.activePlanName === "Premium" ||
                props.business.activePlanName === "Pro" ||
                props.business.activePlanName === "Diamante") &&
            product &&
            product.video
        ) {
            renderGallery();
        } else {
            setAssets([]);
        }
    }, [product, props.business.activePlanName]);
    function shuffleArray(a) {
        const array = [...a];
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    useEffect(() => {
        let myGallery;
        try {
            if (assets && assets.length > 0) {
                //console.log(assets);

                myGallery = cli.galleryWidget({
                    container: "#my-gallery",
                    cloudName: "rockedevelopr",
                    videoProps: {
                        controls: "none",
                        sound: true,
                    },
                    aspectRatio: "3:4",

                    loaderProps: {
                        style: "circle",
                    },
                    zoomProps: {
                        tipTouchText: "Toque para Zoom",
                    },
                    mediaAssets: assets,
                    thumbnailProps: {
                        width: 64,
                        height: 84,
                    },
                });
                //console.log("vou renderizar o componente");
                myGallery.render();
                //console.log("renderizei");
            } else {
                console.log("assets null");
            }
        } catch (error) {
            console.log("no error do try");
            console.log(error);
        }
        return () => {
            if (myGallery) {
                myGallery.destroy();
                console.log("destroyed");
            }
        };
    }, [assets, cli]);

    function addToCart(product) {
        let message = "Adicionado ao carrinho";

        if (parent.variations && parent.variations.length > 0) {
            let hasVariations = selectedVariations.filter((v) => v.variationQuantity > 0);
            if (hasVariations.length === 0) {
                handleClick("Selecione o produto.");
            }
            selectedVariations.forEach((variation) => {
                if (variation.variationQuantity > 0) {
                    let cartItens = props.cartItems.filter((item) => {
                        return item._id === variation._id;
                    });
                    let avaialableItems = variation.quantity;
                    if (cartItens && cartItens.length > 0) {
                        avaialableItems = variation.quantity - cartItens[0].qty;
                    }
                    //console.log(avaialableItems);
                    //console.log(variation.variationQuantity);
                    if (showStockQuantity() && avaialableItems === 0) {
                        return;
                    } else {
                        if (
                            showStockQuantity() &&
                            variation.variationQuantity > avaialableItems
                        ) {
                            props.addToCart(variation, avaialableItems);
                            handleClick(message);
                        } else {
                            props.addToCart(variation, variation.variationQuantity);
                            handleClick(message);
                        }
                    }
                }
            });
            if (parent.model.type === "list-quantity") {
                let zeroVariation = parent.variations.map((product) => {
                    return { ...product, variationQuantity: 0 };
                });

                setParent({
                    ...parent,
                    variations: zeroVariation,
                });
            }
        } else {
            product.color = selectedColor;
            product.size = selectedSize;
            product.option = `${selectedColor}|${selectedSize}`;

            let items = props.cartItems.filter((item) => {
                return (
                    item.id === product.id &&
                    item.option === product.option &&
                    product.quantity === item.qty
                );
            });
            if (showStockQuantity() && items && items.length > 0) {
                return;
            } else {
                props.addToCart(product, 1);
                handleClick(message);
            }
        }
        props.setShipping({});
    }

    function handleSelected(selectedColor, selectedSize) {
        console.log("selecionou ", selectedColor, selectedSize);
        setSelectedColor(selectedColor);
        setSelectedSize(selectedSize);
        const stock = product.stock?.filter(
            (p) => p.color === selectedColor && p.size === selectedSize
        )[0];
        console.log(stock);
        console.log(stock?.quantity);
        setProduct({ ...product, quantity: stock?.quantity });
    }

    function changeSelectVariations(itens, selected) {
        setSelectedVariations(itens);
        if (selected) {
            setProduct(
                parent.variations.filter((v) => {
                    return v.variationValue === selected;
                })[0]
            );
        }
    }

    return (
        <>
            {product ? (
                <div id="product-wrap">
                    {(props.business.activePlanName === "Premium" ||
                        props.business.activePlanName === "Pro" ||
                        props.business.activePlanName === "Diamante") &&
                    product &&
                    product.video ? (
                        <div className="product-picture">
                            <div id="my-gallery"></div>
                        </div>
                    ) : product.images && product.images.length > 1 ? (
                        <div className="product-picture">
                            <>
                                <div style={{ position: "relative" }}>
                                    <ImageGallery
                                        items={product.images.map((p) => {
                                            return {
                                                original: p,
                                                thumbnail: p,
                                            };
                                        })}
                                        showThumbnails={false}
                                        showFullscreenButton={false}
                                        showPlayButton={false}
                                        showBullets={product.images.length > 1}
                                    />
                                </div>
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 18,
                                        left: 21,
                                    }}
                                ></div>
                            </>
                        </div>
                    ) : (
                        <div className="product-picture">
                            <img
                                src={product.images[0] || product.img}
                                style={{ objectFit: "contain", width: "100%" }}
                                alt="imagem principal"
                            />
                        </div>
                    )}
                    <div className="product-description">
                        <h1>{product.name}</h1>
                        <div
                            class="description"
                            dangerouslySetInnerHTML={{
                                __html:
                                    product.description &&
                                    product.description.indexOf("\n") !== -1
                                        ? product.description.split("\n").join("<br/>")
                                        : product.description,
                            }}
                        />
                        {product.promoPrice ? (
                            <>
                                <div style={{ textDecorationLine: "line-through" }}>
                                    <Currency value={product.price} />
                                </div>
                                <div
                                    style={{
                                        fontSize: 31,
                                        color: "rgba(var(--primary-color), 1)",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <Currency value={product.promoPrice} />
                                </div>
                            </>
                        ) : (
                            <h2>
                                <Currency value={product.price} />
                            </h2>
                        )}
                        {showStockQuantity() && (
                            <div
                                style={{
                                    fontSize: 14,
                                }}
                            >
                                {product.quantity > 1
                                    ? "( " + product.quantity + " disponíveis )"
                                    : product.quantity === 1
                                    ? `( Apenas  ${product.quantity} unidade disponível )`
                                    : undefined}
                            </div>
                        )}
                    </div>
                    {product.colors?.length > 0 || product.sizes?.length > 0 ? (
                        <ColorsAndSizes
                            product={parent}
                            callback={handleSelected}
                            hasStock={props.business.features.includes("stock")}
                        />
                    ) : undefined}
                    {parent.variations && parent.variations.length > 0 && (
                        <ProductVariations
                            product={parent}
                            selected={product.variationValue}
                            callback={changeSelectVariations}
                            hasStock={props.business.features.includes("stock")}
                            cartItems={props.cartItems}
                        />
                    )}
                    <div className="buttons">
                        <div className="secondary" onClick={() => history.goBack()}>
                            VOLTAR
                        </div>
                        {product.quantity === 0 &&
                        props.business.features &&
                        props.business.features.includes("stock") ? (
                            <button className="disabled">FORA DE ESTOQUE</button>
                        ) : (
                            <button
                                className="primary"
                                onClick={() => addToCart(product)}
                            >
                                EU QUERO!
                            </button>
                        )}
                    </div>
                    <div className="product-recomendations">
                        <h3>VOCÊ TAMBÉM PODE GOSTAR</h3>
                        <div className="images">
                            {recomendations.map((product) => {
                                return (
                                    <Link
                                        to={{
                                            pathname: `${process.env.PUBLIC_URL}/product/${product.id}`,
                                            state: { view: fromView },
                                        }}
                                    >
                                        <img src={product.img} alt={product.name} />
                                    </Link>
                                );
                            })}
                        </div>
                    </div>

                    <Snackbar
                        open={open}
                        //onClose={handleClose}
                        message={message}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        action={
                            <React.Fragment>
                                <Button
                                    component={Link}
                                    to={`${process.env.PUBLIC_URL}/cart`}
                                    size="small"
                                    color="inherit"
                                >
                                    VER CARRINHO
                                </Button>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    component={Link}
                                    to={`${process.env.PUBLIC_URL}/cart`}
                                >
                                    <div className="badge-food">
                                        <Badge
                                            color="secondary"
                                            badgeContent={props.cartItems.reduce(
                                                (a, { qty }) => a + qty,
                                                0
                                            )}
                                            showZero
                                        >
                                            <ShoppingCartIcon />
                                        </Badge>
                                    </div>
                                </IconButton>
                            </React.Fragment>
                        }
                    />
                </div>
            ) : undefined}
        </>
    );
}
const mapStateToProps = (state) => ({
    business: state.business.current,
    products: state.data.products,
    cartItems: state.cartList.cart,
    //product: getSelected(state.data, state.filters),
});

export default connect(mapStateToProps, { addToCart, setShipping })(ProductPage);
