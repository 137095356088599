import { createStore, applyMiddleware, compose } from "redux";

// middlewares
import thunkMiddleware from "redux-thunk";

// Import custom components
import rootReducer from "../reducers";

//import logger from "redux-logger";

/**
 * Create a Redux store that holds the app state.
 */

const store = createStore(
  rootReducer,
  //compose(compose(applyMiddleware(thunkMiddleware), applyMiddleware(logger)))
  compose(compose(applyMiddleware(thunkMiddleware)))
);

export default store;
