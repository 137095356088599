import React from "react";
import "./styles_support.css";

function SupportInstagramShopping(props) {
    return (
        <div id="support-wrap">
            <div className="support-content">
                <h2>Como realizar a integração com o Melhor Envio </h2>
                <h3>Pré-requisitos</h3>
                <ul>
                    <li>Possuir uma conta no Melhor Envio.</li>
                    <li>Ser assinante do Plano Diamante.</li>
                </ul>
                <h4>Passo a passo</h4>
                <p>
                    Para realizar a integração do Carty com o Melhor Envio, é necessário
                    criar um Token na sua conta do Melhor Envio.
                </p>
                <p>
                    Para isso, utilizando um computador, acesse sua conta do Melhor Envio.
                    Caso você ainda não possua uma conta na plataforma você pode criar uma
                    agora clicando no link abaixo
                </p>
                <p>
                    Link:{" "}
                    <a
                        href="https://melhorenvio.com.br/p/GXA9bYEH1W"
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target={"_blank"}
                    >
                        Crie usa conta agora no Melhor Envio
                    </a>
                </p>
                <p>No Menu lateral acesse Gerenciar - Tokens</p>
                <img
                    style={{ width: "100%" }}
                    src={`https://res.cloudinary.com/rockedevelopr/image/upload/v1678816682/carty/file-QeqPDFIIZL_re7dcr.png`}
                    alt="menu token"
                />
                <p>Desça até a seção TOKENS e clique no botão NOVO TOKEN</p>

                <img
                    style={{ width: "100%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1678816928/carty/file-WRhb4TzRgg_uklirc.png"
                    alt="novo token"
                />
                <p>
                    Agora basta colocar um nome para identificar o aplicativo que irá
                    utilizar o token.
                </p>
                <p>Digite CARTY no campo nome </p>
                <p>
                    Marque Selecionar Todos ( todos as caixas precisam estar marcadas ){" "}
                </p>
                <p>Clique em Salvar</p>
                <img
                    style={{ width: "100%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1678817347/carty/Screen_Shot_2023-03-14_at_15.07.05_nldwdh.png"
                    alt="nome token"
                />
                <p>
                    Prontinho, o sistema exibirá o seu token de acesso. Esta é a única vez
                    que será mostrado, então cuidado para não perdê-lo!
                </p>
                <p>
                    Dica de segurança: Nunca compartilhe esse token, você pode excluí-lo
                    caso ele seja exposto de qualquer forma.
                </p>
                <img
                    style={{ width: "100%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1678817641/carty/file-fbIrVNRaK2_lxtjd2.png"
                    alt="token"
                />

                <p>Agora basta colar o conteúdo do token gerado no app Carty</p>
                <p>Para isso:</p>
                <ul>
                    <li>Abra o Carty e clique em Minhas Integrações</li>
                    <li>Selecione Melhor Envio</li>
                    <li>Clique em Token</li>
                    <li>
                        Por motivos de segurança o app irá solicitar seu usuário e senha
                    </li>
                    <li>Cole o token no campo especificado</li>
                    <li>Clique em Salvar</li>
                </ul>
                <img
                    style={{ width: "100%" }}
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/c_scale,w_250/v1678818738/carty/Simulator_Screen_Shot_-_iPhone_12_-_2023-03-14_at_15.30.02_smyvki.png"
                    alt="savetoken"
                />

                <p>Você será redirecionado para a tela anterior.</p>
                <p>
                    Agora, será necessário aceitar os termos e condiçoes do Melhor Envio.
                </p>
                <p>Clique em Termos de Uso</p>
                <p>Clique em Eu Concordo.</p>
                <p>Clique em Voltar</p>
                <img
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/c_scale,w_250/v1678818981/carty/Simulator_Screen_Shot_-_iPhone_12_-_2023-03-14_at_15.35.30_f2ocvf.png"
                    alt=""
                />

                <p>
                    Agora basta ativar a integração clicando na chave Desativado/Ativado
                    que sua loja estará integrada com o Melhor Envio.
                </p>
                <img
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/c_scale,w_250/v1678819380/carty/Simulator_Screen_Shot_-_iPhone_12_-_2023-03-14_at_15.42.15_n3jsno.png"
                    alt=""
                />

                <h3>É isso!</h3>
                <p>
                    Com isso encerramos a configuração da integração com o Melhor Envio.
                    Se tudo deu certo, parabéns, sua loja está pronta oferecer os melhores
                    fretes da internet. Não se esqueça que estamos sempre disponíveis
                    através do nosso suporte técnico.
                </p>
                <h3>Boas Vendas!</h3>
            </div>
        </div>
    );
}
export default SupportInstagramShopping;
