const messages = {
    accredited: "Pronto, seu pagamento foi aprovado!",
    pending_contingency:
        "Estamos processando o pagamento. Não se preocupe, em menos de 2 dias úteis informaremos por e-mail se foi creditado.",
    pending_review_manual:
        "Estamos processando seu pagamento. Não se preocupe, em menos de 2 dias úteis informaremos por e-mail se foi creditado ou se necessitamos de mais informação.",
    cc_rejected_bad_filled_card_number: "Revise o número do cartão.",
    cc_rejected_bad_filled_date: "Revise a data de vencimento.",
    cc_rejected_bad_filled_other: "Revise os dados.",
    cc_rejected_bad_filled_security_code: "Revise o código de segurança do cartão.",
    cc_rejected_blacklist: "Não pudemos processar seu pagamento.",
    cc_rejected_call_for_authorize:
        "Entre em contato com a central de atendimento do seu cartão para autorizar o pagamento do valor ao Mercado Pago.",
    cc_rejected_card_disabled:
        "Ligue para a central de atendimento para ativar seu cartão. O telefone está no verso do seu cartão.",
    cc_rejected_card_error: "Não conseguimos processar seu pagamento.",
    cc_rejected_duplicated_payment:
        "Você já efetuou um pagamento com esse valor. Caso precise pagar novamente, utilize outro cartão ou outra forma de pagamento.",
    cc_rejected_high_risk:
        "Seu pagamento foi recusado. Escolha outra forma de pagamento. Recomendamos meios de pagamento em dinheiro.",
    cc_rejected_insufficient_amount:
        "Não conseguimos processar seu pagamento. Limite insuficiente.",
    cc_rejected_invalid_installments:
        "O cartão utilizado não permite o parcelamento selecionado.",
    cc_rejected_max_attempts:
        "Você atingiu o limite de tentativas permitido. Escolha outro cartão ou outra forma de pagamento.",
    cc_rejected_other_reason: "Falha ao processar o pagamento.",
    cc_rejected_card_type_not_allowed:
        "O pagamento foi rejeitado porque o usuário não tem a função crédito habilitada em seu cartão multiplo (débito e crédito).",
    "Invalid card_number_validation": "Revise o número do cartão.",
    "Invalid transaction_amount":
        "Valor da venda abaixo do permitido para a forma de pagamento selecionada.",
    "The customer can't be equal to the collector.":
        "Utilize e-mail e/ou CPF diferente do titular da conta do Mercado Pago.",
    "Unauthorized use of live credentials":
        "Credenciais não autorizadas. Favor entrar em contato com o suporte.",
};

export default function getMPMessage(message) {
    const m = messages[message];
    console.log("MENSAGEM DE ERRO DO MP NÃO ENCONTRADA " + message);
    return m ? m : "Não conseguimos processar seu pagamento. Tente novamente.";
}
