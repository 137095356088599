import React, { useState, useEffect } from "react";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getVisibleProducts } from "../../services";
import { setLimit } from "../../actions";
import useWindowDimensions from "../../components/common/dimensions";
import "./styles_f.css";

function GridView(props) {
    const { width } = useWindowDimensions();

    const [loadedProducts, setLoadedProducts] = useState([]);

    useEffect(() => {
        setLoadedProducts(props.products.slice(0, props.limit));
    }, [props.products, props.limit]);

    function loadMore() {
        props.setLimit(props.limit + 20);
    }

    return (
        <div>
            {props.products.length > 0 ? (
                <GridList spacing={10} cols={3}>
                    {loadedProducts.map((product, index) => (
                        <GridListTile
                            key={index}
                            cols={product.featured ? 3 : 1}
                            rows={product.featured ? 2 : 1}
                        >
                            <Link
                                to={{
                                    pathname: `${process.env.PUBLIC_URL}/product/${product.id}`,
                                    state: { view: "grid" },
                                }}
                            >
                                <img
                                    src={product.img}
                                    alt={product.name}
                                    style={{
                                        opacity:
                                            !product.hasStock &&
                                            props.business.features &&
                                            props.business.features.includes("stock")
                                                ? 0.3
                                                : 1,
                                    }}
                                    className={
                                        width < 480
                                            ? "MuiGridListTile-imgFullWidth"
                                            : "MuiGridListTile-imgFullHeight"
                                    }
                                />
                            </Link>
                            {product.promoAmount === 0 ? (
                                <></>
                            ) : (
                                <div className="product-grid-dark-sale">
                                    {product.promoAmount > 0 && product.promoAmount}
                                    {product.promoAmount > 0 && "% OFF"}
                                </div>
                            )}
                        </GridListTile>
                    ))}
                </GridList>
            ) : undefined}
            {props.limit < props.products.length && (
                <div className="load-more-button" onClick={() => loadMore()}>
                    MAIS PRODUTOS
                </div>
            )}
        </div>
    );
}
const mapStateToProps = (state) => ({
    business: state.business.current,
    products: getVisibleProducts(state.data, state.filters, state.searchTerm),
    selected: state.filters,
    limit: state.limit.current,
});

export default connect(mapStateToProps, { setLimit })(GridView);
