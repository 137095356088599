import React, { useEffect } from "react";
import "./styles_promo.css";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Paper from "@material-ui/core/Paper";
import api from "../api/service";
import Snackbar from "@material-ui/core/Snackbar";
import { Box } from "@material-ui/core";
import DialogPaymentLink from "./DialogPaymentLink";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import getMPMessage from "../components/common/mpMessages";
import Currency from "../components/common/currency";
import moment from "moment-timezone";
import qs from "qs";
import InputMask from "react-input-mask";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginRight: 10,
    },
    card: {
        minWidth: 275,
        paddingLeft: 50,
        paddingRight: 50,

        marginTop: 20,
        marginBottom: 20,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    mt: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    instructionsLabel: {
        marginTop: theme.spacing(1),
        fontSize: 13,
        marginBottom: theme.spacing(1),
    },
    paper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "& > *": {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            margin: theme.spacing(2),
            width: "100%",
            height: theme.spacing(30),
        },
    },
}));

function PaymentPage(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [email, setEmail] = React.useState();
    const [business, setBusiness] = React.useState();
    const [message, setMessage] = React.useState("");
    const [plan, setPlan] = React.useState();
    const [endStep, setEndStep] = React.useState(false);
    const [loading, setLoading] = React.useState();
    const [expiresIn, setExpiresIn] = React.useState();
    let width = window.innerWidth;
    const steps = getSteps();
    const [initPoint, setInitPoint] = React.useState();
    const [orderPlanId, setOrderPlanId] = React.useState();
    const [finished, setFinished] = React.useState();
    const [openPix, setOpenPix] = React.useState(true);
    const [showPayerId, setShowPayerId] = React.useState(false);
    const [payerId, setPayerId] = React.useState("");

    useEffect(() => {
        console.log("pegando o email da query string");
        let params = props.location.search;
        let email = qs.parse(params, { ignoreQueryPrefix: true }).email;
        setEmail(email);
    }, [props.location.search]);

    useEffect(() => {
        let intervalId = false;
        async function checkStatus() {
            try {
                let r = await api.get("orderPlan/" + orderPlanId);
                console.log(r.data.status);
                if (r.data.status === "approved") {
                    setFinished(true);
                    setOpenPix(false);
                    clearInterval(intervalId);
                }
            } catch (error) {
                console.log(error);
            }
        }
        if (endStep) {
            intervalId = setInterval(() => {
                checkStatus();
            }, 1000 * 5); // 10 seconds
        }
        return () => clearInterval(intervalId);
    }, [endStep, orderPlanId]);

    moment.tz.add(
        "America/Sao_Paulo|LMT -03 -02|36.s 30 20|01212121212121212121212121212121212121212121212121212121212121212121212121212121212121212121|-2glwR.w HdKR.w 1cc0 1e10 1bX0 Ezd0 So0 1vA0 Mn0 1BB0 ML0 1BB0 zX0 pTd0 PX0 2ep0 nz0 1C10 zX0 1C10 LX0 1C10 Mn0 H210 Rb0 1tB0 IL0 1Fd0 FX0 1EN0 FX0 1HB0 Lz0 1EN0 Lz0 1C10 IL0 1HB0 Db0 1HB0 On0 1zd0 On0 1zd0 Lz0 1zd0 Rb0 1wN0 Wn0 1tB0 Rb0 1tB0 WL0 1tB0 Rb0 1zd0 On0 1HB0 FX0 1C10 Lz0 1Ip0 HX0 1zd0 On0 1HB0 IL0 1wp0 On0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 Rb0 1zd0 Lz0 1C10 Lz0 1C10 On0 1zd0 On0 1zd0 On0 1zd0 On0 1HB0 FX0|20e6"
    );

    const plans = [
        {
            name: "Premium",
            desc: "Plano Premium Mensal",
            value: 19.9,
            productId: "premium1mes",
            dayValue: 0.66,
        },
        {
            name: "Pro",
            desc: "Plano PRO Mensal",
            value: 39.9,
            productId: "pro1mes",
            dayValue: 1.33,
        },
        {
            name: "Diamante",
            desc: "Plano Diamante Mensal",
            value: 59.9,
            productId: "diamante1mes",
            dayValue: 1.99,
        },
    ];

    function handleClose() {
        setMessage("");
    }

    async function getBusiness() {
        try {
            let result = await api.get(`/business/email/${email}`);
            if (result && result.data && result.data.business) {
                return result.data.business;
            } else {
                setMessage("Usuário não encontrado! Verifique o email digitado.");
                return null;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function callMPCheckoutPro() {
        try {
            setLoading(true);
            console.log(expiresIn);

            let r = await api.post("orderPlan/purchase-plan/checkout-pro", {
                businessId: business._id,
                planName: plan.name,
                planPrice: plan.value,
                productId: plan.productId,
                expiresIn: expiresIn.toDate(),
                planDesc: plan.desc,
                payerId: payerId,
                payerEmail: email,
                payerName: business.name,
            });
            if (r.data.id) {
                setInitPoint(r.data.init_point);
                setOrderPlanId(r.data.orderPlanId);

                setLoading(false);
                return true;
            } else {
                setMessage(getMPMessage(r.data.message));
                setLoading(false);
                return false;
            }

            //setLoading(false);
        } catch (error) {
            console.log(error);
            if (error.status === 400) {
                setMessage(getMPMessage(error.data.message));
            }
            setLoading(false);
            return false;
        }
    }

    async function checkValuesStep() {
        if (activeStep === 0) {
            if (email) {
                const b = await getBusiness(email);

                if (b !== null) {
                    setBusiness(b);
                    if (b.cpf || b.cnpj || payerId) {
                        setShowPayerId(false);
                        setPayerId(b.cpf || b.cnpj || payerId);
                        return true;
                    } else {
                        setShowPayerId(true);
                        return false;
                    }
                }
                return false;
            }
            return false;
        } else if (activeStep === 1) {
            if (!plan) {
                setMessage("Selecione um dos planos disponíveis.");
                return false;
            } else {
                let now = moment();
                if (business.activePlanName !== "Gratuito") {
                    let currentPlan = plans.filter(
                        (p) => p.productId === business.activeProductId
                    )[0];

                    let selectedPlan = plan;

                    if (currentPlan) {
                        //calc current credits

                        let expirationDate = moment(business.activePlanExpiresIn);
                        let diffDays = expirationDate.diff(now, "days") + 1;
                        if (diffDays > 0) {
                            let credit = diffDays * currentPlan.dayValue;
                            let plusDays = credit / selectedPlan.dayValue;

                            setMessage(
                                "Você é assinante do plano " +
                                    business.activePlanName +
                                    ". Seu plano expira em " +
                                    moment(business.activePlanExpiresIn).format(
                                        "DD/MM/YYYY"
                                    ) +
                                    ". Será adicionado    " +
                                    Math.trunc(plusDays) +
                                    " dia(s) ao plano contratado referente a conversão do seu crédito atual para o novo plano."
                            );
                            now.add(30 + plusDays, "days");
                            setExpiresIn(now);
                            return true;
                        } else {
                            now.add(30, "days");
                            setExpiresIn(now);
                            return true;
                        }
                    }
                    now.add(30, "days");
                    setExpiresIn(now);
                    return true;
                } else {
                    console.log(now);
                    now.add(30, "days");
                    setExpiresIn(now);
                    return true;
                }
            }
        } else if (activeStep === 2) {
            if (await callMPCheckoutPro()) {
                setEndStep(true);
            }
        }
    }

    const handleNext = async () => {
        if (await checkValuesStep()) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setEndStep(false);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    function getSteps() {
        return width > 480
            ? ["Identificação da Loja", "Seleção do Plano", "Revisão e Pagamento"]
            : ["Loja", "Plano", "Pagamento"];
    }
    function getStepContent(step) {
        switch (step) {
            case 0:
                return renderStepEmail();
            case 1:
                return renderStepPlan();
            case 2:
                return renderLastStep();
            default:
                return "Unknown step";
        }
    }

    function renderStepEmail() {
        return (
            <div className={classes.paper}>
                <Paper>
                    {showPayerId ? (
                        <>
                            <FormLabel component="legend" className={classes.mt}>
                                Digite o seu CPF
                            </FormLabel>
                            <InputMask
                                mask="999.999.999-99"
                                value={payerId}
                                onChange={(e) => {
                                    setPayerId(e.target.value);
                                }}
                            >
                                {() => (
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        style={{ marginTop: 10, width: "100%" }}
                                        value={payerId}
                                    />
                                )}
                            </InputMask>
                        </>
                    ) : (
                        <>
                            <FormLabel component="legend" className={classes.mt}>
                                Digite seu email de cadastro:
                            </FormLabel>

                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                style={{ marginTop: 10, width: "100%" }}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                value={email}
                            />
                        </>
                    )}
                </Paper>
            </div>
        );
    }

    const handleChange = (event) => {
        setPlan(plans.filter((p) => p.productId === event.target.value)[0]);
    };

    function renderStepPlan() {
        return (
            <div className={classes.paper}>
                <Paper>
                    <FormControl component="fieldset" className={classes.mt}>
                        <FormLabel component="legend" className={classes.mt}>
                            Escolha o plano desejado:
                        </FormLabel>
                        <RadioGroup
                            aria-label="plano"
                            name="plano"
                            value={plan && plan.productId}
                            onChange={handleChange}
                        >
                            {plans.map((p) => {
                                return (
                                    <FormControlLabel
                                        value={p.productId}
                                        control={<Radio />}
                                        label={
                                            <>
                                                <Box component="div" fontSize={15}>
                                                    {p.desc}
                                                </Box>
                                                <Box component="div" fontSize={13}>
                                                    <Currency value={p.value} />
                                                </Box>
                                            </>
                                        }
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </Paper>
            </div>
        );
    }

    function renderLastStep() {
        return endStep && !finished ? (
            <div className={classes.paper}>
                <Paper>
                    {initPoint && (
                        <DialogPaymentLink
                            openModal={true}
                            openPix={openPix}
                            initPoint={initPoint}
                        />
                    )}
                </Paper>
            </div>
        ) : finished ? (
            renderFinished()
        ) : (
            renderReview()
        );
    }

    function renderFinished() {
        return (
            <div className={classes.paper}>
                <Paper>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 20,
                        }}
                    >
                        <div>Compra concluída com sucesso!</div>
                        <div>
                            Feche o app Carty e abra em seguida para ativar seu plano.
                        </div>
                    </div>
                </Paper>
            </div>
        );
    }

    function renderReview() {
        return (
            <>
                {width > 480 ? (
                    <div className="promo-review">
                        <div className={classes.paper}>
                            <Paper>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                    }}
                                >
                                    <div>
                                        <Typography
                                            className={classes.instructions}
                                            style={{ marginTop: 20 }}
                                        >
                                            Email:
                                        </Typography>

                                        <Typography className={classes.instructions}>
                                            Nome:
                                        </Typography>

                                        <Typography className={classes.instructions}>
                                            CPF / CNPJ:
                                        </Typography>

                                        <Typography className={classes.instructions}>
                                            Plano:
                                        </Typography>

                                        <Typography className={classes.instructions}>
                                            Vencimento:
                                        </Typography>

                                        <Typography className={classes.instructions}>
                                            Investimento:
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography className={classes.instructions}>
                                            {email}
                                        </Typography>

                                        <Typography className={classes.instructions}>
                                            {business.name}
                                        </Typography>

                                        <Typography className={classes.instructions}>
                                            {payerId}
                                        </Typography>

                                        <Typography className={classes.instructions}>
                                            {plan.desc}
                                        </Typography>

                                        <Typography className={classes.instructions}>
                                            {moment(expiresIn).format("DD/MM/YYYY")}
                                        </Typography>

                                        <Typography className={classes.instructions}>
                                            <Currency value={plan.value} />
                                        </Typography>
                                    </div>
                                </div>
                            </Paper>
                        </div>
                    </div>
                ) : (
                    <div className="promo-review-cellphone">
                        <div className={classes.paper}>
                            <Paper>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                    }}
                                >
                                    <div>
                                        <Typography
                                            className={classes.instructionsLabel}
                                            style={{ marginTop: 20 }}
                                        >
                                            Email: {email}
                                        </Typography>

                                        <Typography className={classes.instructionsLabel}>
                                            Nome: {business.name}
                                        </Typography>
                                        <Typography className={classes.instructionsLabel}>
                                            CPF/CNPJ: {payerId}
                                        </Typography>
                                        <Typography className={classes.instructionsLabel}>
                                            Plano: {plan.desc}
                                        </Typography>

                                        <Typography className={classes.instructionsLabel}>
                                            Vencimento:
                                            {moment(expiresIn).format("DD/MM/YYYY")}
                                        </Typography>

                                        <Typography className={classes.instructionsLabel}>
                                            Investimento: <Currency value={plan.value} />
                                        </Typography>
                                    </div>
                                </div>
                            </Paper>
                        </div>
                    </div>
                )}
            </>
        );
    }

    return (
        <div id="promo-wrap-payment">
            <div className="promo-header">
                <img
                    className="logo"
                    src="https://res.cloudinary.com/rockedevelopr/image/upload/v1627344463/carty/logo_lvqqnx.png"
                    alt="logo"
                />
            </div>
            <div
                className="promo-content"
                style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div className={classes.root}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};

                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div>
                        {activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Button onClick={handleReset} className={classes.button}>
                                    Reset
                                </Button>
                            </div>
                        ) : (
                            <div>
                                <Typography className={classes.instructions}>
                                    {getStepContent(activeStep)}
                                </Typography>
                                <div>
                                    {!finished && (
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            VOLTAR
                                        </Button>
                                    )}
                                    {!endStep && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            disabled={email === "" || email === undefined}
                                            className={classes.button}
                                        >
                                            PRÓXIMO
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <Snackbar
                    open={message !== ""}
                    //onClose={handleClose}
                    message={message}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    autoHideDuration={3000}
                    onClose={handleClose}
                />
                <Backdrop
                    className={classes.backdrop}
                    open={loading}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </div>
    );
}
export default PaymentPage;
