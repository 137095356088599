const loadMP = (callback) => {
    const existingScript = document.getElementById("mp");
    if (!existingScript) {
        const script = document.createElement("script");
        script.src = "https://sdk.mercadopago.com/js/v2";
        script.id = "mp";
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) callback();
        };
    }
    if (existingScript && callback) callback();
};
export default loadMP;
