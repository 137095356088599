import { SET_SHIPPING } from "../constants/ActionTypes";

const initialState = {
  current: {},
};

const shippingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHIPPING:
      return { ...state, current: action.shipping };
    default:
      return state;
  }
};
export default shippingReducer;
