import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import Currency from "../../components/common/currency";
import { addToCart } from "../../actions";
import { loadBusiness, loadBusinessCustomDomain } from "../../api/products";
import Header from "./Header";
import { Link } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import { ProductCarousel } from "../ProductCarousel";
import store from "../../store";
import { getAllProducts, getBusiness } from "../../actions";
import { CarouselProvider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import useWindowDimensions from "../../components/common/dimensions";
import ProductVariations from "../ProductVariations";
import { setShipping } from "../../actions";

function ProductPage(props) {
    // eslint-disable-next-line no-undef
    let cli = cloudinary;
    const [open, setOpen] = useState(false);
    const { width } = useWindowDimensions();
    const [product, setProduct] = useState();
    const [parent, setParent] = useState();
    const [recomendations, setRecomendations] = useState(false);
    const [selectedVariations, setSelectedVariations] = useState();
    const [message, setMessage] = useState();
    const [assets, setAssets] = useState([]);

    function handleClick(message) {
        setMessage(message);
        setOpen(true);
    }
    function handleClose() {
        setOpen(false);
    }
    function showStockQuantity() {
        let show = false;
        if (
            props.business.activePlanName &&
            props.business.activePlanName !== "Gratuito" &&
            props.business.features &&
            props.business.features.includes("stock")
        ) {
            show = true;
            if (parent.variations && parent.variations.length > 0) {
                show = parent.model && parent.model.type !== "list-quantity";
            }
        }

        return show;
    }
    useEffect(() => {
        async function init() {
            window.scrollTo(0, 0);
            document.body.style = "background: rgb(197, 191, 184);";

            let pathname = props.location.pathname;
            //console.log(pathname);
            let productId = pathname.substring(
                pathname.lastIndexOf("/") + 1,
                pathname.length
            );

            if (props.products && props.products.length > 0) {
                let parent = props.products.filter(
                    (product) => product.id === productId
                )[0];
                if (parent.variations && parent.variations.length > 0) {
                    if (parent.model && parent.model.type === "list-quantity") {
                        setProduct(parent);
                    } else {
                        let firstActiveVariation = parent.variations.filter((item) => {
                            return item.status;
                        })[0];
                        firstActiveVariation.variationQuantity = 1;
                        setProduct(firstActiveVariation);
                    }
                } else {
                    setProduct(parent);
                }
                setParent(parent);
            } else {
                // url direta
                let host = window.location.host;

                if (host.indexOf(".carty") === -1 && host.indexOf("localhost") === -1) {
                    let business = await loadBusinessCustomDomain(host);
                    store.dispatch(getBusiness(business));
                    store.dispatch(getAllProducts(business));
                } else {
                    let parts = host.split(".");
                    let uid = parts[0];
                    let business = await loadBusiness(uid);
                    store.dispatch(getBusiness(business));
                    store.dispatch(getAllProducts(business));
                }
            }
            // retiro o produto atual e pego 3 aleatórios para a recomendação
            setRecomendations(
                shuffleArray(
                    props.products.filter((product) => product.id !== productId)
                ).slice(0, 3)
            );
        }
        init();
    }, [props.history, props.location, props.products]);

    useEffect(() => {
        function getPublicId(uri) {
            let parts = uri.split("/");
            let folder = parts[parts.length - 2];
            let fileName = parts[parts.length - 1];
            let uid = fileName.split(".")[0];
            return `${folder}/${uid}`;
        }
        function renderGallery() {
            if (product) {
                let assets = product.images.map((image) => {
                    return {
                        publicId: getPublicId(image),
                        transformation: { crop: "fill" },
                        thumbnailTransformation: { crop: "fill" },
                    };
                });
                if (product.video) {
                    let video = {
                        publicId: getPublicId(product.video),
                        transformation: { crop: "fill" },
                        thumbnailTransformation: { crop: "fill" },
                        mediaType: "video",
                    };
                    assets.push(video);
                }

                //console.log(assets);
                setAssets(assets);
            } else {
                //console.log("produto ainda náo carregado");
            }
        }
        if (props.business.activePlanName === "Premium") {
            renderGallery();
        } else {
            setAssets([]);
        }
    }, [product, props.business.activePlanName]);

    useEffect(() => {
        let myGallery;
        try {
            if (assets && assets.length > 0) {
                //console.log(assets);

                myGallery = cli.galleryWidget({
                    container: "#my-gallery",
                    cloudName: "rockedevelopr",
                    videoProps: {
                        controls: "none",
                        sound: true,
                    },
                    aspectRatio: "3:4",
                    playerType: "cloudinary",
                    loaderProps: {
                        style: "circle",
                    },
                    zoomProps: {
                        tipTouchText: "Toque para Zoom",
                    },
                    mediaAssets: assets,
                    thumbnailProps: {
                        width: 64,
                        height: 84,
                    },
                });
                //console.log("vou renderizar o componente");
                myGallery.render();
                //console.log("renderizei");
            } else {
                console.log("assets null");
            }
        } catch (error) {
            console.log("no error do try");
            console.log(error);
        }
        return () => {
            if (myGallery) {
                myGallery.destroy();
                console.log("destroyed");
            }
        };
    }, [assets, cli]);

    function shuffleArray(a) {
        const array = [...a];
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    function addToCart(product) {
        let message = "Adicionado ao carrinho";

        if (parent.variations && parent.variations.length > 0) {
            let hasVariations = selectedVariations.filter((v) => v.variationQuantity > 0);
            if (hasVariations.length === 0) {
                handleClick("Selecione o produto.");
            }
            selectedVariations.forEach((variation) => {
                if (variation.variationQuantity > 0) {
                    let cartItens = props.cartItems.filter((item) => {
                        return item._id === variation._id;
                    });
                    let avaialableItems = variation.quantity;
                    if (cartItens && cartItens.length > 0) {
                        avaialableItems = variation.quantity - cartItens[0].qty;
                    }
                    //console.log(avaialableItems);
                    //console.log(variation.variationQuantity);
                    if (showStockQuantity() && avaialableItems === 0) {
                        return;
                    } else {
                        if (
                            showStockQuantity() &&
                            variation.variationQuantity > avaialableItems
                        ) {
                            props.addToCart(variation, avaialableItems);
                            handleClick(message);
                        } else {
                            props.addToCart(variation, variation.variationQuantity);
                            handleClick(message);
                        }
                    }
                }
            });
            if (parent.model.type === "list-quantity") {
                let zeroVariation = parent.variations.map((product) => {
                    return { ...product, variationQuantity: 0 };
                });

                setParent({
                    ...parent,
                    variations: zeroVariation,
                });
            }
        } else {
            let items = props.cartItems.filter((item) => {
                return item.id === product.id && product.quantity === item.qty;
            });
            if (showStockQuantity() && items && items.length > 0) {
                return;
            } else {
                props.addToCart(product, 1);
                handleClick(message);
            }
        }
        props.setShipping({});
    }

    function changeSelectVariations(itens, selected) {
        setSelectedVariations(itens);
        if (selected) {
            setProduct(
                parent.variations.filter((v) => {
                    return v.variationValue === selected;
                })[0]
            );
        }
    }

    return (
        <>
            {product ? (
                <div id="product-fendi-wrap">
                    <div className="header-fendi">
                        <Header back={true} />
                    </div>
                    {props.business.activePlanName === "Premium" ? (
                        <div
                            className="product-fendi-picture"
                            style={{
                                marginTop: 70,
                            }}
                        >
                            <div id="my-gallery"></div>
                        </div>
                    ) : product.images && product.images.length > 1 ? (
                        <div
                            className="product-fendi-picture"
                            style={{
                                marginTop: 70,
                            }}
                        >
                            <CarouselProvider
                                naturalSlideWidth={100}
                                naturalSlideHeight={125}
                                totalSlides={product.images.length}
                                key={product.id}
                            >
                                <ProductCarousel
                                    images={product.images}
                                    key={product.id}
                                />
                            </CarouselProvider>
                        </div>
                    ) : (
                        <div
                            className="product-fendi-picture"
                            style={{
                                marginTop: 70,
                                backgroundImage: `url(${
                                    product.images[0] || product.img
                                })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                height: width < 480 ? width * 1.33 : 600,
                            }}
                        ></div>
                    )}
                    <div className="product-fendi-description">
                        <div className="product-fendi-name">{product.name}</div>

                        {product.promoPrice ? (
                            <div className="product-fendi-container-price">
                                <div className="product-fendi-price-line">
                                    <Currency value={product.price} />
                                </div>
                                <div className="product-fendi-promoprice">
                                    <Currency value={product.promoPrice} />
                                </div>
                            </div>
                        ) : (
                            <div className="product-fendi-price">
                                <Currency value={product.price} />
                            </div>
                        )}
                        <div
                            class="product-fendi-details"
                            dangerouslySetInnerHTML={{
                                __html:
                                    product.description &&
                                    product.description.indexOf("\n") !== -1
                                        ? product.description.split("\n").join("<br/>")
                                        : product.description,
                            }}
                        />
                        {showStockQuantity() && (
                            <div
                                className="product-fendi-stock"
                                style={{
                                    fontSize: 14,
                                }}
                            >
                                {product.quantity > 1
                                    ? "( " + product.quantity + " disponíveis )"
                                    : product.quantity === 1
                                    ? `( Apenas  ${product.quantity} unidade disponível )`
                                    : undefined}
                            </div>
                        )}
                    </div>

                    {parent.variations && parent.variations.length > 0 && (
                        <ProductVariations
                            product={parent}
                            selected={product.variationValue}
                            callback={changeSelectVariations}
                            hasStock={props.business.features.includes("stock")}
                            cartItems={props.cartItems}
                        />
                    )}

                    <div className="product-fendi-button">
                        {product.quantity === 0 &&
                        props.business.features &&
                        props.business.features.includes("stock") ? (
                            <div className="out-of-stock">
                                <div>FORA DE ESTOQUE</div>
                            </div>
                        ) : (
                            <div className="in-stock" onClick={() => addToCart(product)}>
                                ADICIONAR À SACOLA
                            </div>
                        )}
                    </div>

                    <div className="product-fendi-recomendations">
                        <h3>VOCÊ TAMBÉM PODE GOSTAR</h3>
                        <div className="images">
                            {recomendations.map((product) => {
                                return (
                                    <Link
                                        to={{
                                            pathname: `${process.env.PUBLIC_URL}/product/${product.id}`,
                                        }}
                                    >
                                        <img src={product.img} alt={product.name} />
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                    <Snackbar
                        open={open}
                        //onClose={handleClose}
                        message={message}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        autoHideDuration={3000}
                        onClose={handleClose}
                    />
                </div>
            ) : undefined}
        </>
    );
}
const mapStateToProps = (state) => ({
    business: state.business.current,
    products: state.data.products,
    cartItems: state.cartList.cart,
    //product: getSelected(state.data, state.filters),
});

export default connect(mapStateToProps, { addToCart, setShipping })(ProductPage);
