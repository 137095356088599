import { SET_SEARCH_TERM } from "../constants/ActionTypes";

const initialState = {
    searchTerm: "",
};

const searchTermReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SEARCH_TERM:
            return { ...state, searchTerm: action.searchTerm };
        default:
            return state;
    }
};
export default searchTermReducer;
