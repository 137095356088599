import React, { useState } from "react";
import { connect } from "react-redux";
import { WhatsappShareButton } from "react-share";
import LinkIcon from "@material-ui/icons/Link";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbar from "@material-ui/core/Snackbar";

function Share(props) {
    const [message, setMessage] = useState("");
    const handleClose = () => {
        setMessage("");
    };
    return (
        <div className="product-share">
            <h3 className="title">COMPARTILHAR PRODUTO</h3>
            <div className="share-buttons">
                <div className="share-button">
                    <WhatsappShareButton
                        url={props.shareUrl}
                        title={props.title}
                        separator=":: "
                    >
                        <div className="whatsapp-icon">
                            <i className="fa fa-whatsapp"></i>
                        </div>
                    </WhatsappShareButton>
                    <div className="button-title">WhatsApp</div>
                </div>
                <div className="share-button">
                    <CopyToClipboard
                        text={props.shareUrl}
                        onCopy={() => setMessage("Link copiado com sucesso!")}
                    >
                        <LinkIcon className="copy-link" />
                    </CopyToClipboard>

                    <div className="button-title">Copiar Link</div>
                </div>
            </div>
            <Snackbar
                open={message !== ""}
                //onClose={handleClose}
                message={message}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                autoHideDuration={3000}
                onClose={handleClose}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
});

export default connect(mapStateToProps, {})(Share);
