import React, { useState, useEffect } from "react";

import "./styles_review.css";
import api from "../api/service";
import Currency from "../components/common/currency";

import moment from "moment";

function CheckOrderPage(props) {
    const [order, setOrder] = useState();

    useEffect(() => {
        async function init() {
            async function loadOrder() {
                let orderId = props.match.params.orderId;
                console.log(orderId);
                const res = await api.get(`/order/${orderId}`);
                console.log(res);
                setOrder(res.data.order);
            }
            loadOrder();
        }
        init();
    }, [props.match.params.orderId]);
    function getCouponAmount() {
        if (order.coupon && order.coupon.amount > 0) {
            let total =
                (order.subTotal || order.orderTotal) * (order.coupon.amount / 100);
            return total;
        }
    }
    function isFreeShipping() {
        return (
            order.business.freeShipping &&
            order.business.freeShipping > 0 &&
            order.orderTotal > order.business.freeShipping
        );
    }

    function getShipping() {
        if (order.delivery === "shipping") {
            return `DADOS DO ENVIO:\n${order.shippingMethod} - ${order.shippingDays}\n\n`;
        } else {
            return "";
        }
    }
    function getStatusDescription(status) {
        switch (status) {
            case "aproved":
                return "SEU PEDIDO FOI APROVADO!";
            case "ready":
                return "SEU PEDIDO FICOU PRONTO!";
            case "on-route":
                return "SEU PEDIDO SAIU PARA ENTREGA!";
            case "canceled":
                return "SEU PEDIDO FOI CANCELADO!";
            case "delivered":
                return "SEU PEDIDO FOI ENTREGUE!";

            default:
                return "SEU PEDIDO FOI RECEBIDO PELO VENDEDOR.";
        }
    }
    return (
        <>
            {order ? (
                <div id="success-wrap">
                    <div class="success-order" style={{ marginTop: 0 }}>
                        <div>
                            {order.business && (
                                <img
                                    src={order.business.logo}
                                    alt="img1"
                                    class="review-logo"
                                />
                            )}
                        </div>
                        <h1> {order.business && order.business.name}</h1>

                        <p>Número do pedido:</p>
                        <span>{order.orderNumber}</span>
                    </div>

                    <div class="success-sumary">
                        <div class="title">DETALHES DO PEDIDO</div>

                        <div class="Rtable Rtable--3cols">
                            <div class="Rtable-cell Rtable-header">PRODUTO</div>
                            <div class="Rtable-cell Rtable-header">QTD</div>
                            <div class="Rtable-cell Rtable-header">PREÇO</div>
                            {order.selections.map((item) => {
                                return (
                                    <>
                                        <div class="Rtable-cell Rtable-content">
                                            {item.productName}
                                        </div>
                                        <div class="Rtable-cell Rtable-content">
                                            {item.quantity}
                                        </div>
                                        <div class="Rtable-cell Rtable-content">
                                            <Currency value={item.productPrice} />
                                        </div>
                                    </>
                                );
                            })}
                            {order.coupon ? (
                                <>
                                    <div class="Rtable-footer Rtable-header">
                                        SUBTOTAL
                                    </div>
                                    <div class="Rtable-footer"></div>
                                    <div class="Rtable-footer Rtable-total">
                                        <Currency value={order.subTotal} />
                                    </div>
                                    <div class="Rtable-footer Rtable-header">
                                        CUPOM({order.coupon.amount}%)
                                    </div>
                                    <div class="Rtable-footer"></div>
                                    <div class="Rtable-footer Rtable-total">
                                        <Currency value={getCouponAmount()} />
                                    </div>
                                </>
                            ) : undefined}
                            {order.delivery === "delivery" ||
                            order.delivery === "localdelivery" ||
                            (order.delivery === "shipping" && order.shippingFee) ? (
                                <>
                                    <div class="Rtable-footer Rtable-header">
                                        TAXA DE ENTREGA
                                    </div>
                                    <div class="Rtable-footer"></div>
                                    <div class="Rtable-footer Rtable-total">
                                        {isFreeShipping() ? (
                                            <div>FRETE GRÁTIS</div>
                                        ) : (
                                            <Currency
                                                value={
                                                    order.deliveryTax || order.shippingFee
                                                }
                                            />
                                        )}
                                    </div>
                                </>
                            ) : undefined}
                            {order.installmentRate && order.installmentRate > 0 ? (
                                <>
                                    <div class="Rtable-footer Rtable-header">JUROS</div>
                                    <div class="Rtable-footer"></div>
                                    <div class="Rtable-footer Rtable-total">
                                        <Currency value={order.installmentRate} />
                                    </div>
                                </>
                            ) : undefined}
                            <div class="Rtable-footer Rtable-header">TOTAL</div>
                            <div class="Rtable-footer"></div>
                            <div class="Rtable-footer Rtable-total">
                                <Currency
                                    value={order.orderTotal + order.installmentRate}
                                />
                            </div>
                        </div>
                    </div>

                    <div class="success-details">
                        <div class="title">RESUMO</div>

                        <p>Número do pedido: {order.orderNumber}</p>
                        <p>Data:{moment(order.createdAt).format("DD/MM/YYYY HH:mm")}</p>
                        <p>
                            Total:
                            <Currency value={order.orderTotal + order.installmentRate} />
                        </p>
                    </div>

                    <div class="success-status">
                        <div class="title">SITUAÇÃO DO PEDIDO</div>
                        <p>{getStatusDescription(order.status)}</p>
                        <p>Qualquer dúvida favor entrar em contato pelo whatsapp:</p>
                        <p>{order.business.whatsappNumber}</p>
                    </div>

                    {order.delivery === "delivery" ||
                    order.delivery === "localdelivery" ? (
                        <div class="success-delivery">
                            <div class="title">ENDEREÇO DE ENTREGA</div>
                            <p>{order.address}</p>
                        </div>
                    ) : undefined}
                    {order.delivery === "takeout" ? (
                        <div class="success-delivery">
                            <div class="title">RETIRAR NA LOJA</div>
                            <p>{order.business.address}</p>
                        </div>
                    ) : undefined}

                    {order.delivery === "shipping" ? (
                        <div class="success-delivery">
                            <div class="title">ENDEREÇO DE ENVIO</div>
                            <p>{order.address}</p>
                        </div>
                    ) : undefined}

                    {order.delivery === "shipping" && getShipping() ? (
                        <div class="success-shipping">
                            <div class="title">DADOS DO ENVIO</div>
                            <p>{getShipping()}</p>
                        </div>
                    ) : undefined}

                    <div class="success-payment">
                        <div class="title">FORMA DE PAGAMENTO</div>
                        <p>{order.payment === "debit-card" ? "Cartão de débito" : ""}</p>
                        <p>
                            {order.payment === "credit-card" ? "Cartão de crédito" : ""}
                        </p>

                        {order.payment === "credit-card" && order.cardLastDigits ? (
                            <div>
                                <img src={order.cardImage} alt="" />
                                <p>{order.cardLastDigits}</p>
                                <p>{order.installmentsMessage}</p>
                            </div>
                        ) : undefined}

                        <p>{order.payment === "money" ? "Dinheiro" : ""}</p>
                        <p>
                            {order.payment === "money-order"
                                ? "Transferência Bancária / PIX"
                                : ""}
                        </p>
                        <p>{order.payment === "slip" ? "Boleto Bancário" : ""}</p>
                        {order.payment === "slip" && order.slipUri ? (
                            <div>
                                <a
                                    href={order.slipUri}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Clique aqui para imprimir o boleto
                                </a>
                            </div>
                        ) : undefined}
                        <p>
                            {order.payment === "payment-link" ? "Link de Pagamento" : ""}
                        </p>
                        {order.payment === "money-order" &&
                        order.business.bankAccount !== null &&
                        order.business.bankAccount !== undefined ? (
                            <div className="col-sm-12 mt-2 bank-section">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            order.business.bankAccount.indexOf("\n") !==
                                            -1
                                                ? order.business.bankAccount
                                                      .split("\n")
                                                      .join("<br/>")
                                                : order.business.bankAccount,
                                    }}
                                />
                            </div>
                        ) : undefined}
                    </div>
                    {order.note && (
                        <div class="success-note">
                            <div class="title">OBSERVAÇÃO</div>
                            <p>{order.note}</p>
                        </div>
                    )}

                    <div>
                        <link
                            rel="stylesheet"
                            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
                        ></link>
                        <a
                            href={
                                "https://api.whatsapp.com/send?phone=55" +
                                order.business.whatsappNumber
                            }
                            class="float"
                        >
                            <i class="fa fa-whatsapp my-float"></i>
                        </a>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

export default CheckOrderPage;
