import React from "react";
import Badge from "@material-ui/core/Badge";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

function Header(props) {
    return (
        <>
            <div className="header">
                <img
                    src="../assets/blank_image_top.png"
                    alt="carty icon"
                    width="50"
                    height="50"
                />
                <div className="badge-food">
                    <Badge
                        color="secondary"
                        badgeContent={props.cartItems.reduce((a, { qty }) => a + qty, 0)}
                        showZero
                        overlap="circle"
                    >
                        <div className="cart">
                            <Button
                                component={Link}
                                to={`${process.env.PUBLIC_URL}/cart`}
                                size="small"
                                color="inherit"
                                style={{ minWidth: 0 }}
                            >
                                <ShoppingCartIcon
                                    style={{ fontSize: 24, color: "#707070" }}
                                />
                            </Button>
                        </div>
                    </Badge>
                </div>
            </div>
            <div className="header-title">
                <div className="title">{props.business.name}</div>
                <p>{props.business.description}</p>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
    cartItems: state.cartList.cart,
});

export default connect(mapStateToProps)(Header);
