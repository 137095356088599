import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import store from "../../store";
import { getAllProducts, getBusiness, filterCategory } from "../../actions";
import { countPageView } from "../../api/products";
import { getVisibleProducts } from "../../services";
import GridView from "./GridView";
import Currency from "../../components/common/currency";
import { WhatsApp } from "./WhatsApp";
import { Link } from "react-router-dom";
import Header from "./Header";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import useWindowDimensions from "../../components/common/dimensions";
import Ratings from "../Ratings";
import MetaTags from "react-meta-tags";
import "./styles_mb.css";

/*
function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}
*/

function LandPage(props) {
    const [featured, setFeatured] = useState(false);
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);
    const [featuredId, setFeaturedId] = useState(0);
    const { width } = useWindowDimensions();

    function filterCategory(category) {
        setOpen(false);
        window.scrollTo(0, 0);
        props.filterCategory(category);
    }

    useEffect(() => {
        async function init() {
            document.body.style = "background: rgb(197, 191, 184);";
            let newClient = localStorage.getItem("newClient");

            let business = JSON.parse(localStorage.getItem("currentBusiness"));

            if (business && business.name) {
                if (newClient === "true") {
                    //alert("contei");
                    countPageView(business._id);
                    localStorage.setItem("newClient", "false");
                }

                store.dispatch(getAllProducts(business));
                store.dispatch(getBusiness(business));
                const favicon = document.getElementById("favicon");
                favicon.href = business.logo;
                document.title = business.name;
            }
        }

        init();
    }, []);

    useEffect(() => {
        if (props.products.length > 0 && !featured) {
            //console.log(props.products);
            let featuredProducts = props.products.filter((p) => {
                return p.featured === true;
            });
            if (featuredProducts.length === 0) {
                featuredProducts = props.products;
            }
            //let index = randomIntFromInterval(0, featuredProducts.length - 1);
            let index = 0;
            setFeatured(featuredProducts[index]);
            setFeaturedId(featuredProducts[index].id);
            setIndex(index);
        }
        /*
    if (props.selected.category !== "") {
      setFeatured(props.products[0]);
      setFeaturedId(props.products[0]._id);
      setIndex(0);
    }
    */
    }, [featured, props]);

    function onClose() {
        setOpen(false);
    }
    function openDrawer() {
        setOpen(true);
    }

    return (
        <div id="fendi-wrap">
            <MetaTags>
                <title>{props.business.name}</title>
                <meta name="description" content={props.business.details} />
                <meta property="og:title" content={props.business.name} />
                <meta property="og:image" content={props.business.logo} />
            </MetaTags>

            <div className="header-fendi">
                <Header openDrawer={openDrawer} />
            </div>
            <div className="blank-fendi">
                <WhatsApp whatsappNumber={props.business.whatsappNumber} />
            </div>
            <div className="announcement-fendi"></div>
            {props.business.freeShipping > 0 && (
                <div className="free-shipping">
                    <div class="fendi">
                        <div class="highlight">FRETE GRÁTIS</div>
                        <div class="space">para compras acima de</div>
                        <div class="highlight">
                            <Currency value={props.business.freeShipping} />
                        </div>
                    </div>
                </div>
            )}

            <div className="banner-fendi">
                {props.products.length > 0 && featured && featured.img !== null ? (
                    <Link
                        to={{
                            pathname: `${process.env.PUBLIC_URL}/product/${featured.id}`,
                            state: { view: "grid" },
                        }}
                    >
                        <div className="banner-container-fendi">
                            <div
                                style={{
                                    backgroundImage: `url(${featured.img})`,
                                    height: width < 480 ? width * 1.33 : 600,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                }}
                            ></div>
                            <div className="banner-fendi-title">{featured.name}</div>
                            {featured.promoPrice ? (
                                <div className="product-fendi-container-price">
                                    <div className="banner-fendi-price-line">
                                        <Currency value={featured.price} />
                                    </div>
                                    <div className="banner-fendi-promoprice">
                                        <Currency value={featured.promoPrice} />
                                    </div>
                                </div>
                            ) : (
                                <div className="banner-fendi-price">
                                    <Currency value={featured.price} />
                                </div>
                            )}
                        </div>
                    </Link>
                ) : undefined}
            </div>

            <GridView
                business={props.business.uid}
                index={index}
                featuredId={featuredId}
            />

            {props.business &&
                props.business.features &&
                props.business.features.includes("rating") && (
                    <div className="rating-fendi">
                        <Ratings business={props.business} />
                    </div>
                )}

            <div className="line"></div>

            <div className="footer-fendi">
                <div className="footer-fendi-title">Sobre nós</div>
                <div className="card-fendi">
                    <div className="card-fendi-text">{props.business.description}</div>
                </div>
                {props.business && (props.business.instagram || props.business.facebook) && (
                    <div className="social-media">
                        <div className="social-media-container">
                            <div className="title">Contato:</div>
                            <div class="icon">
                                <a
                                    href={`https://api.whatsapp.com/send?phone=55${props.business.whatsappNumber}`}
                                    class="link"
                                >
                                    <i class="fa fa-whatsapp"></i>
                                </a>
                            </div>
                            {props.business.instagram && (
                                <div className="icon">
                                    <a
                                        href={
                                            "instagram://user?username=" +
                                            props.business.instagram
                                        }
                                    >
                                        <i class="fa fa-instagram"></i>
                                    </a>
                                </div>
                            )}

                            {props.business.facebook && (
                                <div className="icon">
                                    <a
                                        href={
                                            "https://www.facebook.com/" +
                                            props.business.facebook
                                        }
                                    >
                                        <i class="fa fa-facebook"></i>
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className="footer-fendi-logo">
                    <img className="logo" src={props.business.logo} alt="logo" />
                    <li>
                        <ul>
                            <span>{props.business.phone}</span>
                        </ul>
                        <ul>
                            <span>{props.business.address}</span>
                        </ul>
                        <ul>
                            <span>{props.business.email}</span>
                        </ul>
                    </li>
                </div>
            </div>

            <SwipeableDrawer anchor="left" open={open} onClose={() => onClose()}>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        width: 375,
                        backgroundColor: "#f2f2f2",
                    }}
                >
                    <div onClick={() => setOpen(false)}>
                        <img
                            src="/assets/close.png"
                            alt="fechar"
                            style={{
                                width: 16,
                                height: 16,
                                marginLeft: 21,
                                marginTop: 30,
                            }}
                        />
                    </div>
                    <div style={{ display: "flex", marginTop: 30 }}>
                        <img
                            src="/assets/layers.png"
                            alt="categorias"
                            style={{ width: 16, height: 16, marginLeft: 21 }}
                        />
                        <div className="drawer-title">Categorias</div>
                    </div>
                    <ul className="drawer-items">
                        {props.categories.map((category) => {
                            return (
                                <div onClick={() => filterCategory(category)}>
                                    <li>{category}</li>
                                </div>
                            );
                        })}
                    </ul>
                </div>
            </SwipeableDrawer>
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
    categories: state.category.current,
    cartItems: state.cartList.cart,
    selected: state.filters,
    products: getVisibleProducts(state.data, state.filters, state.searchTerm),
});

export default connect(mapStateToProps, { filterCategory })(LandPage);
