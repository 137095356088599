import React from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";

const Header = (props) => {
  return (
    <div className="container-header-minimalist">
      <div className="back-minimalist">
        <Link
          style={{ textDecoration: "none" }}
          to={{
            pathname: `${process.env.PUBLIC_URL}/`,
          }}
          className="secondary"
        >
          <img src="/assets/arrow-left.svg" alt="menu"></img>
        </Link>
      </div>

      <div className="title-minimalist">
        {props.checkout ? "CHECKOUT(" : "SACOLA("}
        {props.cartItems.reduce((a, { qty }) => a + qty, 0)})
      </div>

      <div className="selectall-minimalist">
        <img src="/assets/blank_image_top.png" alt="selecionar todos"></img>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  business: state.business.current,
  cartItems: state.cartList.cart,
});

export default connect(mapStateToProps)(Header);
