import React from "react";
import Badge from "@material-ui/core/Badge";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";

function Header(props) {
    const history = useHistory();

    function goBack() {
        history.goBack();
    }

    return (
        <div className="container-header-fendi">
            {props.back ? (
                <div className="back-fendi" onClick={() => goBack()}>
                    <img src="/assets/arrow-left.svg" alt="menu"></img>
                </div>
            ) : (
                <div className="menu-fendi" onClick={() => props.openDrawer()}>
                    <img src="/assets/sidelist.png" alt="menu"></img>
                </div>
            )}
            <Link
                style={{ textDecoration: "none" }}
                to={{
                    pathname: `${process.env.PUBLIC_URL}/`,
                }}
                className="secondary"
            >
                <div className="title-fendi">{props.business.name}</div>
            </Link>
            <div className="cart-fendi">
                <Badge
                    component={Link}
                    to={`${process.env.PUBLIC_URL}/cart`}
                    color="secondary"
                    badgeContent={props.cartItems.reduce((a, { qty }) => a + qty, 0)}
                    overlap="circle"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                >
                    <img src="/assets/bag.png" alt="carrinho"></img>
                </Badge>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
    cartItems: state.cartList.cart,
});

export default connect(mapStateToProps)(Header);
