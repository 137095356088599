import React, { useState, useEffect } from "react";
import Rating from "@material-ui/lab/Rating";
import Carousel from "react-material-ui-carousel";
import service from "../api/service";
import moment from "moment";

function Ratings(props) {
  const [ratings, setRatings] = useState();
  useEffect(() => {
    async function init() {
      const response = await service.get(`/rating/${props.business._id}`);
      setRatings(response.data);
    }
    init();
  }, [props.business._id]);
  return (
    <>
      {ratings && ratings.filter((a) => a.show).length > 0 && (
        <>
          <div class="separator" />
          <div class="rating-wrap">
            <div className="title">Avaliações</div>
            <div class="rating-content">
              <Carousel autoPlay={true}>
                {ratings &&
                  ratings
                    .filter((a) => a.show)
                    .map((item, i) => <RatingItem key={i} item={item} />)}
              </Carousel>
            </div>
          </div>
        </>
      )}
    </>
  );
}

function RatingItem(props) {
  return (
    <>
      <div class="rating-header">
        <div class="first-line">
          <div class="rating-title">{props.item.title}</div>
          <div class="rating-stars">
            <Rating
              name="read-only"
              value={props.item.stars}
              readOnly
              size="small"
            />
          </div>
        </div>
      </div>
      <div class="rating-review">{props.item.review}</div>
      <div class="rating-user">
        {props.item.userName} -
        {moment(props.item.createdAt).format("DD/MM/YYYY")}
      </div>
    </>
  );
}
export default Ratings;
