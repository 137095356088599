import React, { useContext, useEffect, useState } from "react";
import { CarouselContext } from "pure-react-carousel";
import { Slider, Slide, DotGroup, Image } from "pure-react-carousel";

export function ProductCarousel(props) {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide
  );
  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <>
      <div className="carousel-container">
        <Slider className="carousel-slider">
          {props.images.map((image, i) => {
            return (
              <Slide index={i}>
                <Image src={image} />
              </Slide>
            );
          })}
        </Slider>
        <div className="carousel-position">
          {currentSlide + 1}/{props.images.length}
        </div>
      </div>

      <DotGroup className="carousel-dotgroup" />
    </>
  );
}
