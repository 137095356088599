import { SET_INSTALLMENT, CLEAR_INSTALLMENT } from "../constants/ActionTypes";

const initialState = {
    current: {},
};

const installmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INSTALLMENT:
            //console.log("setando");
            //console.log(action.installment);
            return { ...state, current: action.installment };
        case CLEAR_INSTALLMENT:
            return { current: {} };
        default:
            return state;
    }
};
export default installmentReducer;
