import React from "react";

export default function CurrencyInput(props) {
  function unmask(number) {
    if (number) {
      let digitsOnly = number.toString().match(/\d+/g);
      let result = digitsOnly
        ? parseInt(digitsOnly.join(""), 10) / Math.pow(10, 2)
        : undefined;

      return result;
    }
    return 0;
  }
  function onChange(e) {
    e.target.value = unmask(e.target.value);
    props.onChange(e);
  }

  function formatNumberValue(numberValue) {
    if (numberValue && numberValue !== null) {
      numberValue = parseFloat(numberValue).toFixed(2);
      return new Intl.NumberFormat(["pt-BR"], {
        style: "currency",
        currency: "BRL",
      }).format(unmask(numberValue));
    }
    return "";
  }

  return (
    <div>
      {props.type === "input" ? (
        <input
          className="form-control"
          id={props.id}
          type="text"
          value={formatNumberValue(props.value)}
          required={props.required}
          onChange={onChange}
        />
      ) : (
        formatNumberValue(props.value)
      )}
    </div>
  );
}
