import React from "react";
import { Route, Switch } from "react-router-dom";
import LandPage from "./main/dark/LandPage";
import Categories from "./main/dark/Categories";
import CartPage from "./main/dark/CartPage";
import ProductPage from "./main/dark/ProductPage";
import CheckoutPage from "./main/CheckoutPage";
import SuccessPage from "./main/SuccessPage";
import ReviewPage from "./main/ReviewPage";
import CheckOrderPage from "./main/CheckOrderPage";
export const DarkLayout = () => {
    return (
        <Switch>
            <Route
                path={`${process.env.PUBLIC_URL}/`}
                component={LandPage}
                exact={true}
            />
            <Route
                path={`${process.env.PUBLIC_URL}/cart`}
                component={CartPage}
                exact={true}
            />
            <Route
                path={`${process.env.PUBLIC_URL}/checkout`}
                component={CheckoutPage}
                exact={true}
            />
            <Route
                path={`${process.env.PUBLIC_URL}/product/:id`}
                component={ProductPage}
                exact={true}
            />
            <Route
                path={`${process.env.PUBLIC_URL}/category`}
                component={Categories}
                exact={true}
            />
            <Route
                path={`${process.env.PUBLIC_URL}/category/:uid`}
                component={Categories}
                exact={true}
            />
            <Route
                path={`${process.env.PUBLIC_URL}/order`}
                component={SuccessPage}
                exact={true}
            />
            <Route
                path={`${process.env.PUBLIC_URL}/review/:orderId`}
                component={ReviewPage}
                exact={true}
            />
            <Route
                path={`${process.env.PUBLIC_URL}/get/order/:orderId`}
                component={CheckOrderPage}
                exact={true}
            />
        </Switch>
    );
};
