import { combineReducers } from "redux";

// Import custom components
import productReducer from "./products";
import businessReducer from "./business";
import cartReducer from "./cart";
import categoryReducer from "./categories";
import filtersReducer from "./filters";
import limitReducer from "./limit";
import shippingReducer from "./shipping";
import couponReducer from "./coupon";
import installmentReducer from "./installment";
import searchTermReducer from "./searchTerm";
import promoReducer from "./promo";

const appReducer = combineReducers({
    data: productReducer,
    cartList: cartReducer,
    business: businessReducer,
    category: categoryReducer,
    filters: filtersReducer,
    limit: limitReducer,
    shipping: shippingReducer,
    coupon: couponReducer,
    installment: installmentReducer,
    searchTerm: searchTermReducer,
    promos: promoReducer,
});

const rootReducer = (state, action) => {
    if (action.type === "CLEAR") {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
