import React, { useEffect, useState, useCallback } from "react";

import { connect } from "react-redux";
import store from "../../store";
import { getAllProducts, getBusiness, filterCategory } from "../../actions";
import { countPageView } from "../../api/products";
import { getVisibleProducts } from "../../services";
import GridView from "./GridView";
import Currency from "../../components/common/currency";
import { WhatsApp } from "./WhatsApp";
import { Link } from "react-router-dom";
import Header from "./Header";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Ratings from "../Ratings";
import MetaTags from "react-meta-tags";
import "./styles_m.css";

import ReactLoading from "react-loading";
import Banner from "../Banner";
import FooterMinimalist from "./FooterMinimalist";
import ReactPixel from "react-facebook-pixel";

/*
function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}
*/

function LandPage(props) {
    const [featured, setFeatured] = useState(false);
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);
    const [featuredId, setFeaturedId] = useState(0);
    //const [categories, setCategories] = useState();

    const isGroupCategory = useCallback(() => {
        return (
            (props.business.activePlanName === "Premium" ||
                props.business.activePlanName === "Pro" ||
                props.business.activePlanName === "Diamante") &&
            props.categories &&
            props.categories.length > 0 &&
            props.categories[0].categories
        );
    }, [props.business.activePlanName, props.categories]);
    /*
    useEffect(() => {
        if (isGroupCategory()) {
            setCategories(props.categories);
        } else {
            setCategories([
                "Todos",
                ...props.categories.map((c) => {
                    return c.name;
                }),
            ]);
        }
    }, [isGroupCategory, props.categories]);
    */
    function filterCategory(category) {
        setOpen(false);

        document
            .getElementById("product-anchor")
            .scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        //window.scrollTo(0, 0);
        props.filterCategory(category.replace('/', ' '));
    }

    useEffect(() => {
        async function init() {
            document.body.style = "background: #f2f2f2;";
            let newClient = localStorage.getItem("newClient");

            let business = JSON.parse(localStorage.getItem("currentBusiness"));

            if (business && business.name) {
                if (newClient === "true") {
                    //alert("contei");
                    countPageView(business._id);

                    localStorage.setItem("newClient", "false");
                }
                if (
                    business.features.includes("metapixel") &&
                    business.activePlanName === "Diamante"
                ) {
                    const advancedMatching = {};
                    const options = {
                        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                        debug: false, // enable logs
                    };
                    let pixel = business.facebookPixel;
                    ReactPixel.init(pixel, advancedMatching, options);
                    ReactPixel.pageView(); // For tracking page view
                    console.log("carreguei o pixel do facebook");
                }
                store.dispatch(getAllProducts(business));
                store.dispatch(getBusiness(business));
                const favicon = document.getElementById("favicon");
                favicon.href = business.logo;
                document.title = business.name;
            }
        }

        init();
    }, []);

    useEffect(() => {
        if (props.products.length > 0 && !featured) {
            //console.log(props.products);
            let featuredProducts = props.products.filter((p) => {
                return p.featured === true;
            });
            if (featuredProducts.length === 0) {
                featuredProducts = props.products;
            }
            //let index = randomIntFromInterval(0, featuredProducts.length - 1);
            let index = 0;
            setFeatured(featuredProducts[index]);
            setFeaturedId(featuredProducts[index].id);
            setIndex(index);
        }
        /*
    if (props.selected.category !== "") {
      setFeatured(props.products[0]);
      setFeaturedId(props.products[0]._id);
      setIndex(0);
    }
    */
    }, [featured, props]);

    function onClose() {
        setOpen(false);
    }
    function openDrawer() {
        setOpen(true);
    }

    function isLoading() {
        return localStorage.getItem("loaded") !== "true";
    }

    return (
        <div id="minimalist-wrap">
            {isLoading() && props.products.length === 0 ? (
                <div className="loading-area">
                    <ReactLoading type={"bubbles"} color={"black"} />
                </div>
            ) : (
                <>
                    <MetaTags>
                        <title>{props.business.name}</title>
                        <meta name="description" content={props.business.details} />
                        <meta property="og:title" content={props.business.name} />
                        <meta property="og:image" content={props.business.logo} />
                    </MetaTags>

                    <div className="header-minimalist">
                        <Header openDrawer={openDrawer} />
                    </div>
                    <div className="blank">
                        <WhatsApp whatsappNumber={props.business.whatsappNumber} />
                    </div>

                    {props.business.freeShipping > 0 && (
                        <div className="free-shipping">
                            <div class="minimalist">
                                <div class="highlight">FRETE GRÁTIS</div>
                                <div class="space">para compras acima de</div>
                                <div class="highlight">
                                    <Currency value={props.business.freeShipping} />
                                </div>
                            </div>
                        </div>
                    )}
                    {props.business && (
                        <div className="promo-banner">
                            <Banner business={props.business} />
                        </div>
                    )}

                    <div className="banner-minimalist">
                        {props.products.length > 0 &&
                        featured &&
                        featured.img !== null ? (
                            <Link
                                to={{
                                    pathname: `${process.env.PUBLIC_URL}/product/${featured.id}`,
                                    state: { view: "grid" },
                                }}
                            >
                                <div className="banner-container-minimalist">
                                    <img
                                        src={featured.img}
                                        style={{ objectFit: "contain", width: "100%" }}
                                        alt="imagem principal"
                                    />

                                    <div className="banner-minimalist-title">
                                        {featured.name}
                                    </div>
                                    {featured.promoPrice ? (
                                        <div className="product-minimalist-container-price">
                                            <div className="banner-minimalist-price-line">
                                                <Currency value={featured.price} />
                                            </div>
                                            <div className="banner-minimalist-promoprice">
                                                <Currency value={featured.promoPrice} />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="banner-minimalist-price">
                                            <Currency value={featured.price} />
                                        </div>
                                    )}
                                </div>
                            </Link>
                        ) : undefined}
                    </div>

                    <GridView
                        business={props.business.uid}
                        index={index}
                        featuredId={featuredId}
                    />

                    {props.business &&
                        props.business.features &&
                        props.business.features.includes("rating") && (
                            <div className="rating-minimalist">
                                <Ratings business={props.business} />
                            </div>
                        )}

                    <div className="line"></div>

                    <div className="footer-minimalist">
                        <FooterMinimalist />
                    </div>

                    <SwipeableDrawer anchor="left" open={open} onClose={() => onClose()}>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                width: 375,
                                backgroundColor: "#f2f2f2",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: 30,
                                }}
                            >
                                <div onClick={() => setOpen(false)}>
                                    <img
                                        src="/assets/close.png"
                                        alt="fechar"
                                        style={{
                                            width: 16,
                                            height: 16,
                                            marginLeft: 21,
                                        }}
                                    />
                                </div>
                                <div
                                    onClick={() => filterCategory("Todos")}
                                    className="drawer-clean"
                                >
                                    LIMPAR
                                </div>
                            </div>
                            <div style={{ display: "flex", marginTop: 30 }}>
                                <img
                                    src="/assets/layers.png"
                                    alt="categorias"
                                    style={{ width: 16, height: 16, marginLeft: 21 }}
                                />
                                <div className="drawer-title">Categorias</div>
                            </div>
                            <ul className="drawer-items">
                                {isGroupCategory()
                                    ? props.categories &&
                                      props.categories.map((group) => {
                                          return (
                                              <>
                                                  <div>{group.name}</div>
                                                  {group.categories.map((category) => {
                                                      return (
                                                          <div
                                                              style={{
                                                                  paddingLeft: 30,
                                                                  cursor: "pointer",
                                                              }}
                                                              onClick={() =>
                                                                  filterCategory(
                                                                      category.name
                                                                  )
                                                              }
                                                          >
                                                              <li
                                                                  style={{
                                                                      color:
                                                                          props.selected
                                                                              .category ===
                                                                          category.name
                                                                              ? "rgba(var(--primary-color), 1)"
                                                                              : "#888888",
                                                                  }}
                                                              >
                                                                  {category.name}
                                                              </li>
                                                          </div>
                                                      );
                                                  })}
                                              </>
                                          );
                                      })
                                    : props.categories &&
                                      props.categories.map((category) => {
                                          return (
                                              <div
                                                  style={{
                                                      cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                      filterCategory(category.name)
                                                  }
                                              >
                                                  <li
                                                      style={{
                                                          color:
                                                              props.selected.category ===
                                                              category.name
                                                                  ? "rgba(var(--primary-color), 1)"
                                                                  : "#888888",
                                                      }}
                                                  >
                                                      {category.name}
                                                  </li>
                                              </div>
                                          );
                                      })}
                            </ul>
                        </div>
                    </SwipeableDrawer>
                </>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
    categories: state.category.current,
    cartItems: state.cartList.cart,
    selected: state.filters,
    products: getVisibleProducts(state.data, { category: null }, state.searchTerm, true),
});

export default connect(mapStateToProps, { filterCategory })(LandPage);
