import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getVisibleProducts } from "../../services";
import "./styles_f.css";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10,
    },
}));

function GridView(props) {
    const classes = useStyles();

    let limit = 21;
    const [loadedProducts, setLoadedProducts] = useState([]);
    const [quantity, setQuantity] = useState(limit);

    useEffect(() => {
        setLoadedProducts(props.products.slice(0, quantity));
    }, [props.products, quantity]);

    function loadMore() {
        setQuantity(quantity + limit);
    }

    useEffect(() => {
        setQuantity(21);
    }, [props.selected.category]);

    return (
        <div className={classes.root}>
            {props.products.length > 0 ? (
                <GridList spacing={10} className={classes.gridList} cols={3}>
                    {loadedProducts.map((product, index) => (
                        <GridListTile
                            key={index}
                            cols={product.featured ? 3 : 1}
                            rows={product.featured ? 2 : 1}
                        >
                            <Link
                                to={{
                                    pathname: `${process.env.PUBLIC_URL}/product/${product.id}`,
                                    state: { view: "grid" },
                                }}
                            >
                                <img
                                    src={product.img}
                                    alt={product.name}
                                    style={{
                                        opacity:
                                            !product.hasStock &&
                                            props.business.features &&
                                            props.business.features.includes("stock")
                                                ? 0.3
                                                : 1,
                                    }}
                                    className={
                                        product.featured
                                            ? "MuiGridListTile-imgFullWidth"
                                            : "MuiGridListTile-imgFullHeight"
                                    }
                                />
                            </Link>
                        </GridListTile>
                    ))}
                </GridList>
            ) : undefined}
            {quantity < props.products.length && (
                <div className="load-more-button" onClick={() => loadMore()}>
                    MAIS PRODUTOS
                </div>
            )}
        </div>
    );
}
const mapStateToProps = (state) => ({
    business: state.business.current,
    products: getVisibleProducts(state.data, state.filters, state.searchTerm),
    selected: state.filters,
});

export default connect(mapStateToProps, {})(GridView);
