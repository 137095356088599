import React from "react";
import "./styles_support.css";

function SupportInstagramShopping(props) {
    return (
        <div id="support-wrap">
            <div className="support-content">
                <h2>
                    Como realizar a integração com o Instagram Shopping ( Sacolinha do
                    Instagram ){" "}
                </h2>
                <h4>Nota:</h4>
                <p>Essa funcionalidade é exclusiva para clientes do Plano PRO.</p>
                <p>
                    O Carty possui as ferramentas necessárias para a realização da
                    integração com o Instagram Shopping porém é necessário informar que:
                </p>
                <ul>
                    <li>
                        A configuração da sacolinha do instagram é um processo de
                        complexidade alta. Considere contratar um profissional para te
                        auxiliar nessa atividade.
                    </li>
                    <li>
                        A aprovação da sua loja no Instagram Shopping depende
                        exclusivamente dos critérios e decisão do Facebook.
                    </li>
                    <li>
                        No momento não possuímos suporte para a configuração da sacolinha
                        no Facebook.
                    </li>
                </ul>
                <h4>Material de referência</h4>
                <p>
                    Disponibilizamos esse vídeo encontrado no Youtube como referência para
                    a configuração da Sacolinha do Instagram. Acreditamos que, seguidas as
                    etapas explicadas no vídeo, sua loja será aprovada.
                </p>
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/Yz0eaztzOKw"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                ></iframe>
                <h4>XML para configuração do Catálogo de Produtos</h4>
                <p>Siga as etapas abaixo para gerar o link do XML para o catálogo:</p>
                <ul>
                    <li>Na tela inicial do Carty clique em Minhas Integrações</li>
                    <li>Clique em Sacolinha do Instagram</li>
                    <li>Clique em geral XML</li>
                    <li>
                        Você pode clicar no link para copiá-lo para a área de
                        transferência
                    </li>
                </ul>
                <p>
                    Agora é só utilizar o link na configuração do Catálogo de produtos do
                    Facebook
                </p>
                <h4>Para verificação do domínio</h4>
                <p>
                    Para verificar um domínio é necessário que você tenha um domínio
                    próprio, o domínio com o .carty.com.br não será aceito para fins de
                    aprovação do Instagram Shopping. No Carty, os clientes do Plano PRO
                    podem hospedar o seu domínio nos servidores do Carty. Para isso é
                    necessário registrar um domínio no site https://registro.br. Esse
                    registro vai te custar em torno de R$ 40 por ano e ficará registrado
                    no seu CPF portanto, não ficará vinculado ao Carty e poderá ser
                    utilizado em qualquer outra plataforma a seu critério.
                </p>
                <p>
                    Após o registro do domínio, entre em contato com o suporte técnico. O
                    suporte irá redirecionar sua loja do Carty para o seu novo domínio.
                </p>
                <p>
                    Feito isso você poderá prosseguir para a verificação do domínio no
                    Facebook.
                </p>
                <ul>
                    <li>
                        1. Você vai precisar acessar o seguinte endereço:
                        https://business.facebook.com/settings/owned-domains e fazer o
                        login com seu usuário e senha do Facebook.
                    </li>
                    <li>
                        2. Clique em Adicionar Domínio e digite o seu domínio. Use o
                        domínio que você registrou e NÃO o com o ".carty.com.br"
                    </li>
                    <li>
                        3. Selecione a opção ‘Atualize o registro TXT do DNS com seu
                        registrador de domínio ( terceira opção )
                    </li>
                    <li>
                        4. Abaixo vão aparecer as instruções, localize o código que começa
                        com facebook-domain-verification.
                    </li>
                    <li>
                        5. Copie esse código, você vai precisar dele quando entrar em
                        contato com o suporte técnico
                    </li>
                    <li>
                        6. Entre em contato com o suporte técnico do Carty e informe que
                        você deseja fazer a validação do domínio
                    </li>
                    <li>
                        7. Forneça o código do tipo Facebook-domain-verification=dfjksjfl
                        para o atendente do suporte.
                    </li>
                    <li>8. Aguarde a configuração do suporte</li>
                    <li>9. Volte na página do Facebook e clique em Verificar Domínio</li>
                </ul>
            </div>
        </div>
    );
}
export default SupportInstagramShopping;
