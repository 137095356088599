import React, { useState } from "react";
import service from "../api/service";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import { setCoupon } from "../actions";

function Coupon(props) {
    const [couponCode, setCouponCode] = useState(props.coupon.code);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState();

    function handleClick(m) {
        setMessage(m);
        setOpen(true);
    }
    function handleClose() {
        setOpen(false);
    }

    async function checkCoupon() {
        if (couponCode && couponCode !== "") {
            try {
                const response = await service.get(
                    `coupon/${props.business._id}/${couponCode.toUpperCase()}`
                );
                props.setCoupon(response.data);

                handleClick("Cupom aplicado ao carrinho.");
            } catch (e) {
                console.log(e);
                if (e.data.error === "This coupon code does not exist.") {
                    handleClick("Cupom inválido.");
                } else if (e.data.error === "Coupon expired.") {
                    handleClick("Cupom expirado.");
                }
            }
        }
    }

    return (
        <>
            <div class="cart-coupon">
                <TextField
                    id="outlined-basic"
                    label="Cupom de Desconto:"
                    placeholder="Insira aqui o código do cupom"
                    variant="outlined"
                    size="small"
                    fullWidth
                    style={{ flex: 0.8 }}
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                />
                <div onClick={() => checkCoupon()} class="apply-button">
                    APLICAR
                </div>
                <div class="line"></div>
            </div>
            <Snackbar
                open={open}
                //onClose={handleClose}
                message={message}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                autoHideDuration={3000}
                onClose={handleClose}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    coupon: state.coupon.current,
    business: state.business.current,
});
export default connect(mapStateToProps, {
    setCoupon,
})(Coupon);
