import React from "react";

function DataProtection(props) {
    return (
        <div class="privacy">
            <h3>Como solicitar a exclusão dos dados no app Carty</h3>

            <p>
                A sua privacidade é importante para nós. Para que os usuários tenham mais
                transparência e controle sobre os próprios dados, adicionamos aqui as
                etapas necessárias para solicitar a exclusão dos seus dados da nossa
                plataforma.
            </p>
            <p>
                IMPORTANTE: Essa operação exclui todos os dados relacionados ao usuário em
                questão. A exclusão dos dados é definitiva e não pode ser desfeita.
            </p>
            <p>
                IMPORTANTE: A exclusão dos dados da plataforma não tem relação com o
                cancelamento de um plano de assinatura. Para cancelar sua assinatura abra
                o app Carty, clique em Perfil e então em Cancelar Assinatura.
            </p>
            <h4>Para solicitar a exclusão siga as etapas:</h4>
            <p>1) Abra o app Carty</p>

            <p>2) Na tela inicial clique em Perfil</p>
            <p>
                3) Selecione a opção Excluir Conta, você pode precisar rolar a tela
                dependendo da resolução do seu aparelho celular.
            </p>
            <p>4) Opcional - Preencha o motivo da exclusão da conta.</p>
            <p>5) Clique no botão EXCLUIR.</p>
            <br></br>
            <p>Carty Brasil Software Ltda</p>
        </div>
    );
}
export default DataProtection;
