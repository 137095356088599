import service from "./service";

export const loadProducts = async (uid) => {
    let products = [];
    try {
        products = await service.get("product/business/" + uid);
    } catch (e) {
        console.log("no erro");
        console.log(e);
    }
    return products.data.products;
};

export const loadFirstProduct = async (uid) => {
    let products = [];
    try {
        products = await service.get("product/business/first/" + uid);
    } catch (e) {
        console.log("no erro");
        console.log(e);
    }
    return products.data.products;
};

export const loadPromos = async (uid) => {
    let promos = [];
    try {
        promos = await service.get(`/promos/${uid}`);
    } catch (e) {
        console.log("no erro");
        console.log(e);
    }
    return promos.data.promos;
};

export const loadProduct = async (id) => {
    let product = {};
    try {
        product = await service.get("product/" + id);
    } catch (e) {
        console.log("no erro");
        console.log(e);
    }
    return product.data.product;
};

export const lockProduct = async (id, quantityToDecrease) => {
    let product = {};
    try {
        product = await service.post("product/lock", {
            productId: id,
            quantityToDecrease,
        });
    } catch (e) {
        console.log("no erro");
        console.log(e);
    }
    return product.data.product;
};

export const loadCategories = async (id) => {
    let categories = [];
    try {
        categories = await service.get("/category/group/" + id);
        //console.log(categories);
    } catch (e) {
        console.log("no erro");
        console.log(e);
    }
    return categories.data;
};

export const countPageView = (id) => {
    //console.log("VIEW + 1");
    service.get("/business/hello/" + id);
    service.get("/traffic/hi/" + id);
};

export const loadBusiness = async (uid) => {
    let business = {};
    try {
        business = await service.get("/business/" + uid);
    } catch (e) {
        console.log("no erro");
        console.log(e);
    }
    return business.data.business;
};

export const loadTraffic = async (id) => {
    let traffic = {};
    try {
        traffic = await service.get("/traffic/" + id);
    } catch (e) {
        console.log("no erro");
        console.log(e);
    }
    return traffic.data.traffic;
};

export const loadBusinessCustomDomain = async (customDomain) => {
    let business = {};
    try {
        business = await service.get("/business/custom/" + customDomain);
    } catch (e) {
        console.log("no erro");
        console.log(e);
    }
    return business.data.business;
};

export const loadAll = async () => {
    let business = {};
    try {
        business = await service.get("/business/info");
        //console.log(business);
    } catch (e) {
        console.log("no erro");
        console.log(e);
    }
    return business.data;
};
