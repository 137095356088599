import React, { useState } from "react";
import { connect } from "react-redux";

import { filterCategory } from "../../actions";

import "./styles_d.css";

import { getVisibleProducts } from "../../services";
import { useHistory } from "react-router-dom";
//import shop from "../../api/shop";

function GridCategories(props) {
    //const [categories, setCategories] = useState();
    const [groupName, setGroupName] = useState("");

    let history = useHistory();
    function cleanCategoryName(category){
        return category.replace('/', ' ');
    }
    function viewCategory(category) {
        props.filterCategory(cleanCategoryName(category));
        history.push("/category/" + cleanCategoryName(category));
    }

    function getCategoryImage(category) {
        if (category && category.imageUri) {
            return category.imageUri;
        } else {
            let productsWithCategory = [];
            if (props.products && props.products.length > 1) {
                productsWithCategory = props.products.filter((p) =>
                    p.category.includes(category.name?.replace("/", " "))
                );
            }

            if (productsWithCategory && productsWithCategory.length > 0) {
                return productsWithCategory[0].img;
            } else {
                return "/assets/blank_category.png";
            }
        }
    }
    function isGroupCategory() {
        return (
            (props.business.activePlanName === "Premium" ||
                props.business.activePlanName === "Pro" ||
                props.business.activePlanName === "Diamante") &&
            props.categories &&
            props.categories.length > 0 &&
            props.categories[0].categories
        );
    }
    function filterCategoryGroup(groupName) {
        //setShowGroupCategory(true);
        setGroupName(groupName);
    }

    return (
        <>
            {isGroupCategory() && groupName !== "" ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 30,
                    }}
                >
                    <div class="back-group" onClick={() => setGroupName("")}>
                        <i class="fa fa-chevron-left"></i>
                    </div>
                    <div class="title-group">{groupName}</div>
                    <div></div>
                </div>
            ) : (
                props.products.length > 1 &&
                props.categories &&
                props.categories.length > 0 && (
                    <div class="title">Compre por Categoria</div>
                )
            )}

            {isGroupCategory() ? (
                <div class="grid-dark-categories">
                    {groupName === ""
                        ? props.categories.map((category, index) => {
                              return (
                                  <div style={{ flex: 1 }}>
                                      <div
                                          onClick={() =>
                                              filterCategoryGroup(category.name)
                                          }
                                          style={{
                                              backgroundImage: `url(${getCategoryImage(
                                                  category
                                              )})`,
                                              height: 140,
                                              backgroundPosition: "center",
                                              backgroundSize: "cover",
                                              backgroundRepeat: "no-repeat",
                                          }}
                                      ></div>
                                      <div class="category">{category.name}</div>
                                  </div>
                              );
                          })
                        : props.categories &&
                          props.categories
                              .filter((g) => g.name === groupName)[0]
                              .categories.map((category, index) => {
                                  return (
                                      <div style={{ flex: 1 }}>
                                          <div
                                              onClick={() => viewCategory(category.name)}
                                              style={{
                                                  backgroundImage: `url(${getCategoryImage(
                                                      category
                                                  )})`,
                                                  height: 140,
                                                  backgroundPosition: "center",
                                                  backgroundSize: "cover",
                                                  backgroundRepeat: "no-repeat",
                                              }}
                                          ></div>
                                          <div class="category">{category.name}</div>
                                      </div>
                                  );
                              })}
                </div>
            ) : (
                <div class="grid-dark-categories">
                    {props.categories &&
                        props.categories.length > 0 &&
                        props.categories.map((category, index) => {
                            return (
                                <div style={{ flex: 1 }}>
                                    <div
                                        onClick={() => viewCategory(category.name)}
                                        style={{
                                            cursor: "pointer",
                                            backgroundImage: `url(${getCategoryImage(
                                                category
                                            )})`,
                                            height: 160,
                                            backgroundPosition: "center",
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                        }}
                                    ></div>
                                    <div class="category">{category.name}</div>
                                </div>
                            );
                        })}
                </div>
            )}
        </>
    );
}
const mapStateToProps = (state) => ({
    categories: state.category.current,
    selected: state.filters,
    products: getVisibleProducts(state.data, { category: null }, { searchTerm: null }),
    business: state.business.current,
});

export default connect(mapStateToProps, { filterCategory })(GridCategories);
