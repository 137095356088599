function unmask(number) {
    if (number) {
        let digitsOnly = number.toString().match(/\d+/g);
        let result = digitsOnly
            ? parseInt(digitsOnly.join(""), 10) / Math.pow(10, 2)
            : undefined;

        return result;
    }
    return 0;
}

export default function formatNumberValue(numberValue) {
    if (numberValue && numberValue !== null) {
        numberValue = parseFloat(numberValue).toFixed(2);
        return new Intl.NumberFormat(["pt-BR"], {
            style: "currency",
            currency: "BRL",
        }).format(unmask(numberValue));
    }
    return "";
}

export function round(numberValue) {
    if (numberValue && numberValue !== null) {
        numberValue = parseFloat(numberValue).toFixed(2);
        return unmask(numberValue);
    }
}
