import React from "react";

import { connect } from "react-redux";

import { useHistory } from "react-router-dom";

import { filterCategory } from "../../actions";

const Header = (props) => {
  let history = useHistory();

  function goBack() {
    props.filterCategory(null);
    history.push("/");
  }
  return (
    <div className="container-header-dark">
      <div className="back-dark" onClick={() => goBack()}>
        <i class="fa fa-chevron-left"></i>
      </div>

      <div className="title-dark">
        {props.checkout ? "Checkout(" : "Sacola("}
        {props.cartItems.reduce((a, { qty }) => a + qty, 0)})
      </div>

      <div className="selectall-dark">
        <div></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  business: state.business.current,
  cartItems: state.cartList.cart,
});

export default connect(mapStateToProps, { filterCategory })(Header);
