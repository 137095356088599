import React, { useEffect } from "react";
import { connect } from "react-redux";

import { filterCategory } from "../../actions";

import "./styles_d.css";

import { getVisibleProducts } from "../../services";

import GridView from "./GridView";

import Header from "./Header";

import FooterDark from "./FooterDark";

import Currency from "../../components/common/currency";
import SearchBar from "../SearchBar";

import { loadBusiness, loadBusinessCustomDomain } from "../../api/products";
import store from "../../store";
import { getAllProducts, getBusiness } from "../../actions";

function Categories(props) {

    useEffect(() => {
        async function init() {
            //window.scrollTo(0, 0);
            document.body.style = "background: #424242;";

            let pathname = props.location.pathname;
            //console.log(pathname);
            let categoryId = pathname.substring(
                pathname.lastIndexOf("/") + 1,
                pathname.length
            );
            console.log("Category Id", categoryId);

            if (props.products && props.products.length > 0) {
                
            } else {
                // url direta
                let host = window.location.host;
                //console.log(host);
                if (host.indexOf(".carty") === -1 && host.indexOf("localhost") === -1) {
                    //console.log("entrou");
                    let business = await loadBusinessCustomDomain(host);
                    store.dispatch(getBusiness(business));
                    store.dispatch(getAllProducts(business));
                } else {
                    let parts = host.split(".");
                    let uid = parts[0];
                    let business = await loadBusiness(uid);
                    store.dispatch(getBusiness(business));
                    store.dispatch(getAllProducts(business));



                    props.filterCategory(categoryId);

                }
          }

        }
        init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const { bannerUri } = Object(props.location.state);

    return (
        <div id="dark-categories-wrap">
            <div className="header-dark">
                <Header openDrawer={false} back={true} isPromo={bannerUri} />
            </div>
            {props.business.freeShipping > 0 && (
                <div className="blank">
                    <div
                        style={{
                            marginTop: 25,
                            paddingTop: 5,
                            width: "100%",
                            backgroundColor: "gray",
                            height: 20,
                            fontSize: 12,
                            textAlign: "center",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <div style={{ marginRight: 5 }}>
                            FRETE GRÁTIS para compras acima de{" "}
                        </div>
                        <Currency value={props.business.freeShipping} />
                    </div>
                </div>
            )}
            {props.business && bannerUri ? (
                <div className="promo-banner">
                    <img
                        src={bannerUri}
                        alt="banner"
                        style={{
                            objectFit: "contain",
                            width: "100%",
                        }}
                    />
                </div>
            ) : (
                <div className="dark-categories-title">{props.selected.category}</div>
            )}

            {props.business.features &&
            props.business.features.includes("searchbar") &&
            (props.business.activePlanName === "Premium" ||
                props.business.activePlanName === "Pro" ||
                props.business.activePlanName === "Diamante") ? (
                <SearchBar />
            ) : undefined}

            <GridView />

            <div className="footer-dark">
                <FooterDark />
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({
    categories: state.category.current,
    selected: state.filters,
    products: getVisibleProducts(state.data, { category: null }, { searchTerm: null }),
    business: state.business.current,
});

export default connect(mapStateToProps, { filterCategory })(Categories);
