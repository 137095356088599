import { SET_COUPON, CLEAR_COUPON } from "../constants/ActionTypes";

const initialState = {
    current: {},
};

const couponReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COUPON:
            return { ...state, current: action.coupon };
        case CLEAR_COUPON:
            return { current: {} };
        default:
            return state;
    }
};
export default couponReducer;
