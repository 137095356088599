import React, { useEffect, useState } from "react";

import { loadAll } from "../api/products";
import useWindowDimensions from "../components/common/dimensions";

function Retails(props) {
    const [businesses, setBusinesses] = useState([]);

    const { width } = useWindowDimensions();
    useEffect(() => {
        async function loadBusiness() {
            let b = await loadAll();

            setBusinesses(
                b.filter(
                    (business) =>
                        business.instagram !== "" &&
                        business.instagram !== null &&
                        business.instagram !== undefined
                )
            );
        }

        loadBusiness();
    }, []);

    function getUri(insta) {
        if (width > 600) {
            return "https://instagram.com/" + insta;
        } else {
            return "instagram://user?username=" + insta;
        }
    }
    return businesses.map((business) => {
        return (
            <div>
                <div>{business.name}</div>
                <div>
                    <a href={getUri(business.instagram)}>{business.instagram}</a>
                </div>
                <br />
            </div>
        );
    });
}
export default Retails;
