import React, { useEffect, useState } from "react";

import Currency from "../../components/common/currency";
import { connect } from "react-redux";
import { getVisibleProducts } from "../../services";
import "./styles_d.css";
import { Link } from "react-router-dom";
import { setLimit } from "../../actions";
import ReactLoading from "react-loading";

function GridView(props) {
    function isLoading() {
        return localStorage.getItem("loaded") !== "true";
    }

    const [loadedProducts, setLoadedProducts] = useState([]);

    useEffect(() => {
        setLoadedProducts(props.products.slice(0, props.limit));
    }, [props.products, props.limit]);

    function loadMore() {
        props.setLimit(props.limit + 20);
    }

    function getPrice(product) {
        return product.variations.length > 0
            ? product.variations[0].price
            : product.price;
    }
    function getPromoPrice(product) {
        return product.variations.length > 0
            ? product.variations[0].promoPrice
            : product.promoPrice;
    }
    return (
        <>
            {isLoading() ? (
                <div className="product-grid-dark">
                    <div className="product-grid-container-dark">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-center",
                                alignItems: "center",
                            }}
                        >
                            <div style={{ color: "white" }}>Carregando produtos</div>
                            <ReactLoading type={"bubbles"} color={"white"} />
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="product-grid-dark">
                        {loadedProducts.map((product, index) => (
                            <div>
                                <div className="product-grid-container-dark">
                                    <Link
                                        to={{
                                            pathname: `${process.env.PUBLIC_URL}/product/${product.id}`,
                                            state: { view: "grid" },
                                        }}
                                    >
                                        <img
                                            src={product.img}
                                            alt={product.name}
                                            style={{
                                                opacity:
                                                    !product.hasStock &&
                                                    props.business.features &&
                                                    props.business.features.includes(
                                                        "stock"
                                                    )
                                                        ? 0.3
                                                        : 1,
                                            }}
                                        />
                                    </Link>
                                    {product.promoAmount === 0 ? (
                                        <></>
                                    ) : (
                                        <div className="product-grid-dark-sale">
                                            {product.promoAmount > 0 &&
                                                product.promoAmount}
                                            {product.promoAmount > 0 && "% OFF"}
                                        </div>
                                    )}
                                </div>
                                <div className="product-grid-dark-title">
                                    {product.name}
                                </div>
                                {(product.variations.length === 0 &&
                                    product.promoPrice) ||
                                (product.variations.length > 0 &&
                                    product.variations[0].promoPrice) ? (
                                    <div className="product-grid-dark-container-price">
                                        <div className="product-grid-dark-price-line">
                                            <Currency value={getPrice(product)} />
                                        </div>
                                        <div className="product-grid-dark-promoprice">
                                            <Currency value={getPromoPrice(product)} />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="product-grid-dark-price">
                                        <Currency value={getPrice(product)} />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    {props.limit < props.products.length && (
                        <div className="load-more-button-dark" onClick={() => loadMore()}>
                            MAIS PRODUTOS
                        </div>
                    )}
                </>
            )}
        </>
    );
}
const mapStateToProps = (state) => ({
    business: state.business.current,
    products: getVisibleProducts(state.data, state.filters, state.searchTerm),
    selected: state.filters,
    limit: state.limit.current,
});

export default connect(mapStateToProps, { setLimit })(GridView);
