import React from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

function CheckoutHeader(props) {
    let history = useHistory();
    function goBack() {
        history.goBack();
    }
    if (props.business.layout === "minimalist") {
        return (
            <div className="checkout-header-minimalist">
                <div className="back-minimalist">
                    <Link
                        style={{ textDecoration: "none" }}
                        to={{
                            pathname: `${process.env.PUBLIC_URL}/`,
                        }}
                        className="secondary"
                    >
                        <img src="/assets/arrow-left.svg" alt="menu"></img>
                    </Link>
                </div>

                <div className="title">
                    {props.checkout ? "CHECKOUT(" : "SACOLA("}
                    {props.cartItems.reduce((a, { qty }) => a + qty, 0)})
                </div>

                <div className="selectall-minimalist">
                    <img src="/assets/blank_image_top.png" alt="selecionar todos"></img>
                </div>
            </div>
        );
    } else if (props.business.layout === "fendi") {
        return (
            <div className="checkout-header-fendi">
                <div className="back-fendi">
                    <Link
                        style={{ textDecoration: "none" }}
                        to={{
                            pathname: `${process.env.PUBLIC_URL}/`,
                        }}
                        className="secondary"
                    >
                        <img src="/assets/arrow-left.svg" alt="menu"></img>
                    </Link>
                </div>

                <div className="title">
                    {props.checkout ? "CHECKOUT(" : "SACOLA("}
                    {props.cartItems.reduce((a, { qty }) => a + qty, 0)})
                </div>

                <div className="selectall-fendi">
                    <img src="/assets/blank_image_top.png" alt="selecionar todos"></img>
                </div>
            </div>
        );
    } else if (props.business.layout === "dark") {
        return (
            <div class="header-dark">
                <div className="container-header-dark">
                    <div className="back-dark" onClick={() => goBack()}>
                        <i class="fa fa-chevron-left"></i>
                    </div>

                    <div className="title-dark">
                        {props.checkout ? "Checkout(" : "Sacola("}
                        {props.cartItems.reduce((a, { qty }) => a + qty, 0)})
                    </div>

                    <div className="selectall-dark">
                        <div></div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div class="checkout-header-food">
                <img
                    src="../assets/blank_image_top.png"
                    alt="carty icon"
                    width="50"
                    height="50"
                />

                <h1>{props.business.name}</h1>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    business: state.business.current,
    cartItems: state.cartList.cart,
});

export default connect(mapStateToProps)(CheckoutHeader);
