import { RECEIVE_CATEGORIES } from "../constants/ActionTypes";

const initialState = {
  current: [],
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_CATEGORIES:
      return { ...state, current: action.categories };

    default:
      return state;
  }
};
export default categoryReducer;
