import React, { useState, useEffect, useCallback } from "react";
import {
    getInstallments,
    getIssuers,
    getPaymentMethods,
    initMercadoPago,
} from "@mercadopago/sdk-react";
import { createCardToken } from "@mercadopago/sdk-react/coreMethods";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { getCartTotal, getCouponAmount } from "../services";
import Currency from "../components/common/currency";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputMask from "react-input-mask";
import shop from "../api/shop";
import hash from "object-hash";

import { makeStyles } from "@material-ui/core/styles";
import {
    clearCart,
    setShipping,
    clearCoupon,
    setInstallment,
    clearInstallment,
} from "../actions";
import service from "../api/service";
import CheckoutHeader from "./CheckoutHeader";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import "./styles_checkout.css";

import InputLabel from "@material-ui/core/InputLabel";

import FormControl from "@material-ui/core/FormControl";
import Shipping2 from "./Shipping2";
import NativeSelect from "@material-ui/core/NativeSelect";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Paper from "@material-ui/core/Paper";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import formatNumberValue from "../components/common/currency-method";
import { removeFromCart, decrementQty, updateCart } from "../actions";
import { validateOrder, getErrorDescription } from "../components/common/orderValidation";
function CheckoutPage(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    function isShippingSelected() {
        return isShipping() && delivery === "delivery";
    }
    function hasPlan() {
        return (
            props.business.activePlanName === "Pro" ||
            props.business.activePlanName === "Premium" ||
            props.business.activePlanName === "Diamante"
        );
    }
    function isShipping() {
        return (
            (hasPlan() &&
                props.business.delivery &&
                props.business.delivery.includes("shipping")) ||
            (props.business.activePlanName === "Diamante" &&
                props.business.features &&
                props.business.features.includes("me"))
        );
    }
    function isLocalDelivery() {
        return (
            props.business.delivery && props.business.delivery.includes("localdelivery")
        );
    }
    let isOnsiteCheckoutMP = useCallback(() => {
        return (
            (props.business.activePlanName === "Pro" ||
                props.business.activePlanName === "Diamante") &&
            props.business.features &&
            props.business.features.includes("onsite-checkout-mp")
        );
    }, [props.business.activePlanName, props.business.features]);

    let isStockActivate = () => {
        return (
            (props.business.activePlanName === "Pro" ||
                props.business.activePlanName === "Diamante" ||
                props.business.activePlanName === "Premium") &&
            props.business.features &&
            props.business.features.includes("stock")
        );
    };

    useEffect(() => {
        if (isOnsiteCheckoutMP()) {
            initMercadoPago(props.business.paymentPublicKey);
            setEnvironment(
                props.business.paymentPublicKey.indexOf("TEST") === -1
                    ? "production"
                    : "sandbox"
            );
        }
    }, [
        isOnsiteCheckoutMP,
        props.business.activePlanName,
        props.business.paymentPublicKey,
    ]);

    useEffect(() => {
        document.body.style = "background: #FFFFFF;";
        setTotal(props.total);
    }, [props.total]);

    const useStyles = makeStyles((theme) => ({
        root: { padding: 5 },
        label: {
            fontSize: 14,
            marginLeft: 10,
        },
        loading: {
            width: "100%",
            "& > * + *": {
                marginTop: theme.spacing(2),
            },
        },
        formControl: {
            width: "100%",
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        disabled: {},
        inputRoot: {
            "&$disabled": {
                color: "black",
            },
        },
    }));

    const classes = useStyles();

    const [name, setName] = useState("");
    const [cellphone, setCellphone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [addressNumber, setAddressNumber] = useState("");
    const [addressNeighborhood, setAddressNeighborhood] = useState("");
    const [addressCity, setAddressCity] = useState("");
    const [addressState, setAddressState] = useState("");

    const [payment, setPayment] = useState("");
    const [delivery, setDelivery] = useState("");
    const [zipCode, setZipCode] = useState("");

    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const [note, setNote] = useState();

    const [searchByZipCode, setSearchByZipCode] = useState("");

    const [cardNumber, setCardNumber] = useState("");
    const [cardholderName, setCardholderName] = useState("");
    const [cardExpiration, setCardExpiration] = useState("");
    const [securityCode, setSecurityCode] = useState("");
    const [identificationNumber, setIdentificationNumber] = useState("");
    const [installments, setInstallments] = useState();
    const [openSandbox, setOpenSandbox] = useState();
    const [environmnet, setEnvironment] = useState("sandbox");
    const [message, setMessage] = useState("");
    const [cardImage, setCardImage] = useState("");
    const [generalZipCode, setGeneralZipCode] = useState(false);
    const [outOfStockMessage, setOutOfStockMessage] = useState();
    const [openOutOfStockDialog, setOpenOutOfStockDialog] = useState(false);
    const [openEmptyCart, setOpenEmptyCart] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [showShippingMethod, setShowShippingMethod] = useState(false);

    function getErrorMessage(field) {
        if (formErrors.includes(field)) {
            return getErrorDescription(field);
        }
        return "";
    }

    function clearErrorMessage(field) {
        setFormErrors(formErrors.filter((f) => f !== field));
    }

    const [isEligibleFreeShipping] = useState(
        props.business.freeShipping && props.business.freeShipping > 0
    );

    useEffect(() => {
        async function fetchInstallments(bin) {
            try {
                const inst = await getInstallments({
                    amount: total + "",
                    locale: "pt-BR",
                    bin: bin,
                });
                //console.log(inst);
                if (inst && inst.length > 0) {
                    setCardImage(inst[0].issuer.secure_thumbnail);
                    setInstallments(inst[0].payer_costs);
                }
            } catch (e) {
                if (e.status === 404) {
                    //setErrorCardNumberMessage("Digite um número de cartão válido.");
                }
            }
        }

        if (cardNumber) {
            let cardNumberNoSpace = cardNumber.replace(/[^0-9]+/g, "");
            if (cardNumberNoSpace.length === 16) {
                let bin = cardNumberNoSpace.substring(0, 6);
                try {
                    fetchInstallments(bin);
                } catch (e) {
                    console.log(e);
                    console.log("caiu no catch");
                }
            }
        }
    }, [cardNumber, total]);

    useEffect(() => {
        console.log("chamou o effect");
        console.log(props.shipping.error);
        if (props.shipping && props.shipping.error) {
            clearCEPFields();
        }
        if (props.shipping && props.shipping.destinationZipCode && zipCode === "") {
            setZipCode(props.shipping.destinationZipCode);
            searchZipCode(props.shipping.destinationZipCode);
            setDelivery("delivery");
        }
        if (props.shipping && props.shipping.method) {
            clearErrorMessage("shippingMethod");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.shipping]);

    useEffect(() => {
        //subtotal
        let total = 0;
        for (var i = 0; i < props.cartItems.length; i++) {
            total +=
                parseFloat(props.cartItems[i].qty) *
                parseFloat(
                    props.cartItems[i].promoPrice || props.cartItems[i].price || 0
                );
        }

        if (props.coupon && props.coupon.amount && props.coupon.amount > 0) {
            let couponAmount = total * (props.coupon.amount / 100);
            total = total - couponAmount;
        }

        if (props.shipping && props.shipping.total > 0) {
            total = parseFloat(props.shipping.total + total).toFixed(2);
        }

        total = parseFloat(total).toFixed(2);
        setTotal(total);
    }, [props.cartItems, props.coupon, props.shipping]);

    async function checkForm(data) {
        let errorFields = await validateOrder(data);

        setFormErrors(errorFields);

        if (errorFields.length > 0) {
            setMessage("Revise os dados do formulário.");
        }
        return errorFields.length === 0;
    }
    function clearCEPFields() {
        setAddress("");
        setAddressNeighborhood("");
        setAddressState("");
        setAddressCity("");
        props.setShipping({});
        setSearchByZipCode("");
        setGeneralZipCode(false);
        setZipCode("");
        setAddressNumber("");
        //setShowShippingMethod(false);
        clearErrorMessage("shippingMethod");
    }
    async function searchZipCode(typed) {
        var numberPattern = /\d+/g;
        let zip = typed && typed !== null && typed.match(numberPattern);
        if (zip) {
            zip = zip.join("");
            if (zip.length < 8) {
                clearCEPFields();
            } else if (zip.length === 8) {
                let zipCode = zip.replace("-", "");
                console.log(zipCode);
                service.get(`/shipping/address/${zipCode}`).then(async (response) => {
                    response = response.data;
                    if (response && response.result && response.result.uf) {
                        setAddress(response.result.logradouro);
                        setAddressNeighborhood(response.result.bairro);
                        setAddressState(response.result.uf);
                        setAddressCity(response.result.localidade);
                        //setErrorAddressMessage("");
                        setGeneralZipCode(
                            zipCode.substring(zipCode.length - 3) === "000"
                        );

                        setSearchByZipCode(zipCode);
                        setShowShippingMethod(true);
                    } else {
                        setMessage("CEP não encontrado.");
                    }
                });
            }
        }
        setZipCode(typed);
    }

    async function checkDelivery(value) {
        clearErrorMessage("delivery");
        props.setShipping({});

        if (value === "delivery") {
            let totalCalc = !isFreeShipping() ? Number(props.business.deliveryTax) : 0;
            props.setShipping({ total: totalCalc });
        } else {
            //setTotal(Number(props.total));
            clearCEPFields();
            setSearchByZipCode(null);
        }

        setDelivery(value);
    }
    function getAdress() {
        if (address !== "") {
            return `${address} ${addressNumber} , ${addressNeighborhood} - ${addressCity}/${addressState} CEP: ${zipCode} `;
        } else {
            return address;
        }
    }

    function getShipping() {
        if (
            props.shipping &&
            props.shipping.method &&
            props.shipping.total &&
            props.shipping.total > 0
        ) {
            return `${props.shipping.method} : ${formatNumberValue(
                props.shipping.total
            )} - ${props.shipping.days}`;
        } else {
            return null || "";
        }
    }

    async function checkStock() {
        //let products = await shop.getProducts(props.business.uid);
        let checked = true;
        let messages = [];
        for (var i = 0; i < props.cartItems.length; i++) {
            let cartProduct = props.cartItems[i];
            let existing = await shop.getProduct(cartProduct.id);
            props.updateCart(existing);
            //console.log(existing);
            if (existing.quantity < cartProduct.qty) {
                if (existing.quantity > 0) {
                    let diff = cartProduct.qty - existing.quantity;
                    props.decrementQty(existing.id, diff);
                    messages.push(
                        `O produto ${existing.name} possui apenas ${existing.quantity} unidade(s) no estoque. Seu pedido foi atualizado.`
                    );
                } else {
                    props.removeFromCart(existing);
                    messages.push(
                        `O produto ${existing.name} está indisponível no momento. Esse item foi retirado do pedido.`
                    );
                }
                checked = false;
            }
        }
        setOutOfStockMessage(messages);
        return { checked, messages };
    }

    async function generateHashOrder() {
        let content = {
            name: name,
            email: email,
            address: getAdress(),
            cellphone: cellphone,
            business: props.business._id,
            payment: payment,
            delivery: delivery,
            subTotal: props.subtotal || 0,
            cartItems: props.cartItems
                .map((product) => {
                    return product.id + product.qty;
                })
                .join(),
            note: note,
            orderTotal: total,
        };
        return hash(content);
    }

    async function save() {
        if (!loading) {
            setLoading(true);
            if (isStockActivate()) {
                let toCheck = await checkStock();
                if (!toCheck.checked) {
                    setOpenOutOfStockDialog(true);
                } else {
                    doIt();
                }
            } else {
                doIt();
            }
        } else {
            console.log("duplo clique detectado");
        }
    }

    async function hasNoOrder(hash) {
        let existingOrder = await service.get(`/order/hash/${hash}`);
        if (existingOrder.data.order === null || existingOrder.data.order === undefined) {
            return true;
        } else {
            //check order timestamp
            let orderTimestamp = existingOrder.data.order.createdAt;
            let diffInSeconds = (new Date() - moment(orderTimestamp).toDate()) / 1000;
            //console.log(`segundos ${diffInSeconds}`);
            if (diffInSeconds < 30) {
                return false;
            } else {
                return true;
            }
        }
    }

    async function doIt() {
        let data = await generateOrder();
        if (await checkForm(data)) {
            let hashOrder = await generateHashOrder();
            if (await hasNoOrder(hashOrder)) {
                const order = await createAndSaveOrder(data);
                if (order) {
                    callSuccess(order);
                }
                setLoading(false);
            } else {
                console.log("a ordem já existe");
            }
        }
        setLoading(false);
    }

    async function generateCardToken(
        cardNumberNoSpace,
        cardExpirationMonth,
        cardExpirationYear,
        idNumber
    ) {
        const cardToken = await createCardToken({
            cardNumber: cardNumberNoSpace,
            cardholderName,
            cardExpirationMonth,
            cardExpirationYear,
            securityCode,
            identificationType: "CPF",
            identificationNumber: idNumber,
        });
        return cardToken;
    }

    function getInstallmentsMessage() {
        if (installments && props.installment && props.installment.installments > 1) {
            return props.installment.recommended_message;
        }
    }
    function getInstallmentRate() {
        if (installments && props.installment && props.installment.installments > 1) {
            let rate =
                (total || props.total) * (props.installment.installment_rate / 100);
            return rate;
        } else {
            return 0;
        }
    }

    function callSuccess(order) {
        let orderNumber = order.orderNumber;
        props.clearCart();
        props.clearCoupon();
        props.clearInstallment();
        props.history.push({
            pathname: "/order",
            state: {
                items: props.cartItems,
                orderTotal: total,
                deliveryTax: getShippingDeliveryTax(),
                name: name,
                cellphone: cellphone,
                address: getAdress(),
                email: email,
                payment: payment,
                delivery: delivery,
                storeaddress: props.business.address,
                bankAccount: props.business.bankAccount,
                orderNumber: orderNumber,
                whatsapp: props.business.whatsappNumber,
                coupon: props.coupon,
                couponAmount: props.couponAmount,
                subTotal: props.subtotal,
                note: note,
                shippingDesc: getShipping(),
                shippingFee: props.shipping.total,
                cardLastDigits: getLastDigitsCreditNumber(),
                cardImage: cardImage,
                installmentsMessage: getInstallmentsMessage(),
                installmentRate: getInstallmentRate(),
                slip_url: order.slipUri,
                qrcode: order.qrcode,
                expirationDatePix: order.expirationDatePix,
                initPoint: order.initPoint,
            },
        });
    }

    function isFreeShipping() {
        return isEligibleFreeShipping && props.subtotal > props.business.freeShipping;
    }
    function getName(item) {
        let name = item.name;
        if (item.variationValue) {
            name = name + " - " + item.variationValue;
        }
        return name;
    }
    function getShippingDeliveryTax() {
        if (props.shipping && props.shipping.total) {
            return props.shipping.total;
        }
    }

    function selectInstallment(installmentsQuantity) {
        if (installments && installmentsQuantity) {
            //console.log(installments);
            let filtered = installments.filter(
                (i) => i.installments === Number(installmentsQuantity)
            );

            props.setInstallment(filtered[0]);
        }
    }

    function renderButtons() {
        if (props.business.layout === "minimalist") {
            return (
                <div class="checkout-button-minimalist">
                    {!loading ? (
                        <div className="enabled" onClick={() => save()}>
                            FINALIZAR
                        </div>
                    ) : (
                        <div className="disabled">FINALIZAR</div>
                    )}
                </div>
            );
        } else if (props.business.layout === "dark") {
            return (
                <div class="checkout-button-dark">
                    {!loading ? (
                        <div className="enabled" onClick={() => save()}>
                            FINALIZAR
                        </div>
                    ) : (
                        <div className="disabled">FINALIZAR</div>
                    )}
                </div>
            );
        } else {
            return (
                <div class="checkout-button-food">
                    <Link to={`${process.env.PUBLIC_URL}/cart`} class="secondary">
                        VOLTAR
                    </Link>
                    {!loading ? (
                        <Button
                            onClick={() => save()}
                            size="small"
                            color="inherit"
                            class="primary"
                        >
                            FECHAR PEDIDO
                        </Button>
                    ) : (
                        <Button size="small" color="inherit" class="disabled">
                            FECHAR PEDIDO
                        </Button>
                    )}
                </div>
            );
        }
    }
    async function getCreditCardFields() {
        return {
            cardNumber,
            cardholderName,
            securityCode,
            installments: props.installment.installments,
            cardExpiration,
        };
    }
    async function getCreditCard() {
        console.log("gerando o token");
        try {
            if (isOnsiteCheckoutMP() && payment === "credit-card") {
                let cardNumberNoSpace = cardNumber.replace(/\s+/g, "");
                let bin = cardNumberNoSpace.substring(0, 6);
                let cardExpirationMonth = cardExpiration.split("/")[0];
                let cardExpirationYear = cardExpiration.split("/")[1];
                let idNumber = identificationNumber.replace(/[^0-9]+/g, "");

                const paymentMethods = await getPaymentMethods({ bin: bin });

                const issuers = await getIssuers({
                    paymentMethodId: paymentMethods.results[0].id,
                    bin: bin,
                });
                let issuerId = issuers[0].id;

                const cardToken = await generateCardToken(
                    cardNumberNoSpace,
                    cardExpirationMonth,
                    cardExpirationYear,
                    idNumber
                );

                return {
                    token: cardToken,
                    issuerId,
                    installments: props.installment
                        ? Number(props.installment.installments)
                        : 0,
                    cardLastDigits: getLastDigitsCreditNumber(),
                    cardImage: cardImage,
                    installmentsMessage: getInstallmentsMessage(),
                    installmentRate: getInstallmentRate(),
                    paymentMethodId: paymentMethods.results[0].id,
                };
            } else {
                return null;
            }
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async function callApiWithRetry(data, maxRetries = 3, retryInterval = 500) {
        let retries = 0;
        let error = "";
        while (retries < maxRetries) {
            try {
                const response = await service.post("order/generate", data);

                if (response.data.success) {
                    return response.data; // API call succeeded, return the data
                } else {
                    console.log(response.data.message);
                    if (retries === 0) {
                        error = response.data.message;
                    }
                    if (response.data.message.indexOf("retry") === -1) {
                        //will not retry
                        retries = 2;
                    }
                    if (response.data.message === "Insufficient stock") {
                        throw new Error("Produto fora de estoque.");
                    } else {
                        console.log(
                            `API call failed. Retrying (attempt ${
                                retries + 1
                            }/${maxRetries})...`
                        );
                        retries++;
                        await new Promise((resolve) =>
                            setTimeout(resolve, retryInterval)
                        ); // Wait before retrying
                    }
                }
            } catch (error) {
                console.error(`API call error: ${error.message}`);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryInterval)); // Wait before retrying
            }
        }

        throw new Error(error);
    }

    async function generateOrder() {
        let hashOrder = await generateHashOrder();
        console.log(props.shipping);
        let order = {
            payment: payment,
            delivery: delivery,
            subTotal: props.subtotal || 0,
            hash: hashOrder,
            orderTotal: total + getInstallmentRate(),
            coupon: props.coupon ? props.coupon._id : null,
            note: note,
            deliveryTax: getShippingDeliveryTax(),
            shippingFee: delivery === "delivery" ? props.shipping.total : 0,
            shippingMethod: delivery === "delivery" ? props.shipping.method : "",
            shippingZipCode:
                delivery === "delivery" ? props.shipping.destinationZipCode : "",
            shippingDays: delivery === "delivery" ? props.shipping.days : "",

            shippingService: props.shipping.id ? props.shipping.id : null,
            identificationNumber: identificationNumber,
        };

        const items = props.cartItems.map((product) => {
            // console.log(product);
            let selection = {
                _id: product.id,
                quantity: product.qty,
                productName: getName(product),
                productPrice: product.promoPrice || product.price || 0,
            };
            return selection;
        });

        const client = {
            name: name,
            email: email,
            fullAddress: getAdress(),
            address: address,
            number: addressNumber,
            neighborhood: addressNeighborhood,
            city: addressCity,
            state: addressState,
            zipCode: zipCode,
            cellphone: cellphone,
            business: props.business._id,
            identificationNumber: identificationNumber,
        };

        const card = await getCreditCardFields();

        const data = {
            business: {
                _id: props.business._id,
                isFreeShipping: isFreeShipping(),
                isLocalDelivery: isLocalDelivery(),
                isShippingActivated: isShipping(),
                isOnsiteCheckoutMP: isOnsiteCheckoutMP(),
            },
            client,
            items,
            order,
            card,
        };
        console.log(data);

        return data;
    }

    async function createAndSaveOrder(data) {
        try {
            if (data.order.payment === "credit-card" && isOnsiteCheckoutMP()) {
                data = { ...data, card: await getCreditCard() };
            }
            console.log(data);
            const response = await callApiWithRetry(data);
            if (response) {
                return response.order;
            }
        } catch (e) {
            setMessage(e.message);
            setLoading(false);
        }
    }

    function getLastDigitsCreditNumber() {
        return cardNumber && "FINAL " + cardNumber.split(" ").pop();
    }
    const handleClose = () => {
        setOpenOutOfStockDialog(false);
    };

    const handleCloseMessage = () => {
        setMessage("");
    };

    function checkCartItems() {
        if (props.cartItems.length === 0) {
            setOpenEmptyCart(true);
        } else {
            setOpenOutOfStockDialog(false);
            props.history.push({
                pathname: "/cart",
            });
        }
    }
    function toHome() {
        props.history.push({
            pathname: "/",
        });
    }

    return (
        <div id="checkout-wrap">
            <div>
                <Backdrop
                    className={classes.backdrop}
                    open={loading}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar
                    open={message !== ""}
                    //onClose={handleClose}
                    message={message}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    autoHideDuration={7000}
                    onClose={handleCloseMessage}
                />

                <Dialog
                    open={openOutOfStockDialog}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Alguém comprou esse produto."}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Paper variant="outlined" square>
                                <div style={{ margin: 10 }}>
                                    <div>{outOfStockMessage}</div>
                                </div>
                            </Paper>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => checkCartItems()} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openEmptyCart}
                    keepMounted
                    onClose={() => setOpenEmptyCart(false)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Carrinho vazio."}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Paper variant="outlined" square>
                                <div style={{ margin: 10 }}>
                                    <div>
                                        Não existem produtos no seu carrinho. Você será
                                        redirecionado para nossa loja.
                                    </div>
                                </div>
                            </Paper>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => toHome()} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openSandbox}
                    keepMounted
                    onClose={() => setOpenSandbox(false)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"AMBIENTE DE TESTE"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Paper variant="outlined" square>
                                <div style={{ margin: 10 }}>
                                    Esse é um ambiente de teste. O pedido não irá gerar
                                    uma cobrança no cartão de crédito informado. Para
                                    habilitar o ambiente de produção, insira as
                                    credenciais corretas do Mercado Pago no app Carty.
                                </div>
                            </Paper>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenSandbox(false)} color="primary">
                            Entendi
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div
                className={
                    props.business.layout === "minimalist" ||
                    props.business.layout === "dark" ||
                    props.business.layout === "fendi"
                        ? "checkout-header"
                        : "checkout-header-2"
                }
            >
                <CheckoutHeader checkout={true} />
            </div>

            <div class="checkout-client-data">
                <div
                    className={
                        props.business.layout === "minimalist" ||
                        props.business.layout === "dark" ||
                        props.business.layout === "fendi"
                            ? "marginTopMinimalist"
                            : "marginTopFood"
                    }
                ></div>
                <div class="title">DADOS DO CLIENTE</div>
                <div class="data">
                    <TextField
                        id="outlined-basic"
                        label="Nome:"
                        variant="standard"
                        size="small"
                        fullWidth
                        style={{ marginBottom: 20 }}
                        required
                        value={name}
                        error={getErrorMessage("name") !== ""}
                        helperText={getErrorMessage("name")}
                        onChange={(e) => {
                            //setErrorNameMessage("");
                            clearErrorMessage("name");
                            setName(e.target.value);
                        }}
                    />

                    <InputMask
                        mask="(99) 99999-9999"
                        value={cellphone}
                        onChange={(e) => {
                            clearErrorMessage("cellphone");
                            setCellphone(e.target.value);
                        }}
                    >
                        {() => (
                            <TextField
                                id="outlined-basic"
                                label="Celular ( Whatsapp ):"
                                variant="standard"
                                size="small"
                                fullWidth
                                type="tel"
                                required
                                error={getErrorMessage("cellphone") !== ""}
                                helperText={getErrorMessage("cellphone")}
                                style={{ marginBottom: 20 }}
                            />
                        )}
                    </InputMask>
                    {isOnsiteCheckoutMP() ? (
                        <TextField
                            id="outlined-basic"
                            label="Email:"
                            variant="standard"
                            size="small"
                            type="email"
                            fullWidth
                            style={{ marginBottom: 20 }}
                            value={email}
                            error={getErrorMessage("email") !== ""}
                            helperText={getErrorMessage("email")}
                            onChange={(e) => {
                                clearErrorMessage("email");
                                setEmail(e.target.value);
                            }}
                            required
                        />
                    ) : (
                        <TextField
                            id="outlined-basic"
                            label="Email ( OPCIONAL ) :"
                            variant="standard"
                            size="small"
                            type="email"
                            fullWidth
                            style={{ marginBottom: 20 }}
                            value={email}
                            error={getErrorMessage("email") !== ""}
                            helperText={getErrorMessage("email")}
                            onChange={(e) => {
                                clearErrorMessage("email");
                                setEmail(e.target.value);
                            }}
                        />
                    )}

                    <InputMask
                        mask="999.999.999-99"
                        value={identificationNumber}
                        onChange={(e) => {
                            clearErrorMessage("cpf");
                            setIdentificationNumber(e.target.value);
                        }}
                    >
                        {() => (
                            <TextField
                                label="CPF:"
                                variant="standard"
                                size="small"
                                fullWidth
                                style={{ marginBottom: 40 }}
                                required
                                type="tel"
                                error={getErrorMessage("cpf") !== ""}
                                helperText={getErrorMessage("cpf")}
                            />
                        )}
                    </InputMask>

                    <div class="checkout-delivery">
                        <div class="title">FORMA DE ENTREGA</div>
                        <div class="options">
                            <RadioGroup aria-label="delivery" name="delivery-method">
                                {props.business.delivery.indexOf("delivery") !== -1 ||
                                isLocalDelivery() ||
                                isShipping() ? (
                                    <FormControlLabel
                                        value="delivery"
                                        control={
                                            <Radio
                                                icon={
                                                    <RadioButtonUncheckedIcon
                                                        style={{ fontSize: 17 }}
                                                    />
                                                }
                                                checkedIcon={
                                                    <RadioButtonCheckedIcon
                                                        style={{ fontSize: 17 }}
                                                    />
                                                }
                                                classes={{
                                                    root: classes.root,
                                                }}
                                                //checked={
                                                //  delivery === "delivery" ||
                                                //  (props.business.delivery.indexOf("delivery") !== -1 &&
                                                //    props.business.delivery.length === 1)
                                                //}
                                                onClick={() => checkDelivery("delivery")}
                                            />
                                        }
                                        label="Entrega"
                                        classes={{
                                            label: classes.label,
                                        }}
                                    />
                                ) : undefined}

                                {props.business.delivery.indexOf("takeout") !== -1 && (
                                    <FormControlLabel
                                        value="takeout"
                                        control={
                                            <Radio
                                                icon={
                                                    <RadioButtonUncheckedIcon
                                                        style={{ fontSize: 17 }}
                                                    />
                                                }
                                                checkedIcon={
                                                    <RadioButtonCheckedIcon
                                                        style={{ fontSize: 17 }}
                                                    />
                                                }
                                                classes={{
                                                    root: classes.root,
                                                }}
                                                //checked={
                                                //  delivery === "takeout" ||
                                                //  (props.business.delivery.indexOf("takeout") !== -1 &&
                                                //    props.business.delivery.length === 1)
                                                // }
                                                onClick={() => checkDelivery("takeout")}
                                            />
                                        }
                                        label="Retirar no Local"
                                        classes={{
                                            label: classes.label,
                                        }}
                                    />
                                )}
                            </RadioGroup>
                        </div>
                        {getErrorMessage("delivery") !== "" && (
                            <div
                                style={{
                                    marginTop: 3,
                                    marginLeft: 20,
                                    fontSize: "0.75rem",
                                    color: "#f44336",
                                }}
                            >
                                {getErrorMessage("delivery")}
                            </div>
                        )}
                    </div>
                    {delivery === "delivery" ? (
                        <div style={{ marginTop: 30 }}>
                            <div class="title">ENDEREÇO DE ENTREGA</div>
                            <div class="data">
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "flex-end",
                                    }}
                                >
                                    <InputMask
                                        mask="99999-999"
                                        value={zipCode}
                                        onChange={(e) => {
                                            clearErrorMessage("zipcode");

                                            //console.log(e.target.value);

                                            searchZipCode(e.target.value);
                                        }}
                                        //onBlur={(e) => searchZipCode(e.target.value)}
                                    >
                                        {() => (
                                            <TextField
                                                id="outlined-basic"
                                                label="CEP:"
                                                variant="standard"
                                                size="small"
                                                error={getErrorMessage("zipcode") !== ""}
                                                helperText={getErrorMessage("zipcode")}
                                                required
                                                fullWidth
                                                shrink={false}
                                                style={{ marginBottom: 20, width: "48%" }}
                                            />
                                        )}
                                    </InputMask>
                                    <div
                                        style={{
                                            color: "blue",
                                            textDecoration: "underline",
                                            marginBottom: 18,
                                            marginLeft: 30,
                                            fontSize: 15,
                                            cursor: "pointer",
                                        }}
                                    >
                                        <div
                                            onClick={() => {
                                                clearCEPFields();
                                            }}
                                        >
                                            Não sei meu CEP
                                        </div>
                                    </div>
                                </div>

                                <TextField
                                    id="outlined-basic"
                                    label="Rua / Avenida:"
                                    variant="standard"
                                    size="small"
                                    fullWidth
                                    disabled={!generalZipCode}
                                    InputProps={{
                                        classes: {
                                            root: classes.inputRoot,
                                            disabled: classes.disabled,
                                        },
                                    }}
                                    style={{ marginBottom: 20 }}
                                    value={address}
                                    error={getErrorMessage("address") !== ""}
                                    helperText={getErrorMessage("address")}
                                    required
                                    onChange={(e) => {
                                        clearErrorMessage("address");
                                        setAddress(e.target.value);
                                    }}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <TextField
                                        id="outlined-basic"
                                        label="Número:"
                                        required
                                        variant="standard"
                                        size="small"
                                        style={{ marginBottom: 20, width: "48%" }}
                                        value={addressNumber}
                                        error={getErrorMessage("addressNumber") !== ""}
                                        helperText={getErrorMessage("addressNumber")}
                                        onChange={(e) => {
                                            clearErrorMessage("addressNumber");
                                            setAddressNumber(e.target.value);
                                        }}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        label="Bairro:"
                                        variant="standard"
                                        size="small"
                                        style={{ marginBottom: 20, width: "48%" }}
                                        value={addressNeighborhood}
                                        disabled={!generalZipCode}
                                        InputProps={{
                                            classes: {
                                                root: classes.inputRoot,
                                                disabled: classes.disabled,
                                            },
                                        }}
                                        onChange={(e) => {
                                            setAddressNeighborhood(e.target.value);
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <TextField
                                        id="outlined-basic"
                                        label="Cidade:"
                                        variant="standard"
                                        size="small"
                                        style={{ marginBottom: 20, width: "48%" }}
                                        value={addressCity}
                                        error={getErrorMessage("addressCity") !== ""}
                                        helperText={getErrorMessage("addressCity")}
                                        required
                                        disabled
                                        InputProps={{
                                            classes: {
                                                root: classes.inputRoot,
                                                disabled: classes.disabled,
                                            },
                                        }}
                                        onChange={(e) => {
                                            clearErrorMessage("addressCity");
                                            setAddressCity(e.target.value);
                                        }}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        label="Estado:"
                                        variant="standard"
                                        size="small"
                                        style={{ marginBottom: 20, width: "48%" }}
                                        value={addressState}
                                        disabled
                                        error={getErrorMessage("addressState") !== ""}
                                        helperText={getErrorMessage("addressState")}
                                        required
                                        InputProps={{
                                            classes: {
                                                root: classes.inputRoot,
                                                disabled: classes.disabled,
                                            },
                                        }}
                                        onChange={(e) => {
                                            clearErrorMessage("addressState");
                                            setAddressState(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : undefined}

                    {showShippingMethod && (
                        <div
                            style={{
                                marginTop: 20,
                                color:
                                    getErrorMessage("shippingMethod") !== "" ? "red" : "",
                            }}
                            class="title"
                        >
                            MÉTODO DE ENVIO
                        </div>
                    )}

                    {getErrorMessage("shippingMethod") !== "" && (
                        <div
                            style={{
                                marginTop: 3,
                                fontSize: "0.75rem",
                                color: "#f44336",
                            }}
                        >
                            {getErrorMessage("shippingMethod")}
                        </div>
                    )}
                    <div
                        style={{
                            marginBottom: 0,
                            paddingLeft: 23,
                        }}
                    >
                        {showShippingMethod && (
                            <Shipping2 searchBar={false} searchBy={searchByZipCode} />
                        )}
                    </div>
                </div>
            </div>

            <div class="checkout-sumary">
                <div class="title">RESUMO DO PEDIDO</div>

                <div class="Rtable Rtable--3cols">
                    <div class="Rtable-cell Rtable-header">PRODUTO</div>
                    <div class="Rtable-cell Rtable-header">QTD</div>
                    <div class="Rtable-cell Rtable-header">PREÇO</div>
                    {props.cartItems.map((item) => {
                        return (
                            <>
                                <div class="Rtable-cell Rtable-content">
                                    {item.name}
                                    {item.variationValue
                                        ? " - " + item.variationValue
                                        : ""}
                                </div>
                                <div class="Rtable-cell Rtable-content">{item.qty}</div>
                                <div class="Rtable-cell Rtable-content">
                                    <Currency value={item.promoPrice || item.price} />
                                </div>
                            </>
                        );
                    })}

                    {props.coupon && props.couponAmount ? (
                        <>
                            <div class="Rtable-footer Rtable-header">SUBTOTAL</div>
                            <div class="Rtable-footer"></div>
                            <div class="Rtable-footer Rtable-total">
                                <Currency value={props.subtotal} />
                            </div>
                            <div class="Rtable-footer Rtable-header">
                                CUPOM({props.coupon.amount}%)
                            </div>
                            <div class="Rtable-footer"></div>
                            <div class="Rtable-footer Rtable-total">
                                <Currency value={props.couponAmount} />
                            </div>
                        </>
                    ) : undefined}
                    {delivery === "delivery" ||
                    delivery === "localdelivery" ||
                    (isShippingSelected() && props.shipping) ? (
                        <>
                            <div class="Rtable-footer Rtable-header">TX ENTREGA</div>
                            <div class="Rtable-footer"></div>
                            <div class="Rtable-footer Rtable-total">
                                {isFreeShipping() ? (
                                    <div>FRETE GRÁTIS</div>
                                ) : (
                                    <Currency value={getShippingDeliveryTax()} />
                                )}
                            </div>
                        </>
                    ) : undefined}
                    {props.installment && props.installment.installments > 1 ? (
                        <>
                            <div class="Rtable-footer Rtable-header">JUROS</div>
                            <div class="Rtable-footer"></div>
                            <div class="Rtable-footer Rtable-total">
                                <Currency value={getInstallmentRate()} />
                            </div>
                        </>
                    ) : undefined}
                    <div class="Rtable-footer Rtable-header">TOTAL</div>
                    <div class="Rtable-footer"></div>
                    <div class="Rtable-footer Rtable-total">
                        <Currency value={total + getInstallmentRate()} />
                    </div>
                </div>
            </div>

            <div class="checkout-payment">
                <div class="title">FORMA DE PAGAMENTO</div>
                {isOnsiteCheckoutMP() && (
                    <div>
                        <img
                            style={{ width: 200 }}
                            alt="mercado pago"
                            src="https://res.cloudinary.com/rockedevelopr/image/upload/v1664550667/carty/mercado-pago-logo_kiwjq4.png"
                        />
                    </div>
                )}
                <div class="options">
                    <RadioGroup aria-label="payment" name="payment-method">
                        {props.business.payment === null || props.business.payment === ""
                            ? "Configure a forma de pagamento no aplicativo"
                            : undefined}
                        {props.business.payment &&
                        props.business.payment.includes("money-order") ? (
                            <FormControlLabel
                                value="money-order"
                                control={
                                    <Radio
                                        icon={
                                            <RadioButtonUncheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        checkedIcon={
                                            <RadioButtonCheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        classes={{
                                            root: classes.root,
                                        }}
                                        onClick={() => {
                                            setPayment("money-order");
                                            clearErrorMessage("payment");
                                        }}
                                    />
                                }
                                label="PIX"
                                classes={{
                                    label: classes.label,
                                }}
                            />
                        ) : undefined}

                        {props.business.payment &&
                        props.business.payment.includes("credit-card") ? (
                            <FormControlLabel
                                value="credit-card"
                                control={
                                    <Radio
                                        icon={
                                            <RadioButtonUncheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        checkedIcon={
                                            <RadioButtonCheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        classes={{
                                            root: classes.root,
                                        }}
                                        onClick={() => {
                                            if (
                                                environmnet === "sandbox" &&
                                                isOnsiteCheckoutMP()
                                            ) {
                                                setOpenSandbox(true);
                                            }
                                            setPayment("credit-card");
                                            clearErrorMessage("payment");
                                        }}
                                    />
                                }
                                label="Cartão de Crédito"
                                classes={{
                                    label: classes.label,
                                }}
                            />
                        ) : undefined}
                        {props.business.payment &&
                        props.business.payment.includes("debit-card") ? (
                            <FormControlLabel
                                value="debit-card"
                                control={
                                    <Radio
                                        icon={
                                            <RadioButtonUncheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        checkedIcon={
                                            <RadioButtonCheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        classes={{
                                            root: classes.root,
                                        }}
                                        onClick={() => {
                                            setPayment("debit-card");
                                            clearErrorMessage("payment");
                                        }}
                                    />
                                }
                                label="Cartão de Débito"
                                classes={{
                                    label: classes.label,
                                }}
                            />
                        ) : undefined}
                        {props.business.payment &&
                        props.business.payment.includes("money") ? (
                            <FormControlLabel
                                value="money"
                                control={
                                    <Radio
                                        icon={
                                            <RadioButtonUncheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        checkedIcon={
                                            <RadioButtonCheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        classes={{
                                            root: classes.root,
                                        }}
                                        onClick={() => {
                                            setPayment("money");
                                            clearErrorMessage("payment");
                                        }}
                                    />
                                }
                                label="Dinheiro"
                                classes={{
                                    label: classes.label,
                                }}
                            />
                        ) : undefined}
                        {props.business.payment &&
                        props.business.payment.includes("payment-link") ? (
                            <FormControlLabel
                                value="payment-link"
                                control={
                                    <Radio
                                        icon={
                                            <RadioButtonUncheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        checkedIcon={
                                            <RadioButtonCheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        classes={{
                                            root: classes.root,
                                        }}
                                        onClick={() => {
                                            setPayment("payment-link");
                                            clearErrorMessage("payment");
                                        }}
                                    />
                                }
                                label="Link de Pagamento"
                                classes={{
                                    label: classes.label,
                                }}
                            />
                        ) : undefined}
                        {props.business.payment &&
                        props.business.payment.includes("slip") ? (
                            <FormControlLabel
                                value="slip"
                                control={
                                    <Radio
                                        icon={
                                            <RadioButtonUncheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        checkedIcon={
                                            <RadioButtonCheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        classes={{
                                            root: classes.root,
                                        }}
                                        onClick={() => {
                                            setPayment("slip");
                                            clearErrorMessage("payment");
                                        }}
                                    />
                                }
                                label="Boleto Bancário"
                                classes={{
                                    label: classes.label,
                                }}
                            />
                        ) : undefined}
                    </RadioGroup>
                </div>
                {getErrorMessage("payment") !== "" && (
                    <div
                        style={{
                            marginTop: 3,
                            marginLeft: 20,
                            fontSize: "0.75rem",
                            color: "#f44336",
                        }}
                    >
                        {getErrorMessage("payment")}
                    </div>
                )}

                {payment === "credit-card" && isOnsiteCheckoutMP() && (
                    <div class="checkout-card">
                        <div class="title">CARTÃO DE CRÉDITO</div>
                        <div>
                            <img
                                src="https://res.cloudinary.com/rockedevelopr/image/upload/v1645215548/carty/selo-seguranca_utma7f.png"
                                class="secureImage"
                                alt=""
                            />
                        </div>
                        <div class="data">
                            <div id="form-checkout">
                                <InputMask
                                    mask="9999 9999 9999 9999"
                                    value={cardNumber}
                                    onChange={(e) => {
                                        clearErrorMessage("cardNumber");
                                        setCardNumber(e.target.value);
                                    }}
                                >
                                    {() => (
                                        <TextField
                                            label="Número do cartão:"
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            style={{
                                                marginTop: 20,
                                                marginBottom: 20,
                                            }}
                                            error={getErrorMessage("cardNumber") !== ""}
                                            helperText={getErrorMessage("cardNumber")}
                                            required
                                            type="tel"
                                            InputProps={{
                                                endAdornment: cardImage ? (
                                                    <InputAdornment position="start">
                                                        <img src={cardImage} alt="" />
                                                    </InputAdornment>
                                                ) : undefined,
                                            }}
                                        />
                                    )}
                                </InputMask>
                                <InputMask
                                    mask="99/99"
                                    value={cardExpiration}
                                    onChange={(e) => {
                                        clearErrorMessage("cardExpiration");
                                        setCardExpiration(e.target.value);
                                    }}
                                >
                                    {() => (
                                        <TextField
                                            label="Data do Vencimento (MM/YY):"
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            style={{ marginBottom: 20 }}
                                            type="tel"
                                            required
                                            error={
                                                getErrorMessage("cardExpiration") !== ""
                                            }
                                            helperText={getErrorMessage("cardExpiration")}
                                        />
                                    )}
                                </InputMask>
                                <TextField
                                    label="Nome titular:"
                                    variant="standard"
                                    size="small"
                                    fullWidth
                                    style={{ marginBottom: 20 }}
                                    required
                                    value={cardholderName}
                                    error={getErrorMessage("cardholderName") !== ""}
                                    helperText={getErrorMessage("cardholderName")}
                                    onChange={(e) => {
                                        clearErrorMessage("cardholderName");
                                        setCardholderName(e.target.value);
                                    }}
                                />

                                <InputMask
                                    mask="999"
                                    value={securityCode}
                                    onChange={(e) => {
                                        clearErrorMessage("securityCode");
                                        setSecurityCode(e.target.value);
                                    }}
                                >
                                    {() => (
                                        <TextField
                                            label="CVC:"
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            style={{ marginBottom: 20 }}
                                            required
                                            type="tel"
                                            error={getErrorMessage("securityCode") !== ""}
                                            helperText={getErrorMessage("securityCode")}
                                        />
                                    )}
                                </InputMask>

                                <FormControl fullWidth>
                                    <InputLabel
                                        variant="standard"
                                        htmlFor="uncontrolled-native"
                                    >
                                        Parcelamento
                                    </InputLabel>
                                    <NativeSelect
                                        onChange={(e) => {
                                            //setErrorLocalAddressMessage("");
                                            clearErrorMessage("installments");
                                            selectInstallment(e.target.value);
                                            //props.setInstallment(e.target.value);
                                        }}
                                    >
                                        <option value=""></option>
                                        {installments &&
                                            installments.map((i) => {
                                                return (
                                                    <option value={i.installments}>
                                                        {i.recommended_message}
                                                    </option>
                                                );
                                            })}
                                    </NativeSelect>
                                </FormControl>
                                {getErrorMessage("installments") !== "" && (
                                    <div
                                        style={{
                                            marginTop: 3,
                                            marginLeft: 20,
                                            fontSize: "0.75rem",
                                            color: "#f44336",
                                        }}
                                    >
                                        {getErrorMessage("installments")}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div class="checkout-note">
                <div class="title">OBSERVAÇÃO</div>
                <div class="data">
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Adicione uma observação para o vendedor."
                        rows={4}
                        size="small"
                        multiline
                        fullWidth
                        style={{ marginBottom: 20 }}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                </div>
            </div>
            <div class="checkout-buttons">{renderButtons()}</div>
            <div class="loading">
                <div className={classes.loading}>
                    {loading ? <LinearProgress /> : undefined}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
    cartItems: state.cartList.cart,
    total: getCartTotal(
        state.cartList.cart,
        state.shipping.current,
        state.coupon.current,
        state.installment.current
    ),
    subtotal: getCartTotal(state.cartList.cart),
    shipping: state.shipping.current,
    coupon: state.coupon.current,
    couponAmount: getCouponAmount(state.cartList.cart, state.coupon.current),
    installment: state.installment.current,
});

export default connect(mapStateToProps, {
    clearCart,
    setShipping,
    clearCoupon,
    setInstallment,
    clearInstallment,
    removeFromCart,
    decrementQty,
    updateCart,
})(CheckoutPage);
