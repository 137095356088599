import React, { useState, useEffect, useCallback } from "react";

import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { getCartTotal, getCouponAmount } from "../services";
import Currency from "../components/common/currency";
//import InputMask from "react-input-mask";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputMask from "react-input-mask";
import shop from "../api/shop";
import hash from "object-hash";
import { useHistory } from "react-router-dom";

//import service from "../../api/service";

import { makeStyles } from "@material-ui/core/styles";
import {
    clearCart,
    setShipping,
    clearCoupon,
    setInstallment,
    clearInstallment,
} from "../actions";
import service from "../api/service";
import CheckoutHeader from "./CheckoutHeader";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import "./styles_checkout.css";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Shipping from "./Shipping";
import NativeSelect from "@material-ui/core/NativeSelect";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Paper from "@material-ui/core/Paper";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import getMPMessage from "../components/common/mpMessages";
import formatNumberValue from "../components/common/currency-method";
import { removeFromCart, decrementQty, updateCart } from "../actions";

function CheckoutPage(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    function isShippingSelected() {
        return isShipping() && delivery === "delivery";
    }
    function hasPlan() {
        return (
            props.business.activePlanName === "Pro" ||
            props.business.activePlanName === "Premium" ||
            props.business.activePlanName === "Diamante"
        );
    }
    function isShipping() {
        return (
            (hasPlan() &&
                props.business.delivery &&
                props.business.delivery.includes("shipping")) ||
            (props.business.activePlanName === "Diamante" &&
                props.business.features &&
                props.business.features.includes("me"))
        );
    }
    function isLocalDelivery() {
        return (
            props.business.delivery && props.business.delivery.includes("localdelivery")
        );
    }
    let isOnsiteCheckoutMP = useCallback(() => {
        return (
            (props.business.activePlanName === "Pro" ||
                props.business.activePlanName === "Diamante") &&
            props.business.features &&
            props.business.features.includes("onsite-checkout-mp")
        );
    }, [props.business.activePlanName, props.business.features]);

    let isStockActivate = () => {
        return (
            (props.business.activePlanName === "Pro" ||
                props.business.activePlanName === "Diamante" ||
                props.business.activePlanName === "Premium") &&
            props.business.features &&
            props.business.features.includes("stock")
        );
    };

    useEffect(() => {
        if (isOnsiteCheckoutMP()) {
            //console.log(props.business.paymentPublicKey);
            // eslint-disable-next-line no-undef
            const mp = new MercadoPago(props.business.paymentPublicKey, {
                locale: "pt-BR",
            });
            setMp(mp);
            setEnvironment(
                props.business.paymentPublicKey.indexOf("TEST") === -1
                    ? "production"
                    : "sandbox"
            );
        }
    }, [
        isOnsiteCheckoutMP,
        props.business.activePlanName,
        props.business.paymentPublicKey,
    ]);
    /*
  useEffect(() => {
    if (props.business.delivery.length === 1) {
      setDelivery(props.business.delivery[0]);
    }
    setTotal(
      Number(props.total) +
        (props.business.delivery[0] === "delivery"
          ? Number(props.business.deliveryTax)
          : 0)
    );
  }, [props.business.delivery, props.business.deliveryTax, props.total]);
*/

    const history = useHistory();

    useEffect(() => {
        async function init() {
            if (
                typeof props.business === "object" &&
                Object.keys(props.business).length === 0
            ) {
                history.push("/");
            }
        }
        init();
    }, [history, props.business]);

    useEffect(() => {
        document.body.style = "background: #FFFFFF;";
        setTotal(props.total);
    }, [props.total]);

    const useStyles = makeStyles((theme) => ({
        root: { padding: 5 },
        label: {
            fontSize: 14,
            marginLeft: 10,
        },
        loading: {
            width: "100%",
            "& > * + *": {
                marginTop: theme.spacing(2),
            },
        },
        formControl: {
            width: "100%",
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        disabled: {},
        inputRoot: {
            "&$disabled": {
                color: "black",
            },
        },
    }));

    const classes = useStyles();

    const [name, setName] = useState("");
    const [cellphone, setCellphone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [addressNumber, setAddressNumber] = useState("");
    const [addressNeighborhood, setAddressNeighborhood] = useState("");
    const [addressCity, setAddressCity] = useState("");
    const [addressState, setAddressState] = useState("");

    const [payment, setPayment] = useState("");
    const [delivery, setDelivery] = useState("");
    const [errorCellphoneMessage, setErrorCellphoneMessage] = useState("");
    const [errorMailMessage, setErrorMailMessage] = useState("");
    const [errorAddressMessage, setErrorAddressMessage] = useState("");
    const [errorLocalDeliveryFeeMessage, setErrorLocalDeliveryFeeMessage] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [errorZipCodeMessage, setErrorZipCodeMessage] = useState("");
    const [errorAddressNumberMessage, setErrorAddressNumberMessage] = useState("");

    const [errorShippingMethod, setErrorShippingMethod] = useState("");
    const [errorAddressCityMessage, setErrorAddressCityMessage] = useState("");
    const [errorAddressStateMessage, setErrorAddressStateMessage] = useState("");
    const [errorCPFMessage, setErrorCPFMessage] = useState("");
    const [errorNameMessage, setErrorNameMessage] = useState("");
    const [errorDeliveryMessage, setErrorDeliveryMessage] = useState("");
    const [errorPaymentMessage, setErrorPaymentMessage] = useState("");

    const [errorCardNumberMessage, setErrorCardNumberMessage] = useState("");
    const [errorCardExpirationMessage, setErrorCardExpirationMessage] = useState("");
    const [errorCardHolderNameMessage, setErrorCardHolderNameMessage] = useState("");
    const [errorSecurityCodeMessage, setErrorSecurityCodeMessage] = useState("");
    const [errorInstallments, setErrorInstallments] = useState("");
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const [note, setNote] = useState();

    const [neighborhoods, setNeighborhoods] = useState();
    const [selectedNeighborhood, setSelectedNeigborhood] = useState();
    const [deliveryAddress, setDeliveryAddress] = useState();
    const [searchByZipCode, setSearchByZipCode] = useState("");

    const [cardNumber, setCardNumber] = useState("");
    const [cardholderName, setCardholderName] = useState("");
    const [cardExpiration, setCardExpiration] = useState("");
    const [securityCode, setSecurityCode] = useState("");
    const [identificationType] = useState("CPF");
    const [identificationNumber, setIdentificationNumber] = useState("");
    const [installments, setInstallments] = useState();
    const [mp, setMp] = useState();
    const [open, setOpen] = useState();
    const [openSandbox, setOpenSandbox] = useState();
    const [environmnet, setEnvironment] = useState("sandbox");
    const [message, setMessage] = useState("");
    const [cardImage, setCardImage] = useState("");
    const [generalZipCode, setGeneralZipCode] = useState(false);
    const [outOfStockMessage, setOutOfStockMessage] = useState();
    const [openOutOfStockDialog, setOpenOutOfStockDialog] = useState(false);
    const [openEmptyCart, setOpenEmptyCart] = useState(false);
    const [useZipCode, setUseZipCode] = useState(true);
    const [isLocal, setIsLocal] = useState(false);

    let slip_url;
    let init_point;
    let qrcode;
    let expirationDatePix;

    let MAX = 60000 * 10;

    const [isEligibleFreeShipping] = useState(
        props.business.freeShipping && props.business.freeShipping > 0
    );

    useEffect(() => {
        setErrorPaymentMessage("");
    }, [payment]);

    useEffect(() => {
        async function fetchInstallments(bin) {
            try {
                const inst = await mp.getInstallments({
                    amount: total + "",
                    locale: "pt-BR",
                    bin: bin,
                });
                //console.log(inst);
                if (inst && inst.length > 0) {
                    setCardImage(inst[0].issuer.secure_thumbnail);
                    setInstallments(inst[0].payer_costs);
                }
            } catch (e) {
                if (e.status === 404) {
                    setErrorCardNumberMessage("Digite um número de cartão válido.");
                }
            }
        }

        //console.log("no effect");
        if (cardNumber) {
            //let cardNumberNoSpace = cardNumber.replace(/\s+/g, "");
            let cardNumberNoSpace = cardNumber.replace(/[^0-9]+/g, "");
            //console.log(cardNumberNoSpace);
            //console.log(cardNumberNoSpace.length);
            if (cardNumberNoSpace.length === 16) {
                //console.log("digitou tudo");
                let bin = cardNumberNoSpace.substring(0, 6);

                //console.log(bin);
                try {
                    //console.log("vou fetch");
                    fetchInstallments(bin);
                } catch (e) {
                    console.log(e);
                    console.log("caiu no catch");
                }
            }
        }
    }, [cardNumber, mp, total]);

    useEffect(() => {
        function getLocalDelivery() {
            let url = `/delivery/${props.business._id}`;
            if (!neighborhoods || neighborhoods.length === 0) {
                service.get(url).then((result) => {
                    //console.log(result.data);
                    setNeighborhoods(result.data);
                });
            }
        }
        if (isLocalDelivery()) {
            getLocalDelivery();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.business]);

    useEffect(() => {
        if (props.shipping && props.shipping.destinationZipCode && zipCode === "") {
            setZipCode(props.shipping.destinationZipCode);
            searchZipCode(props.shipping.destinationZipCode);
            setDelivery("delivery");
        }
        if (props.shipping && props.shipping.method) {
            setErrorShippingMethod("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.shipping]);
    /*
    useEffect(() => {
        if (selectedNeighborhood) {
            setTotal(
                Number(props.total) +
                    (selectedNeighborhood && !isFreeShipping()
                        ? Number(selectedNeighborhood.deliveryTax)
                        : 0)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNeighborhood]);
*/
    useEffect(() => {
        //subtotal
        let total = 0;
        for (var i = 0; i < props.cartItems.length; i++) {
            total +=
                parseFloat(props.cartItems[i].qty) *
                parseFloat(
                    props.cartItems[i].promoPrice || props.cartItems[i].price || 0
                );
        }

        if (props.coupon && props.coupon.amount && props.coupon.amount > 0) {
            let couponAmount = total * (props.coupon.amount / 100);
            total = total - couponAmount;
        }

        if (props.shipping && props.shipping.total > 0) {
            total = parseFloat(props.shipping.total + total).toFixed(2);
        }
        if (selectedNeighborhood) {
            total = total + Number(selectedNeighborhood.deliveryTax);
        }
        total = parseFloat(total).toFixed(2);
        setTotal(total);
    }, [props.cartItems, props.coupon, props.shipping, selectedNeighborhood]);

    async function checkForm() {
        let valid = true;
        if (!validateName()) {
            setErrorNameMessage("Digite o seu nome.");
            valid = false;
        }
        if (!validateCellphone()) {
            setErrorCellphoneMessage("Digite um número de celular válido.");
            valid = false;
        }
        if (isOnsiteCheckoutMP()) {
            if (!validateEmail()) {
                setErrorMailMessage("Digite um email válido.");
                valid = false;
            }
        }
        if (!validateDelivery()) {
            setErrorDeliveryMessage("Selecione o tipo de entrega.");
            valid = false;
        }
        if (!validateAddressDelivery()) {
            setErrorAddressMessage("Digite o endereço para a entrega.");
            valid = false;
        }

        if (!validateLocalDeliveryFee()) {
            //console.log("aqui");
            setErrorLocalDeliveryFeeMessage("Selecione a região da entrega.");
            valid = false;
        }
        if (!validateShippingAddress()) {
            setErrorZipCodeMessage("Digite o CEP da entrega");
            valid = false;
        }
        if (!validateAddressNumber()) {
            setErrorAddressNumberMessage("Digite o número.");
            valid = false;
        }
        if (!validateShippingFee()) {
            setErrorShippingMethod("Selecione o método de envio.");
            valid = false;
        }
        if (!validatePayment()) {
            setErrorPaymentMessage("Selecione o tipo de pagamento.");
            valid = false;
        }

        if (isOnsiteCheckoutMP() && payment === "credit-card") {
            if (!(await validateCardNumber())) {
                //console.log("cartao invalido");
                setErrorCardNumberMessage("Digite um número de cartão válido.");
                setCardImage(null);
                valid = false;
            }

            if (!validateCardExpiration()) {
                setErrorCardExpirationMessage("Digite uma data válida ( MM/YY ).");
                valid = false;
            }
            if (!validateCardHolderName()) {
                setErrorCardHolderNameMessage(
                    "Digite o nome do titular conforme apresentado no cartão."
                );
                valid = false;
            }
            if (!validateSecurityCode()) {
                setErrorSecurityCodeMessage("Digite o código de segurança.");
                valid = false;
            }
            if (!validateCPF()) {
                setErrorCPFMessage("Digite um CPF válido.");
                valid = false;
            }
            if (!validateInstallments()) {
                setErrorInstallments("Selecione a quantidade de parcelas.");
                valid = false;
            }
        }
        if (isOnsiteCheckoutMP() && payment === "slip") {
            if (!validateCPF()) {
                setErrorCPFMessage("Digite um CPF válido.");
                valid = false;
            }
        }

        if (isShipping() && delivery === "delivery" && !isLocal) {
            if (!validateCPF()) {
                setErrorCPFMessage("Digite um CPF válido.");
                valid = false;
            }
        }

        if (!valid) {
            setMessage("Revise os dados do formulário.");
        }
        return valid;
    }
    function clearCEPFields() {
        setAddress("");
        setAddressNeighborhood("");
        setAddressState("");
        setAddressCity("");
        setDeliveryAddress(null);
        props.setShipping({});
        setSearchByZipCode("");
        setGeneralZipCode(false);
        setZipCode("");
        setAddressNumber("");
        setErrorShippingMethod("");
        setSelectedNeigborhood(null);
        setIsLocal(false);
    }
    async function searchZipCode(typed) {
        var numberPattern = /\d+/g;
        let zip = typed && typed !== null && typed.match(numberPattern);
        if (zip) {
            zip = zip.join("");
            //console.log(zip);
            //console.log(zipCode);
            if (deliveryAddress && zip.length < 8) {
                clearCEPFields();
            } else if (zip.length === 8) {
                let zipCode = zip.replace("-", "");

                service.get(`/shipping/address/${zipCode}`).then(async (response) => {
                    response = response.data;
                    if (response && response.result && response.result.uf) {
                        setDeliveryAddress(response.result);
                        setAddress(response.result.logradouro);
                        setAddressNeighborhood(response.result.bairro);
                        setAddressState(response.result.uf);
                        setAddressCity(response.result.localidade);
                        setErrorAddressMessage("");
                        //console.log(zipCode.substring(zipCode.length - 3) === "000");
                        setGeneralZipCode(
                            zipCode.substring(zipCode.length - 3) === "000"
                        );
                        if (isShipping() && !isLocalDelivery()) {                            
                                setIsLocal(false);
                                setSearchByZipCode(zipCode);                            
                        } else if( !isShipping() && isLocalDelivery()) {
                                setIsLocal(true);
                                setSearchByZipCode("");
                        } else if (isShipping() && isLocalDelivery()) {
                          
                            //compare localidade
                            let originZipCode = props.business.originZipCode;
                            let responseOrigin = await service.get(
                                `/shipping/address/${originZipCode}`
                            );
                            if (
                                responseOrigin.data.result &&
                                responseOrigin.data.result.localidade &&
                                responseOrigin.data.result.localidade ===
                                    response.result.localidade
                            ) {
                                setIsLocal(true);
                                setSearchByZipCode("");
                            } else {
                                setIsLocal(false);
                                setSearchByZipCode(zipCode);
                            }
                        } else {
                            setIsLocal(false);
                            setSearchByZipCode("");
                        }
                    } else {
                        setMessage("CEP não encontrado.");
                    }
                });
            }
        }
        setZipCode(typed);
    }

    async function validateCardNumber() {
        let cardNumberNoSpace = cardNumber.replace(/[^0-9]+/g, "");
        //console.log(cardNumberNoSpace);
        if (cardNumberNoSpace !== "") {
            let bin = cardNumberNoSpace.substring(0, 6);
            const paymentMethods = await mp.getPaymentMethods({ bin: bin });

            return (
                cardNumber !== "" &&
                cardNumberNoSpace.length === 16 &&
                paymentMethods.results &&
                paymentMethods.results.length > 0
            );
        } else {
            //console.log("retornando false");
            return false;
        }
    }
    function validateCardExpiration() {
        let cardExpirationMonth = cardExpiration.split("/")[0];
        let cardExpirationYear = cardExpiration.split("/")[1];
        var regex = new RegExp("^((0[1-9])|(1[0-2]))/(2[2-9])|(3[0-9])$");
        let valid = cardExpiration !== "" && regex.test(cardExpiration);
        valid = valid && moment().year() <= 2000 + Number(cardExpirationYear);
        if (moment().year() === 2000 + Number(cardExpirationYear)) {
            //console.log(valid);
            //console.log(moment().month());
            //console.log(Number(cardExpirationMonth));
            valid = valid && moment().month() + 1 <= Number(cardExpirationMonth);
        }
        return valid;
    }
    function validateCardHolderName() {
        return cardholderName !== "";
    }
    function validateSecurityCode() {
        return securityCode !== "" && securityCode >= 3;
    }
    function validateInstallments() {
        //console.log("no validar installment");
        //console.log(props.installment);
        return props.installment && props.installment.installments > 0;
    }

    function validateCPF() {
        let cpf = identificationNumber;
        cpf = cpf.replace(/[^\d]+/g, "");
        if (cpf === "") return false;
        // Elimina CPFs invalidos conhecidos
        if (
            cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999"
        )
            return false;
        // Valida 1o digito
        let add = 0;
        for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
        let rev = 11 - (add % 11);
        if (rev === 10 || rev === 11) rev = 0;
        if (rev !== parseInt(cpf.charAt(9))) return false;
        // Valida 2o digito
        add = 0;
        for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev === 10 || rev === 11) rev = 0;
        if (rev !== parseInt(cpf.charAt(10))) return false;
        return true;
    }

    function validateShippingAddress() {
        let valid = true;
        if (useZipCode && delivery === "delivery") {
            valid = valid && zipCode !== "" && deliveryAddress !== null;
        }
        return valid;
    }
    function validateAddressNumber() {
        let valid = true;
        if (useZipCode && delivery === "delivery") {
            valid = valid && addressNumber !== "";
        }
        return valid;
    }
    function validateShippingFee() {
        if (
            isShipping() &&
            useZipCode &&
            delivery === "delivery" &&
            zipCode &&
            !isFreeShipping() &&
            !isLocal
        ) {
            if (props.shipping && props.shipping.total > 0) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
    function validateDelivery() {
        return delivery !== "";
    }

    function validatePayment() {
        return payment !== "";
    }

    function validateAddressDelivery() {
        if (delivery === "delivery") {
            return address !== null && address !== "";
        }
        return true;
    }

    function validateLocalDeliveryFee() {
        if (
            delivery === "delivery" &&
            isLocalDelivery() &&
            !isFreeShipping() &&
            neighborhoods &&
            neighborhoods.length > 1 &&
            (isLocal || !useZipCode)
        ) {
            return selectedNeighborhood && selectedNeighborhood !== null;
        }
        return true;
    }

    function validateEmail() {
        if (email) {
            return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                email
            );
        } else {
            return false;
        }
    }
    function validateName() {
        return name !== "";
    }
    function validateCellphone() {
        var regex = new RegExp("^\\(((1[1-9])|([2-9][0-9]))\\) (9[0-9]{4}-[0-9]{4})$");
        return regex.test(cellphone);
    }

    async function checkDelivery(value) {
        setErrorDeliveryMessage("");
        props.setShipping({});

        if (value === "delivery") {
            let totalCalc = !isFreeShipping() ? Number(props.business.deliveryTax) : 0;
            props.setShipping({ total: totalCalc });
            if (neighborhoods && neighborhoods.length === 1) {
                setSelectedNeigborhood(neighborhoods[0]);
            } else {
                setSelectedNeigborhood(null);
                setUseZipCode(true);
            }
        } else {
            //setTotal(Number(props.total));
            clearCEPFields();
            setSearchByZipCode(null);
            setSelectedNeigborhood(null);
            setUseZipCode(true);
        }

        setDelivery(value);
    }
    function getAdress() {
        if (useZipCode) {
            return `${address} ${addressNumber} , ${addressNeighborhood} - ${addressCity}/${addressState} CEP: ${zipCode} `;
        } else {
            return address;
        }
    }

    function getShipping() {
        if (
            props.shipping &&
            props.shipping.method &&
            props.shipping.total &&
            props.shipping.total > 0
        ) {
            return `${props.shipping.method} : ${formatNumberValue(
                props.shipping.total
            )} - ${props.shipping.days}`;
        } else {
            return null || "";
        }
    }

    async function checkStock() {
        //let products = await shop.getProducts(props.business.uid);
        let checked = true;
        let messages = [];
        for (var i = 0; i < props.cartItems.length; i++) {
            let cartProduct = props.cartItems[i];
            let existing = await shop.getProduct(cartProduct.id);
            props.updateCart(existing);
            //console.log(existing);
            if (existing.quantity < cartProduct.qty) {
                if (existing.quantity > 0) {
                    let diff = cartProduct.qty - existing.quantity;
                    props.decrementQty(existing.id, diff);
                    messages.push(
                        `O produto ${existing.name} possui apenas ${existing.quantity} unidade(s) no estoque. Seu pedido foi atualizado.`
                    );
                } else {
                    props.removeFromCart(existing);
                    messages.push(
                        `O produto ${existing.name} está indisponível no momento. Esse item foi retirado do pedido.`
                    );
                }
                checked = false;
            }
        }
        setOutOfStockMessage(messages);
        return { checked, messages };
    }

    async function generateHashOrder() {
        let content = {
            name: name,
            email: email,
            address: getAdress(),
            cellphone: cellphone,
            business: props.business._id,
            payment: payment,
            delivery: delivery,
            subTotal: props.subtotal || 0,
            cartItems: props.cartItems
                .map((product) => {
                    return product.id + product.qty;
                })
                .join(),
            note: note,
            orderTotal: total,
        };
        return hash(content);
    }

    async function save() {
        if (isStockActivate() && !props.business.pivot) {
            let toCheck = await checkStock();
            if (!toCheck.checked) {
                setOpenOutOfStockDialog(true);
            } else {
                doIt();
            }
        } else {
            doIt();
        }
    }

    async function hasNoOrder(hash) {
        let existingOrder = await service.get(`/order/hash/${hash}`);
        if (existingOrder.data.order === null || existingOrder.data.order === undefined) {
            return true;
        } else {
            //check order timestamp
            let orderTimestamp = existingOrder.data.order.createdAt;
            let diffInSeconds = (new Date() - moment(orderTimestamp).toDate()) / 1000;
            //console.log(`segundos ${diffInSeconds}`);
            if (diffInSeconds < 30) {
                return false;
            } else {
                return true;
            }
        }
    }

    async function doIt() {
        if (await checkForm()) {
            let hashOrder = await generateHashOrder();
            if (await hasNoOrder(hashOrder)) {
                if (isOnsiteCheckoutMP() && payment === "credit-card") {
                    setOpen(true);
                    return;
                }
                if (isOnsiteCheckoutMP() && payment === "slip") {
                    callMPTicket();
                    return;
                }
                if (isOnsiteCheckoutMP() && payment === "money-order") {
                    callMPPix();
                    return;
                }
                if (isOnsiteCheckoutMP() && payment === "payment-link") {
                    callMPCheckoutPro();
                } else {
                    setLoading(true);
                    let order = await createAndSaveOrder();
                    setLoading(false);
                    callSuccess(order);
                }
            } else {
                console.log("a ordem já existe");
            }
        }
    }
    async function callMPCreditCard() {
        try {
            setOpen(false);
            setLoading(true);
            //console.log("no save");
            let cardNumberNoSpace = cardNumber.replace(/\s+/g, "");
            let bin = cardNumberNoSpace.substring(0, 6);
            //console.log(cardNumber);
            //console.log(bin);
            //console.log(cardholderName);
            let cardExpirationMonth = cardExpiration.split("/")[0];
            let cardExpirationYear = cardExpiration.split("/")[1];
            //console.log(cardExpirationMonth);
            //console.log(cardExpirationYear);
            //console.log(securityCode);
            let idNumber = identificationNumber.replace(/[^0-9]+/g, "");
            //console.log(idNumber);

            let amount = total;
            //console.log(amount);
            //console.log(email);

            const paymentMethods = await mp.getPaymentMethods({ bin: bin });
            //console.log(paymentMethods);

            const issuers = await mp.getIssuers({
                paymentMethodId: paymentMethods.results[0].id,
                bin: bin,
            });
            // console.log(issuers);
            let issuerId = issuers[0].id;

            const cardToken = await mp.createCardToken({
                cardNumber: cardNumberNoSpace,
                cardholderName,
                cardExpirationMonth,
                cardExpirationYear,
                securityCode,
                identificationType: "CPF",
                identificationNumber: idNumber,
            });
            let r = await service.post("order/process_payment", {
                businessId: props.business._id,
                token: cardToken.id,
                issuer_id: issuerId,
                payment_method_id: paymentMethods.results[0].id,
                transaction_amount: Number(amount),
                installments: props.installment
                    ? Number(props.installment.installments)
                    : 0,
                description: "Venda no app Carty",
                payer: {
                    email,
                    identification: {
                        type: identificationType,
                        number: idNumber,
                    },
                },
            });
            if (r.data.status !== "rejected") {
                //console.log(r.data);
                //console.log(r.data.id);
                let order = await createAndSaveOrder("credit-card", r.data.id);
                callSuccess(order, "credit-card");
                //await generateOrder("credit-card", r.data.id);
            } else {
                setMessage(getMPMessage(r.data.message));
                setLoading(false);
            }
            //setLoading(false);
        } catch (error) {
            console.log(error);
            if (error.status === 400) {
                setMessage(getMPMessage(error.data.message));
            }
            setLoading(false);
        }
    }
    async function callMPCheckoutPro() {
        let order = {};
        try {
            setLoading(true);
            order = await createAndSaveOrder();
            let r = await service.post("order/checkout-pro", { orderId: order._id });
            if (r.data.id) {
                init_point = r.data.init_point;
                await service.put("order", {
                    ...r.data.order,
                    initPoint: init_point,
                });
                callSuccess(order, "payment-link");
            } else {
                setMessage(getMPMessage(r.data.message));
                callSuccess(order, "payment-link");
                setLoading(false);
            }
            //setLoading(false);
        } catch (error) {
            console.log(error);
            if (error.status === 400) {
                setMessage(getMPMessage(error.data.message));
                callSuccess(order);
            }
            setLoading(false);
        }
    }

    async function callMPPix() {
        try {
            setLoading(true);

            let amount = total;
            let idNumber = identificationNumber.replace(/[^0-9]+/g, "");
            let payment_method_id = "pix";
            let r = await service.post("order/pix", {
                businessId: props.business._id,
                payment_method_id,
                transaction_amount: Number(amount),
                description: `Venda no app Carty - ${props.business.name}`,
                date_of_expiration: new Date(Date.now() + MAX * 4), //40min
                payer: {
                    email,
                    first_name: name,
                    last_name: " ",
                    identification: {
                        type: identificationType,
                        number: idNumber,
                    },
                },
            });
            if (r.data.id) {
                qrcode = r.data.qrcode;
                expirationDatePix = Date.now() + MAX;
                //setOpenPix(true);
                let order = await createAndSaveOrder("pix", r.data.id);
                callSuccess(order, "pix");
            } else {
                setMessage(getMPMessage(r.data.message));
                setLoading(false);
            }
            //setLoading(false);
        } catch (error) {
            console.log(error);
            if (error.status === 400) {
                setMessage(getMPMessage(error.data.message));
            }
            setLoading(false);
        }
    }

    async function callMPTicket() {
        try {
            setLoading(true);

            let amount = total;
            let idNumber = identificationNumber.replace(/[^0-9]+/g, "");
            let payment_method_id = "bolbradesco";
            let r = await service.post("order/ticket", {
                businessId: props.business._id,
                payment_method_id,
                transaction_amount: Number(amount),
                description: `Venda no app Carty - ${props.business.name}`,
                payer: {
                    email,
                    first_name: name,
                    last_name: " ",
                    identification: {
                        type: identificationType,
                        number: idNumber,
                    },
                },
            });
            if (r.data.id) {
                slip_url = r.data.external_resource_url;
                let order = await createAndSaveOrder("bolbradesco", r.data.id);
                callSuccess(order, "bolbradesco");
            } else {
                setMessage(getMPMessage(r.data.message));
                setLoading(false);
            }
            //setLoading(false);
        } catch (error) {
            console.log(error);
            if (error.status === 400) {
                setMessage(getMPMessage(error.data.message));
            }
            setLoading(false);
        }
    }

    function getInstallmentsMessage() {
        if (installments && props.installment && props.installment.installments > 1) {
            return props.installment.recommended_message;
        }
    }
    function getInstallmentRate() {
        if (installments && props.installment && props.installment.installments > 1) {
            let rate =
                (total || props.total) * (props.installment.installment_rate / 100);
            return rate;
        } else {
            return 0;
        }
    }

    function callSuccess(order, payment_method_id) {
        let orderNumber = order.orderNumber;
        //console.log(">>>>> order " + orderNumber);
        props.clearCart();
        props.clearCoupon();
        props.clearInstallment();
        //console.log(props.business.uid);
        props.history.push({
            pathname: "/order",
            state: {
                items: props.cartItems,
                orderTotal: total,
                deliveryTax: getShippingDeliveryTax(),
                name: name,
                cellphone: cellphone,
                address: getAdress(),
                email: email,
                payment: payment,
                delivery: delivery,
                storeaddress: props.business.address,
                bankAccount: props.business.bankAccount,
                orderNumber: orderNumber,
                whatsapp: props.business.whatsappNumber,
                coupon: props.coupon,
                couponAmount: props.couponAmount,
                subTotal: props.subtotal,
                note: note,
                shippingDesc: getShipping(),
                shippingFee: props.shipping.total,
                cardLastDigits: getLastDigitsCreditNumber(),
                cardImage: cardImage,
                installmentsMessage: getInstallmentsMessage(),
                installmentRate: getInstallmentRate(),
                slip_url: payment_method_id === "bolbradesco" ? slip_url : "",
                qrcode: payment_method_id === "pix" ? qrcode : "",
                expirationDatePix: payment_method_id === "pix" ? expirationDatePix : "",
                initPoint: payment_method_id === "payment-link" ? init_point : "",
            },
        });
    }

    function isFreeShipping() {
        return isEligibleFreeShipping && props.subtotal > props.business.freeShipping;
    }
    function getName(item) {
        let name = item.name;

        if (item.variationValue) {
            name = name + " - " + item.variationValue;
        }
        if (item.colors?.length > 0 || item.sizes?.length > 0) {
            if (item.color && item.color !== "" && item.color !== "undefined") {
                name += " - " + item.color;
            }
            if (item.size && item.size !== "" && item.size !== "undefined") {
                name += "  " + item.size;
            }
        }
        return name;
    }
    function getShippingDeliveryTax() {
        if (props.shipping && props.shipping.total) {
            return props.shipping.total;
        }
        if (selectedNeighborhood && selectedNeighborhood.deliveryTax) {
            //console.log(selectedNeighborhood.deliveryTax);
            return selectedNeighborhood.deliveryTax;
        }
        if (
            props.business.deliveryTax &&
            delivery !== "localdelivery" &&
            delivery !== "shipping"
        ) {
            return props.business.deliveryTax && props.business.deliveryTax !== undefined
                ? props.business.deliveryTax
                : 0;
        }
    }

    function selectInstallment(installmentsQuantity) {
        if (installments && installmentsQuantity) {
            //console.log(installments);
            let filtered = installments.filter(
                (i) => i.installments === Number(installmentsQuantity)
            );

            props.setInstallment(filtered[0]);
        }
    }
    function getMessage(orderNumber) {
        let message = "NOVO PEDIDO! \nRESUMO DO PEDIDO: # " + orderNumber + " \n";

        //message = message + "PRODUTO, QUANTIDADE, VALOR \n";
        props.cartItems.forEach((item) => {
            message =
                message +
                "( " +
                item.qty +
                " ) X " +
                getName(item) +
                " : " +
                formatNumberValue(item.promoPrice || item.price) +
                " \n";
        });
        if (
            delivery === "delivery" ||
            delivery === "localdelivery" ||
            (isShippingSelected() && props.shipping)
        ) {
            if (isFreeShipping()) {
                message = message + "TAXA ENTREGA: FRETE GRÁTIS\n";
            } else {
                message =
                    message +
                    "TAXA ENTREGA: " +
                    formatNumberValue(getShippingDeliveryTax()) +
                    " \n \n";
            }
        }

        if (props.installment && props.installment.installments > 1) {
            message =
                message + "JUROS: " + formatNumberValue(getInstallmentRate()) + " \n \n";
        }

        if (props.coupon && props.couponAmount) {
            message =
                message +
                "CUPOM ( " +
                props.coupon.code +
                " ): " +
                formatNumberValue(props.couponAmount) +
                " \n \n";
        }

        message =
            message +
            "TOTAL: " +
            formatNumberValue(total + getInstallmentRate()) +
            " \n \n";

        message = message + "DADOS DO COMPRADOR: \n";
        message = message + "NOME: " + name + " \n";
        message = message + "CELULAR: " + cellphone + " \n";
        message = message + "EMAIL: " + (email || "NÃO INFORMADO") + " \n \n";
        if (address) {
            message = message + "ENDEREÇO DE ENTREGA:\n" + getAdress() + " \n \n";
        } else {
            message =
                message +
                "ENDEREÇO PARA RETIRADA:\n" +
                (props.business.address || "A COMBINAR") +
                " \n \n";
        }

        if (props.shipping && isShippingSelected() && !isFreeShipping()) {
            message = message + getShipping() + " \n";
        }

        message = message + "FORMA DE PAGAMENTO: \n";
        message = message + getPaymentDescription() + "\n";

        if (isOnsiteCheckoutMP() && payment === "credit-card") {
            message = message + getLastDigitsCreditNumber() + " \n \n";
            if (props.installment && props.installment.installments > 1) {
                message = message + getInstallmentsMessage() + "\n \n";
            }
        }

        if (note) {
            message = message + "\n \nOBSERVAÇÃO: \n" + note + "\n";
        }

        return message;
    }

    function getPaymentDescription() {
        switch (payment) {
            case "money-order":
                return "TRANSFERÊNCIA BANCÁRIA / PIX";
            case "credit-card":
                return "CARTÃO DE CRÉDITO";
            case "debit-card":
                return "CARTÃO DE DÉBITO";
            case "money":
                return "DINHEIRO";
            case "slip":
                return "BOLETO BANCÁRIO";
            case "payment-link":
                return "LINK DE PAGAMENTO";
            default:
                return null;
        }
    }

    function renderButtons() {
        if (props.business.layout === "minimalist") {
            return (
                <div class="checkout-button-minimalist">
                    {!loading ? (
                        <div className="enabled" onClick={() => save()}>
                            FINALIZAR
                        </div>
                    ) : (
                        <div className="disabled">FINALIZAR</div>
                    )}
                </div>
            );
        } else if (props.business.layout === "dark") {
            return (
                <div class="checkout-button-dark">
                    {!loading ? (
                        <div className="enabled" onClick={() => save()}>
                            FINALIZAR
                        </div>
                    ) : (
                        <div className="disabled">FINALIZAR</div>
                    )}
                </div>
            );
        } else {
            return (
                <div class="checkout-button-food">
                    <Link to={`${process.env.PUBLIC_URL}/cart`} class="secondary">
                        VOLTAR
                    </Link>
                    {!loading ? (
                        <Button
                            onClick={() => save()}
                            size="small"
                            color="inherit"
                            class="primary"
                        >
                            FECHAR PEDIDO
                        </Button>
                    ) : (
                        <Button size="small" color="inherit" class="disabled">
                            FECHAR PEDIDO
                        </Button>
                    )}
                </div>
            );
        }
    }
    async function processCartItems(props, createdOrder) {
        const selections = [];

        for (const product of props.cartItems) {
            console.log(product);

            try {
                const resp = await service.post("selection/stock", {
                    order: createdOrder.data.order._id,
                    product: { ...product, _id: product.id },
                    stockQuantity: product.quantity,
                    quantity: product.qty,
                    productName: getName(product),
                    productColor: product.color,
                    productSize: product.size,
                    productPrice: product.promoPrice || product.price || 0,
                    player_ids: props.business.deviceId || [],
                    orderId: createdOrder.data.order._id,
                    hasStock:
                        props.business.activePlanName &&
                        props.business.activePlanName !== "Gratuito" &&
                        props.business.features.includes("stock"),
                });

                selections.push(resp.data.selection._id);
            } catch (error) {
                // Handle errors as needed
                console.error("Error processing product:", product, error);
            }
        }

        return selections;
    }

    async function createAndSaveOrder(payment_method_id, transactionId) {
        //setLoading(true);
        try {
            let hashOrder = await generateHashOrder();
            const createdClient = await service.post("client", {
                name: name,
                email: email,
                fullAddress: getAdress(),
                address: address,
                number: addressNumber,
                neighborhood: addressNeighborhood,
                city: addressCity,
                state: addressState,
                zipCode: zipCode,
                cellphone: cellphone,
                business: props.business._id,
                identificationNumber: identificationNumber,
            });
            //console.log(createdClient);
            const createdOrder = await service.post("order", {
                client: createdClient.data.client._id,
                business: props.business._id,
                payment: payment,
                delivery: delivery,
                subTotal: props.subtotal || 0,
                hash: hashOrder,
            });

            if (createdOrder) {
                /*
                const promisses = props.cartItems.map(async (product) => {
                    console.log(product);
                    const resp = await service.post("selection/stock", {
                        order: createdOrder.data.order._id,
                        product: { ...product, _id: product.id },
                        stockQuantity: product.quantity,
                        quantity: product.qty,
                        productName: getName(product),
                        productColor: product.color,
                        productSize: product.size,
                        productPrice: product.promoPrice || product.price || 0,
                        player_ids: props.business.deviceId || [],
                        orderId: createdOrder.data.order._id,
                        hasStock:
                            props.business.activePlanName &&
                            props.business.activePlanName !== "Gratuito" &&
                            props.business.features.includes("stock"),
                    });
                    return resp.data.selection._id;
                });

                const selections = await Promise.all(promisses);
                */
                // Call the function
                const selections = await processCartItems(props, createdOrder);

                await service.put("order", {
                    _id: createdOrder.data.order._id,
                    selections: selections,
                    orderTotal: total + getInstallmentRate(),
                    coupon: props.coupon ? props.coupon._id : null,
                    note: note,
                    deliveryNeighborhood: selectedNeighborhood
                        ? selectedNeighborhood.neighborhood
                        : "",
                    deliveryTax: getShippingDeliveryTax(),
                    shippingFee: isShippingSelected() ? props.shipping.total : 0,
                    shippingMethod: isShippingSelected() ? props.shipping.method : "",
                    shippingZipCode: isShippingSelected()
                        ? props.shipping.destinationZipCode
                        : "",
                    shippingDays: isShippingSelected() ? props.shipping.days : "",

                    cardPaymentId:
                        payment_method_id === "credit-card" ? transactionId : "",
                    cardLastDigits:
                        payment_method_id === "credit-card"
                            ? getLastDigitsCreditNumber()
                            : "",
                    cardImage: cardImage,
                    installmentsMessage: getInstallmentsMessage(),
                    installmentRate: getInstallmentRate(),
                    transactionId:
                        payment_method_id === "bolbradesco" || payment_method_id === "pix"
                            ? transactionId
                            : "",
                    slipUri: payment_method_id === "bolbradesco" ? slip_url : "",
                    hash: hashOrder,
                    qrcode: payment_method_id === "pix" ? qrcode : "",
                    expirationDatePix:
                        payment_method_id === "pix" ? expirationDatePix : "",
                    shippingService: props.shipping.id ? props.shipping.id : null,
                    identificationNumber: identificationNumber,
                    //initPoint: payment_method_id === "payment-link" ? init_point : "", //local update callMPCheckoutPro
                });
                console.log(createdOrder);

                await service.post("order/notify", {
                    orderId: createdOrder.data.order._id,
                    to: props.business.whatsappNumber,
                    email: email,
                    message: getMessage(createdOrder.data.order.orderNumber),
                    player_ids: props.business.deviceId || [],
                });
            }

            //console.log(resp);
            //setLoading(false);

            return createdOrder.data.order;
        } catch (e) {
            console.log(e);
            //setLoading(false);
        }
    }
    function getLastDigitsCreditNumber() {
        return cardNumber && "FINAL " + cardNumber.split(" ").pop();
    }
    const handleClose = () => {
        setOpen(false);
        setOpenOutOfStockDialog(false);
    };

    const handleCloseMessage = () => {
        setMessage("");
    };

    function checkCartItems() {
        if (props.cartItems.length === 0) {
            setOpenEmptyCart(true);
        } else {
            setOpenOutOfStockDialog(false);
            props.history.push({
                pathname: "/cart",
            });
        }
    }
    function toHome() {
        props.history.push({
            pathname: "/",
        });
    }
    return props.business && props.business.delivery ? (
        <div id="checkout-wrap">
            <div>
                <Backdrop
                    className={classes.backdrop}
                    open={loading}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar
                    open={message !== ""}
                    //onClose={handleClose}
                    message={message}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    autoHideDuration={3000}
                    onClose={handleCloseMessage}
                />
                <Dialog
                    open={open}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Deseja confirmar o pedido?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Paper variant="outlined" square>
                                <div style={{ margin: 10 }}>
                                    <div>Valor do pedido:</div>
                                    <div
                                        style={{ color: "rgba(var(--primary-color), 1)" }}
                                    >
                                        <Currency value={total + getInstallmentRate()} />
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        Cartão de crédito:
                                    </div>
                                    <img src={cardImage} alt="" />
                                    <div
                                        style={{ color: "rgba(var(--primary-color), 1)" }}
                                    >
                                        {getLastDigitsCreditNumber()}
                                    </div>
                                </div>
                            </Paper>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">
                            Não
                        </Button>
                        <Button onClick={() => callMPCreditCard()} color="primary">
                            Sim
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openOutOfStockDialog}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Alguém comprou esse produto."}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Paper variant="outlined" square>
                                <div style={{ margin: 10 }}>
                                    <div>{outOfStockMessage}</div>
                                </div>
                            </Paper>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => checkCartItems()} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openEmptyCart}
                    keepMounted
                    onClose={() => setOpenEmptyCart(false)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Carrinho vazio."}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Paper variant="outlined" square>
                                <div style={{ margin: 10 }}>
                                    <div>
                                        Não existem produtos no seu carrinho. Você será
                                        redirecionado para nossa loja.
                                    </div>
                                </div>
                            </Paper>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => toHome()} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openSandbox}
                    keepMounted
                    onClose={() => setOpenSandbox(false)}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {"AMBIENTE DE TESTE"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Paper variant="outlined" square>
                                <div style={{ margin: 10 }}>
                                    Esse é um ambiente de teste. O pedido não irá gerar
                                    uma cobrança no cartão de crédito informado. Para
                                    habilitar o ambiente de produção, insira as
                                    credenciais corretas do Mercado Pago no app Carty.
                                </div>
                            </Paper>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenSandbox(false)} color="primary">
                            Entendi
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div
                className={
                    props.business.layout === "minimalist" ||
                    props.business.layout === "dark" ||
                    props.business.layout === "fendi"
                        ? "checkout-header"
                        : "checkout-header-2"
                }
            >
                <CheckoutHeader checkout={true} />
            </div>

            <div class="checkout-client-data">
                <div
                    className={
                        props.business.layout === "minimalist" ||
                        props.business.layout === "dark" ||
                        props.business.layout === "fendi"
                            ? "marginTopMinimalist"
                            : "marginTopFood"
                    }
                ></div>
                <div class="title">DADOS DO CLIENTE</div>
                <div class="data">
                    <TextField
                        id="outlined-basic"
                        label="Nome:"
                        variant="standard"
                        size="small"
                        fullWidth
                        style={{ marginBottom: 20 }}
                        required
                        value={name}
                        error={errorNameMessage !== ""}
                        helperText={errorNameMessage}
                        onChange={(e) => {
                            setErrorNameMessage("");
                            setName(e.target.value);
                        }}
                    />

                    <InputMask
                        mask="(99) 99999-9999"
                        value={cellphone}
                        onChange={(e) => {
                            setErrorCellphoneMessage("");
                            setCellphone(e.target.value);
                        }}
                    >
                        {() => (
                            <TextField
                                id="outlined-basic"
                                label="Celular ( Whatsapp ):"
                                variant="standard"
                                size="small"
                                fullWidth
                                type="tel"
                                required
                                error={errorCellphoneMessage !== ""}
                                helperText={errorCellphoneMessage}
                                style={{ marginBottom: 20 }}
                            />
                        )}
                    </InputMask>
                    {isOnsiteCheckoutMP() ? (
                        <TextField
                            id="outlined-basic"
                            label="Email:"
                            variant="standard"
                            size="small"
                            type="email"
                            fullWidth
                            style={{ marginBottom: 40 }}
                            value={email}
                            error={errorMailMessage !== ""}
                            helperText={errorMailMessage}
                            onChange={(e) => {
                                setErrorMailMessage("");
                                setEmail(e.target.value);
                            }}
                            required
                        />
                    ) : (
                        <TextField
                            id="outlined-basic"
                            label="Email ( OPCIONAL ) :"
                            variant="standard"
                            size="small"
                            type="email"
                            fullWidth
                            style={{ marginBottom: 40 }}
                            value={email}
                            error={errorMailMessage !== ""}
                            helperText={errorMailMessage}
                            onChange={(e) => {
                                setErrorMailMessage("");
                                setEmail(e.target.value);
                            }}
                        />
                    )}

                    <div class="checkout-delivery">
                        <div class="title">FORMA DE ENTREGA</div>
                        <div class="options">
                            <RadioGroup aria-label="delivery" name="delivery-method">
                                {(props.business &&
                                    props.business.delivery &&
                                    props.business.delivery.indexOf("delivery") !== -1) ||
                                isLocalDelivery() ||
                                isShipping() ? (
                                    <FormControlLabel
                                        value="delivery"
                                        control={
                                            <Radio
                                                icon={
                                                    <RadioButtonUncheckedIcon
                                                        style={{ fontSize: 17 }}
                                                    />
                                                }
                                                checkedIcon={
                                                    <RadioButtonCheckedIcon
                                                        style={{ fontSize: 17 }}
                                                    />
                                                }
                                                classes={{
                                                    root: classes.root,
                                                }}
                                                //checked={
                                                //  delivery === "delivery" ||
                                                //  (props.business.delivery.indexOf("delivery") !== -1 &&
                                                //    props.business.delivery.length === 1)
                                                //}
                                                onClick={() => checkDelivery("delivery")}
                                            />
                                        }
                                        label="Entrega"
                                        classes={{
                                            label: classes.label,
                                        }}
                                    />
                                ) : undefined}

                                {props.business.delivery.indexOf("takeout") !== -1 && (
                                    <FormControlLabel
                                        value="takeout"
                                        control={
                                            <Radio
                                                icon={
                                                    <RadioButtonUncheckedIcon
                                                        style={{ fontSize: 17 }}
                                                    />
                                                }
                                                checkedIcon={
                                                    <RadioButtonCheckedIcon
                                                        style={{ fontSize: 17 }}
                                                    />
                                                }
                                                classes={{
                                                    root: classes.root,
                                                }}
                                                //checked={
                                                //  delivery === "takeout" ||
                                                //  (props.business.delivery.indexOf("takeout") !== -1 &&
                                                //    props.business.delivery.length === 1)
                                                // }
                                                onClick={() => checkDelivery("takeout")}
                                            />
                                        }
                                        label="Retirar no Local"
                                        classes={{
                                            label: classes.label,
                                        }}
                                    />
                                )}
                            </RadioGroup>
                        </div>
                        {errorDeliveryMessage && (
                            <div
                                style={{
                                    marginTop: 3,
                                    marginLeft: 20,
                                    fontSize: "0.75rem",
                                    color: "#f44336",
                                }}
                            >
                                {errorDeliveryMessage}
                            </div>
                        )}
                    </div>
                    {delivery === "delivery" && useZipCode ? (
                        <div style={{ marginTop: 30 }}>
                            <div class="title">ENDEREÇO DE ENTREGA</div>
                            <div class="data">
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "flex-end",
                                    }}
                                >
                                    <InputMask
                                        mask="99999-999"
                                        value={zipCode}
                                        onChange={(e) => {
                                            setErrorZipCodeMessage("");

                                            //console.log(e.target.value);

                                            searchZipCode(e.target.value);
                                        }}
                                        //onBlur={(e) => searchZipCode(e.target.value)}
                                    >
                                        {() => (
                                            <TextField
                                                id="outlined-basic"
                                                label="CEP:"
                                                variant="standard"
                                                size="small"
                                                error={errorZipCodeMessage !== ""}
                                                helperText={errorZipCodeMessage}
                                                required
                                                fullWidth
                                                shrink={false}
                                                style={{
                                                    marginBottom: 20,
                                                    width: "48%",
                                                }}
                                            />
                                        )}
                                    </InputMask>
                                    <div
                                        style={{
                                            color: "blue",
                                            textDecoration: "underline",
                                            marginBottom: 18,
                                            marginLeft: 30,
                                            fontSize: 15,
                                            cursor: "pointer",
                                        }}
                                    >
                                        <div
                                            onClick={() => {
                                                setUseZipCode(false);
                                                clearCEPFields();
                                                setIsLocal(true);
                                            }}
                                        >
                                            Não sei meu CEP
                                        </div>
                                    </div>
                                </div>

                                <TextField
                                    id="outlined-basic"
                                    label="Rua / Avenida:"
                                    variant="standard"
                                    size="small"
                                    fullWidth
                                    disabled={!generalZipCode}
                                    InputProps={{
                                        classes: {
                                            root: classes.inputRoot,
                                            disabled: classes.disabled,
                                        },
                                    }}
                                    style={{ marginBottom: 20 }}
                                    value={address}
                                    error={errorAddressMessage !== ""}
                                    helperText={errorAddressMessage}
                                    required
                                    onChange={(e) => {
                                        setErrorAddressMessage("");
                                        setAddress(e.target.value);
                                    }}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <TextField
                                        id="outlined-basic"
                                        label="Número:"
                                        required
                                        variant="standard"
                                        size="small"
                                        style={{ marginBottom: 20, width: "48%" }}
                                        value={addressNumber}
                                        error={errorAddressNumberMessage !== ""}
                                        helperText={errorAddressNumberMessage}
                                        onChange={(e) => {
                                            setErrorAddressNumberMessage("");
                                            setAddressNumber(e.target.value);
                                        }}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        label="Bairro:"
                                        variant="standard"
                                        size="small"
                                        style={{ marginBottom: 20, width: "48%" }}
                                        value={addressNeighborhood}
                                        disabled={!generalZipCode}
                                        InputProps={{
                                            classes: {
                                                root: classes.inputRoot,
                                                disabled: classes.disabled,
                                            },
                                        }}
                                        onChange={(e) => {
                                            setAddressNeighborhood(e.target.value);
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <TextField
                                        id="outlined-basic"
                                        label="Cidade:"
                                        variant="standard"
                                        size="small"
                                        style={{ marginBottom: 20, width: "48%" }}
                                        value={addressCity}
                                        error={errorAddressCityMessage !== ""}
                                        helperText={errorAddressCityMessage}
                                        required
                                        disabled
                                        InputProps={{
                                            classes: {
                                                root: classes.inputRoot,
                                                disabled: classes.disabled,
                                            },
                                        }}
                                        onChange={(e) => {
                                            setErrorAddressCityMessage("");
                                            setAddressCity(e.target.value);
                                        }}
                                    />
                                    <TextField
                                        id="outlined-basic"
                                        label="Estado:"
                                        variant="standard"
                                        size="small"
                                        style={{ marginBottom: 20, width: "48%" }}
                                        value={addressState}
                                        error={errorAddressStateMessage !== ""}
                                        disabled
                                        helperText={errorAddressStateMessage}
                                        required
                                        InputProps={{
                                            classes: {
                                                root: classes.inputRoot,
                                                disabled: classes.disabled,
                                            },
                                        }}
                                        onChange={(e) => {
                                            setErrorAddressStateMessage("");
                                            setAddressState(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : undefined}

                    {!useZipCode ? (
                        <div style={{ marginTop: 30 }}>
                            <div class="title">ENDEREÇO DE ENTREGA</div>
                            <div class="data">
                                <TextField
                                    id="outlined-basic"
                                    label="Endereço:"
                                    variant="standard"
                                    size="small"
                                    fullWidth
                                    style={{ marginBottom: 20 }}
                                    value={address}
                                    error={errorAddressMessage !== ""}
                                    helperText={errorAddressMessage}
                                    required
                                    onChange={(e) => {
                                        setErrorAddressMessage("");
                                        setAddress(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    ) : undefined}

                    {isShipping() && searchByZipCode && !isLocal ? (
                        <div
                            style={{
                                marginTop: 20,
                                color: errorShippingMethod ? "red" : "",
                            }}
                            class="title"
                        >
                            MÉTODO DE ENVIO
                        </div>
                    ) : undefined}
                    {errorShippingMethod && (
                        <div
                            style={{
                                marginTop: 3,
                                fontSize: "0.75rem",
                                color: "#f44336",
                            }}
                        >
                            {errorShippingMethod}
                        </div>
                    )}
                    <div
                        style={{
                            marginBottom: 0,
                            paddingLeft: 23,
                        }}
                    >
                        {!isLocal && (
                            <Shipping searchBar={false} searchBy={searchByZipCode} />
                        )}
                    </div>

                    {isLocalDelivery() &&
                        delivery === "delivery" &&
                        !isFreeShipping() &&
                        neighborhoods &&
                        neighborhoods.length > 1 &&
                        (isLocal ) && (
                            <div style={{ marginBottom: 20 }}>
                                <FormControl required className={classes.formControl}>
                                    <InputLabel id="">Região da entrega</InputLabel>
                                    <Select
                                        labelId=""
                                        id=""
                                        className={classes.selectEmpty}
                                        onChange={(e) => {
                                            setErrorLocalDeliveryFeeMessage("");
                                            setSelectedNeigborhood(
                                                neighborhoods.filter((n) => {
                                                    return n._id === e.target.value;
                                                })[0]
                                            );
                                        }}
                                    >
                                        {neighborhoods &&
                                            neighborhoods.map((item) => {
                                                return (
                                                    <MenuItem value={item._id}>
                                                        {item.neighborhood +
                                                            " ( " +
                                                            (item.deliveryTax === 0
                                                                ? "FRETE GRÁTIS"
                                                                : formatNumberValue(
                                                                      item.deliveryTax
                                                                  )) +
                                                            " )"}
                                                    </MenuItem>
                                                );
                                            })}
                                    </Select>
                                    {errorLocalDeliveryFeeMessage && (
                                        <div
                                            style={{
                                                marginTop: 3,
                                                fontSize: "0.75rem",
                                                color: "#f44336",
                                            }}
                                        >
                                            {errorLocalDeliveryFeeMessage}
                                        </div>
                                    )}
                                </FormControl>
                            </div>
                        )}
                </div>
            </div>

            <div class="checkout-sumary">
                <div class="title">RESUMO DO PEDIDO</div>

                <div class="Rtable Rtable--3cols">
                    <div class="Rtable-cell Rtable-header">PRODUTO</div>
                    <div class="Rtable-cell Rtable-header">QTD</div>
                    <div class="Rtable-cell Rtable-header">PREÇO</div>
                    {props.cartItems.map((item) => {
                        return (
                            <>
                                <div class="Rtable-cell Rtable-content">
                                    {item.name} {(item.color || item.size) && " - "}
                                    {item.color} {item.size}
                                    {item.variationValue
                                        ? " - " + item.variationValue
                                        : ""}
                                </div>
                                <div class="Rtable-cell Rtable-content">{item.qty}</div>
                                <div class="Rtable-cell Rtable-content">
                                    <Currency value={item.promoPrice || item.price} />
                                </div>
                            </>
                        );
                    })}

                    {props.coupon && props.couponAmount ? (
                        <>
                            <div class="Rtable-footer Rtable-header">SUBTOTAL</div>
                            <div class="Rtable-footer"></div>
                            <div class="Rtable-footer Rtable-total">
                                <Currency value={props.subtotal} />
                            </div>
                            <div class="Rtable-footer Rtable-header">
                                CUPOM({props.coupon.amount}%)
                            </div>
                            <div class="Rtable-footer"></div>
                            <div class="Rtable-footer Rtable-total">
                                <Currency value={props.couponAmount} />
                            </div>
                        </>
                    ) : undefined}
                    {delivery === "delivery" ||
                    delivery === "localdelivery" ||
                    (isShippingSelected() && props.shipping) ? (
                        <>
                            <div class="Rtable-footer Rtable-header">TX ENTREGA</div>
                            <div class="Rtable-footer"></div>
                            <div class="Rtable-footer Rtable-total">
                                {isFreeShipping() ? (
                                    <div>FRETE GRÁTIS</div>
                                ) : (
                                    <Currency value={getShippingDeliveryTax()} />
                                )}
                            </div>
                        </>
                    ) : undefined}
                    {props.installment && props.installment.installments > 1 ? (
                        <>
                            <div class="Rtable-footer Rtable-header">JUROS</div>
                            <div class="Rtable-footer"></div>
                            <div class="Rtable-footer Rtable-total">
                                <Currency value={getInstallmentRate()} />
                            </div>
                        </>
                    ) : undefined}
                    <div class="Rtable-footer Rtable-header">TOTAL</div>
                    <div class="Rtable-footer"></div>
                    <div class="Rtable-footer Rtable-total">
                        <Currency value={total + getInstallmentRate()} />
                    </div>
                </div>
            </div>

            <div class="checkout-payment">
                <div class="title">FORMA DE PAGAMENTO</div>
                {isOnsiteCheckoutMP() && (
                    <div>
                        <img
                            style={{ width: 200 }}
                            alt="mercado pago"
                            src="https://res.cloudinary.com/rockedevelopr/image/upload/v1664550667/carty/mercado-pago-logo_kiwjq4.png"
                        />
                    </div>
                )}
                <div class="options">
                    <RadioGroup aria-label="payment" name="payment-method">
                        {props.business.payment === null || props.business.payment === ""
                            ? "Configure a forma de pagamento no aplicativo"
                            : undefined}
                        {props.business.payment &&
                        props.business.payment.includes("money-order") ? (
                            <FormControlLabel
                                value="money-order"
                                control={
                                    <Radio
                                        icon={
                                            <RadioButtonUncheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        checkedIcon={
                                            <RadioButtonCheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        classes={{
                                            root: classes.root,
                                        }}
                                        onClick={() => setPayment("money-order")}
                                    />
                                }
                                label="PIX"
                                classes={{
                                    label: classes.label,
                                }}
                            />
                        ) : undefined}

                        {props.business.payment &&
                        props.business.payment.includes("credit-card") ? (
                            <FormControlLabel
                                value="credit-card"
                                control={
                                    <Radio
                                        icon={
                                            <RadioButtonUncheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        checkedIcon={
                                            <RadioButtonCheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        classes={{
                                            root: classes.root,
                                        }}
                                        onClick={() => {
                                            if (
                                                environmnet === "sandbox" &&
                                                isOnsiteCheckoutMP()
                                            ) {
                                                setOpenSandbox(true);
                                            }
                                            setPayment("credit-card");
                                        }}
                                    />
                                }
                                label="Cartão de Crédito"
                                classes={{
                                    label: classes.label,
                                }}
                            />
                        ) : undefined}
                        {props.business.payment &&
                        props.business.payment.includes("debit-card") ? (
                            <FormControlLabel
                                value="debit-card"
                                control={
                                    <Radio
                                        icon={
                                            <RadioButtonUncheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        checkedIcon={
                                            <RadioButtonCheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        classes={{
                                            root: classes.root,
                                        }}
                                        onClick={() => setPayment("debit-card")}
                                    />
                                }
                                label="Cartão de Débito"
                                classes={{
                                    label: classes.label,
                                }}
                            />
                        ) : undefined}
                        {props.business.payment &&
                        props.business.payment.includes("money") ? (
                            <FormControlLabel
                                value="money"
                                control={
                                    <Radio
                                        icon={
                                            <RadioButtonUncheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        checkedIcon={
                                            <RadioButtonCheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        classes={{
                                            root: classes.root,
                                        }}
                                        onClick={() => setPayment("money")}
                                    />
                                }
                                label="Dinheiro"
                                classes={{
                                    label: classes.label,
                                }}
                            />
                        ) : undefined}
                        {props.business.payment &&
                        props.business.payment.includes("payment-link") ? (
                            <FormControlLabel
                                value="payment-link"
                                control={
                                    <Radio
                                        icon={
                                            <RadioButtonUncheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        checkedIcon={
                                            <RadioButtonCheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        classes={{
                                            root: classes.root,
                                        }}
                                        onClick={() => setPayment("payment-link")}
                                    />
                                }
                                label="Link de Pagamento"
                                classes={{
                                    label: classes.label,
                                }}
                            />
                        ) : undefined}
                        {props.business.payment &&
                        props.business.payment.includes("slip") ? (
                            <FormControlLabel
                                value="slip"
                                control={
                                    <Radio
                                        icon={
                                            <RadioButtonUncheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        checkedIcon={
                                            <RadioButtonCheckedIcon
                                                style={{ fontSize: 17 }}
                                            />
                                        }
                                        classes={{
                                            root: classes.root,
                                        }}
                                        onClick={() => setPayment("slip")}
                                    />
                                }
                                label="Boleto Bancário"
                                classes={{
                                    label: classes.label,
                                }}
                            />
                        ) : undefined}
                    </RadioGroup>
                </div>
                {errorPaymentMessage && (
                    <div
                        style={{
                            marginTop: 3,
                            marginLeft: 20,
                            fontSize: "0.75rem",
                            color: "#f44336",
                        }}
                    >
                        {errorPaymentMessage}
                    </div>
                )}
                {((payment === "slip" || payment === "money-order") &&
                    isOnsiteCheckoutMP()) ||
                (isShipping() && delivery === "delivery" && !isLocal) ? (
                    <div class="checkout-card">
                        <div class="title-slip">DADOS ADICIONAIS DO CLIENTE</div>
                        <InputMask
                            mask="999.999.999-99"
                            value={identificationNumber}
                            onChange={(e) => {
                                setErrorCPFMessage("");
                                setIdentificationNumber(e.target.value);
                            }}
                        >
                            {() => (
                                <TextField
                                    label="CPF:"
                                    variant="standard"
                                    size="small"
                                    fullWidth
                                    style={{ marginBottom: 20 }}
                                    required
                                    type="tel"
                                    error={errorCPFMessage !== ""}
                                    helperText={errorCPFMessage}
                                />
                            )}
                        </InputMask>
                    </div>
                ) : undefined}
                {payment === "credit-card" && isOnsiteCheckoutMP() && (
                    <div class="checkout-card">
                        <div class="title">CARTÃO DE CRÉDITO</div>
                        <div>
                            <img
                                src="https://res.cloudinary.com/rockedevelopr/image/upload/v1645215548/carty/selo-seguranca_utma7f.png"
                                class="secureImage"
                                alt=""
                            />
                        </div>
                        <div class="data">
                            <div id="form-checkout">
                                <InputMask
                                    mask="9999 9999 9999 9999"
                                    value={cardNumber}
                                    onChange={(e) => {
                                        setErrorCardNumberMessage("");
                                        setCardNumber(e.target.value);
                                    }}
                                >
                                    {() => (
                                        <TextField
                                            label="Número do cartão:"
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            style={{
                                                marginTop: 20,
                                                marginBottom: 20,
                                            }}
                                            error={errorCardNumberMessage !== ""}
                                            helperText={errorCardNumberMessage}
                                            required
                                            type="tel"
                                            InputProps={{
                                                endAdornment: cardImage ? (
                                                    <InputAdornment position="start">
                                                        <img src={cardImage} alt="" />
                                                    </InputAdornment>
                                                ) : undefined,
                                            }}
                                        />
                                    )}
                                </InputMask>
                                <InputMask
                                    mask="99/99"
                                    value={cardExpiration}
                                    onChange={(e) => {
                                        setErrorCardExpirationMessage("");
                                        setCardExpiration(e.target.value);
                                    }}
                                >
                                    {() => (
                                        <TextField
                                            label="Data do Vencimento (MM/YY):"
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            style={{ marginBottom: 20 }}
                                            type="tel"
                                            required
                                            error={errorCardExpirationMessage !== ""}
                                            helperText={errorCardExpirationMessage}
                                        />
                                    )}
                                </InputMask>
                                <TextField
                                    label="Nome titular:"
                                    variant="standard"
                                    size="small"
                                    fullWidth
                                    style={{ marginBottom: 20 }}
                                    required
                                    value={cardholderName}
                                    error={errorCardHolderNameMessage !== ""}
                                    helperText={errorCardHolderNameMessage}
                                    onChange={(e) => {
                                        setErrorCardHolderNameMessage("");
                                        setCardholderName(e.target.value);
                                    }}
                                />

                                <InputMask
                                    mask="999"
                                    value={securityCode}
                                    onChange={(e) => {
                                        setErrorSecurityCodeMessage("");
                                        setSecurityCode(e.target.value);
                                    }}
                                >
                                    {() => (
                                        <TextField
                                            label="CVC:"
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            style={{ marginBottom: 20 }}
                                            required
                                            type="tel"
                                            error={errorSecurityCodeMessage !== ""}
                                            helperText={errorSecurityCodeMessage}
                                        />
                                    )}
                                </InputMask>
                                <InputMask
                                    mask="999.999.999-99"
                                    value={identificationNumber}
                                    onChange={(e) => {
                                        setErrorCPFMessage("");
                                        setIdentificationNumber(e.target.value);
                                    }}
                                >
                                    {() => (
                                        <TextField
                                            label="CPF:"
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            style={{ marginBottom: 20 }}
                                            required
                                            type="tel"
                                            error={errorCPFMessage !== ""}
                                            helperText={errorCPFMessage}
                                        />
                                    )}
                                </InputMask>

                                <FormControl fullWidth>
                                    <InputLabel
                                        variant="standard"
                                        htmlFor="uncontrolled-native"
                                    >
                                        Parcelamento
                                    </InputLabel>
                                    <NativeSelect
                                        onChange={(e) => {
                                            //setErrorLocalAddressMessage("");
                                            setErrorInstallments("");
                                            selectInstallment(e.target.value);
                                            //props.setInstallment(e.target.value);
                                        }}
                                    >
                                        <option value=""></option>
                                        {installments &&
                                            installments.map((i) => {
                                                return (
                                                    <option value={i.installments}>
                                                        {i.recommended_message}
                                                    </option>
                                                );
                                            })}
                                    </NativeSelect>
                                </FormControl>
                                {errorInstallments && (
                                    <div
                                        style={{
                                            marginTop: 3,
                                            marginLeft: 20,
                                            fontSize: "0.75rem",
                                            color: "#f44336",
                                        }}
                                    >
                                        {errorInstallments}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div class="checkout-note">
                <div class="title">OBSERVAÇÃO</div>
                <div class="data">
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Adicione uma observação para o vendedor."
                        rows={4}
                        size="small"
                        multiline
                        fullWidth
                        style={{ marginBottom: 20 }}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                </div>
            </div>
            <div class="checkout-buttons">{renderButtons()}</div>
            <div class="loading">
                <div className={classes.loading}>
                    {loading ? <LinearProgress /> : undefined}
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
}

const mapStateToProps = (state) => ({
    business: state.business.current,
    cartItems: state.cartList.cart,
    total: getCartTotal(
        state.cartList.cart,
        state.shipping.current,
        state.coupon.current,
        state.installment.current
    ),
    subtotal: getCartTotal(state.cartList.cart),
    shipping: state.shipping.current,
    coupon: state.coupon.current,
    couponAmount: getCouponAmount(state.cartList.cart, state.coupon.current),
    installment: state.installment.current,
});

export default connect(mapStateToProps, {
    clearCart,
    setShipping,
    clearCoupon,
    setInstallment,
    clearInstallment,
    removeFromCart,
    decrementQty,
    updateCart,
})(CheckoutPage);
