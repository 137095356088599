import { RECEIVE_PROMOS } from "../constants/ActionTypes";

const initialState = {
    current: [],
};

const promoReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_PROMOS:
            return { ...state, current: action.promos };

        default:
            return state;
    }
};
export default promoReducer;
