import React from "react";
import { connect } from "react-redux";

import "./styles_m.css";
import Payment from "../../components/common/payment";

function FooterMinimalist(props) {
    return (
        <div className="footer-minimalist">
            <div className="footer-minimalist-title">Sobre nós</div>
            <div className="card-minimalist">
                <div className="card-minimalist-text">{props.business.description}</div>
            </div>
            {props.business && (props.business.instagram || props.business.facebook) && (
                <div className="social-media">
                    <div className="social-media-container">
                        <div className="title">Siga as nossas redes sociais:</div>
                        {props.business.instagram && (
                            <div className="icon">
                                <a
                                    href={
                                        "instagram://user?username=" +
                                        props.business.instagram
                                    }
                                >
                                    <i class="fa fa-instagram"></i>
                                </a>
                            </div>
                        )}
                        {props.business.facebook && (
                            <div className="icon">
                                <a
                                    href={
                                        "https://www.facebook.com/" +
                                        props.business.facebook
                                    }
                                >
                                    <i class="fa fa-facebook"></i>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className="footer-minimalist-logo">
                <img className="logo" src={props.business.logo} alt="logo" />
                <li>
                    <ul>
                        <span>{props.business.phone}</span>
                    </ul>
                    <ul>
                        <span>{props.business.address}</span>
                    </ul>
                    <ul>
                        <span>{props.business.email}</span>
                    </ul>
                </li>
            </div>
            <Payment business={props.business} />
        </div>
    );
}
const mapStateToProps = (state) => ({
    business: state.business.current,
});

export default connect(mapStateToProps, {})(FooterMinimalist);
