import React from "react";
import { connect } from "react-redux";

import { filterCategory } from "../../actions";

import { getVisibleProducts } from "../../services";

import GridView from "./GridView";

import SearchBar from "../SearchBar";

import { Link } from "react-router-dom";

function Categories(props) {
    function goBack() {
        props.filterCategory(null);
    }

    const { bannerUri } = Object(props.location.state);

    return (
        <div id="categories-wrap">
            {props.business && bannerUri ? (
                <div className="promo-banner">
                    <img
                        src={bannerUri}
                        alt="banner"
                        style={{
                            objectFit: "contain",
                            width: "100%",
                        }}
                    />
                </div>
            ) : undefined}

            {props.business.features &&
            props.business.features.includes("searchbar") &&
            (props.business.activePlanName === "Premium" ||
                props.business.activePlanName === "Pro" ||
                props.business.activePlanName === "Diamante") ? (
                <SearchBar />
            ) : undefined}
            <div className="banner">
                <GridView />
            </div>
            <div class="buttons">
                <Link
                    to={{
                        pathname: `${process.env.PUBLIC_URL}/`,
                        state: { action: "back" },
                    }}
                    onClick={() => goBack()}
                    class="secondary"
                >
                    VOLTAR
                </Link>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({
    categories: state.category.current,
    selected: state.filters,
    products: getVisibleProducts(state.data, { category: null }, { searchTerm: null }),
    business: state.business.current,
});

export default connect(mapStateToProps, { filterCategory })(Categories);
