import React from "react";
import { connect } from "react-redux";

import { filterCategory } from "../../actions";

import { getVisibleProducts } from "../../services";

import GridView from "./GridView";

import Header from "./Header";

import Currency from "../../components/common/currency";
import SearchBar from "../SearchBar";
import FooterMinimalist from "./FooterMinimalist";

function Categories(props) {
    const { bannerUri } = Object(props.location.state);

    return (
        <div id="minimalist-categories-wrap">
            <div className="header-minimalist">
                <Header back={true} isPromo={bannerUri} />
            </div>
            {props.business.freeShipping > 0 && (
                <div className="blank">
                    <div
                        style={{
                            marginTop: 25,
                            paddingTop: 5,
                            width: "100%",
                            backgroundColor: "gray",
                            height: 20,
                            fontSize: 12,
                            textAlign: "center",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <div style={{ marginRight: 5 }}>
                            FRETE GRÁTIS para compras acima de{" "}
                        </div>
                        <Currency value={props.business.freeShipping} />
                    </div>
                </div>
            )}
            {props.business && bannerUri ? (
                <div className="promo-banner">
                    <img
                        src={bannerUri}
                        alt="banner"
                        style={{
                            objectFit: "contain",
                            width: "100%",
                        }}
                    />
                </div>
            ) : (
                <div className="minimalist-categories-title">
                    {props.selected.category}
                </div>
            )}

            {props.business.features &&
            props.business.features.includes("searchbar") &&
            (props.business.activePlanName === "Premium" ||
                props.business.activePlanName === "Pro" ||
                props.business.activePlanName === "Diamante") ? (
                <SearchBar />
            ) : undefined}

            <GridView />

            <div className="footer-minimalist">
                <FooterMinimalist />
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({
    categories: state.category.current,
    selected: state.filters,
    products: getVisibleProducts(state.data, { category: null }, { searchTerm: null }),
    business: state.business.current,
});

export default connect(mapStateToProps, { filterCategory })(Categories);
