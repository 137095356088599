import React, { useRef } from "react";

import ImageGallery from "react-image-gallery";

import { connect } from "react-redux";
import "./styles_d.css";
import { getVisibleProducts } from "../../services";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useHistory } from "react-router-dom";

export function TopSellers(props) {
    const refGallery = useRef();

    let history = useHistory();

    function selectProduct() {
        let index = refGallery.current.getCurrentIndex();
        //console.log(props.products[index].name);
        history.push(`/product/${props.products[index].id}`);
    }
    return (
        <div>
            {props.products.length > 1 && (
                <>
                    <div class="title">Novidades</div>
                    <div style={{ position: "relative" }}>
                        <ImageGallery
                            items={props.products
                                .sort((a, b) => {
                                    return (
                                        new Date(b.createdAt).getTime() -
                                        new Date(a.createdAt).getTime()
                                    );
                                })
                                .slice(0, 3)
                                .map((p, index) => {
                                    return { original: p.images[0] };
                                })}
                            showThumbnails={false}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            showBullets={true}
                            ref={refGallery}
                        />
                        <div
                            onClick={(e, i) => selectProduct()}
                            className="image-dark-button"
                        >
                            COMPRAR
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    products: getVisibleProducts(state.data, { category: null }, { searchTerm: null }),
});

export default connect(mapStateToProps, {})(TopSellers);
